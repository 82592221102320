import { Spin } from 'antd';
import { useEffect } from 'react'
import cookie from 'react-cookies';

const RedirectNewTrackings = () => {
    
    useEffect(() => {
        window.location.href = `${process.env.REACT_APP_URL_NEW_BUYERS}/tracking-numbers?token=${cookie.load('token')}`;
    }, [])

    return (
        <div className='flex justify-center items-center h-screen'>
            <Spin size="large" />
        </div>
    );
}

export default RedirectNewTrackings