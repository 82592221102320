import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Tag } from 'antd';
import RenderActions from '../containers/RenderActions';
import RenderQtyUser from '../containers/RenderQtyUser';
import usePriceFormat from '../../../hooks/usePriceFormat';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const dataTableFields: ColumnsType<any> = [
  {
    title: 'Item',
    key: 'item',
    width: '24rem',
    sorter: {},
    render: (item: any) => (
      <>
        <aside
          className='opacity-40 text-left font-semibold text-[13px] fontGTAmericanR'
          title={item.marc}>
          {item.item.marc}
        </aside>
        <aside
          className='text-left mb-4 fontGTAmericanR font-semibold text-[13px] truncante-text'
          title={item.id}>
          {item.item.description}
        </aside>
        <aside className='opacity-40 text-left font-semibold text-[13px]'>
          Colors:&nbsp;
          {item.quantityUser?.map((item: any) => item.color + ' ')}
        </aside>
      </>
    ),
  },
  {
    title: 'Price',
    dataIndex: 'weReimburse',
    key: 'weReimburse',
    width: '12rem',
    className: 'fontGTAmericanR ',
    sorter: (a: any, b: any) => {
      return a.weReimburse - b.weReimburse;
    },
    render: (weReimburse) => {
      return (
        <p className='fontGTAmericanR font-semibold'>
          {usePriceFormat(weReimburse || 0)}
        </p>
      );
    },
  },
  // TODO: WHEN IT'S NEEDED
  {
    title: 'We Reimburse',
    dataIndex: 'price',
    key: 'priceTable',
    width: '10rem',
    sorter: (a: any, b: any) => {
      return a.price - b.price;
    },
    render: (price: string[], item) => {
      return (
        <>
          <p
            className={
              Number(price) > Number(item.weReimburse) ||
              Number(price) < Number(item.weReimburse)
                ? 'flex flex-col font-bold mt-4 fontGTAmericanR'
                : 'flex flex-col font-bold fontGTAmericanR'
            }>
            {usePriceFormat(Number(price))}
            {Number(price) > Number(item.weReimburse) && (
              <Tag
                style={{
                  borderRadius: 10,
                  fontSize: '8px',
                  width: '4.5rem',
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  textAlign: 'center',
                }}
                color='#81BFFD'>
                {usePriceFormat(Number(price) - Number(item.weReimburse) || 0)}
                <ArrowUpwardIcon fontSize='inherit' />
                Price
              </Tag>
            )}

            {Number(price) < Number(item.weReimburse) && (
              <Tag
                style={{
                  borderRadius: 10,
                  fontSize: '8px',
                  width: '4.5rem',
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  textAlign: 'center',
                }}
                color='#ff6339'>
                {usePriceFormat(Number(item.weReimburse) - Number(price) || 0)}
                <ArrowDownwardIcon fontSize='inherit' />
                Price
              </Tag>
            )}
          </p>
        </>
      );
    },
  },
  {
    title: 'Deal Expires',
    dataIndex: 'dealExpires',
    key: 'dealExpires',
    width: '10rem',
    sorter: {},
    render: (dealExpires: string) => (
      <p className={'flex flex-col font-semibold fontGTAmericanR'}>
        {dealExpires}
        {/* {dealExpires && (
          <Tag
            style={{
              borderRadius: 10,
              fontSize: '10px',
              padding: 0,
              width: '5rem',
              textAlign: 'center',
            }}
            color='#F86238'>
            {dealExpires}
          </Tag>
        )} */}
      </p>
    ),
  },
  {
    title: 'Quantity',
    key: 'quantityUser',
    width: '15rem',
    sorter: {},
    render: (prueba) => <RenderQtyUser item={prueba} />,
  },
  {
    title: 'Actions',
    width: '10rem',
    render: (prueba) => <RenderActions item={prueba} />,
  },
];

export const itemInfo = {
  item: {
    item: {
      TotalPrice: NaN,
      dealExpires: '04-28-2022',
      enabled: true,
      id: 3,
      item: {
        image:
          'https://api.usabuying.group.thecornercloud.com/dev…caa30dda1bef592ae49ebf371ce59041qPQ1u-XvL_833.jpg',
        marc: 'NINTENDO',
        description: 'Alcaltel Pixi Theatre on Cricket Wireless 4G LTE Network',
        color: 'Slate red, red',
      },
      itemTotal: 210,
      price: 210,
      quantity: 1,
      quantityUser: [
        {
          color: 'Alcaltel Pixi Theatre on Cricket Wireless 4G LTE Network',
          amount: 2,
        },
      ],
      shopNow: [
        '/images/walmart.jpg',
        '/images/bestbuy.jpg',
        '/images/amazon.png',
      ],
      stores: {
        linkBestBuy: '',
        linkAmazon:
          'https://www.amazon.com/-/es/CeraVe-salic%C3%ADlico…g=ih4t5&ref_=pd_gw_exports_top_sellers_unrec&th=1',
        linkWalmart: '',
      },
      variations: [
        {
          allow_buyer_to_decrease_term: false,
          allow_decrease_without_approval_term: false,
          buyer_commit: 10,
          commit_id: 1,
          quantity_request: null,
          commit_limit: 10,
          id: 1,
          received: 0,
          is_buyer_commit_editable: true,
          locked: false,
          model: 'Xiaomi8.LiteB',
          price: 150,
          stores: [
            {
              id: '1',
              link: 'https://amz.com/XIAOMI8',
              icon: 'https://api.usabuying.group.thecornercloud.com/dev_staging/images/products/no-icon.png',
              name: 'Amazon',
            },
          ],
          variation: 'Xiaomi 8 Lite 64GB Black',
        },
      ],
      weReimburse: ['$1.00'],
    },
  },
};
