/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { Button } from '@mui/material';
import { IAlertType } from '../../../interfaces/activeDeal';
// import useExportTrackings from '../../../api/services/trackingNumbers/exportTrackings';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew';
import useResponsive from '../../../hooks/useResponsive';
import { usePostDowload } from '../../../api/services/trackingNumbers/usePostDowload';

const ExportTrackingNumbers = ({ list }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [viewModal, setViewModal] = useState({ active: false });
  const [infoRequestExport, setInfoRequestExport] = useState({
    ids: '',
    module: 'buyers',
    submodule: 'pos',
    trackings_list: true,
  });

  const { mutate } = usePostDowload();
  const { isMobile } = useResponsive();

  // const handlePdf = () => {
  //   let formData = {
  //     module: 'buyers',
  //     submodule: 'trackings',
  //     format: 'pdf',
  //     ids: trackingsList,
  //   };

  //   mutate(formData, {
  //     onSuccess: (data) => {
  //       setShowAlert({
  //         show: data?.error ? true : false,
  //         message: data?.error,
  //         title: 'Warning',
  //         type: 'warning',
  //       });
  //       setIsVisible(false);
  //       setViewModal({ active: false });

  //       if (data?.download) {
  //         window.location.href = `${
  //           process.env.REACT_APP_BACKEND_URL
  //         }/resources/${encodeURIComponent(data?.download.code)}`;
  //         setIsVisible(false);
  //         setViewModal({ active: false });
  //       }
  //     },
  //     onError: (error: any) => {
  //       setShowAlert({
  //         show: true,
  //         message: error.toString(),
  //         title: 'Error',
  //         type: 'error',
  //       });
  //     },
  //   });
  // };

  const handlePopoverClick = (visible: any) => {
    isMobile ? setViewModal({ active: visible }) : setIsVisible(visible);
  };

  useEffect(() => {
    const idsData = () => {
      const listTest: any[] = [];

      list.map((item: any) => {
        listTest.push(item.id);
      });
      setInfoRequestExport({
        ...infoRequestExport,
        ids: listTest.toString(),
      });
    };

    idsData();
  }, [list]);

  const onSubmit = (format: any) => {
    if (infoRequestExport.ids === '') {
      setShowAlert({
        show: true,
        message: 'Ids id is required',
        title: 'Error',
        type: 'warning',
      });
    }

    try {
      mutate(
        {
          ...infoRequestExport,
          format,
        },
        {
          onSuccess: (data) => {
            if (data?.download) {
              window.location.href = `${
                process.env.REACT_APP_BACKEND_URL
              }resources/${encodeURIComponent(data?.download.code)}`;
              setShowAlert({
                show: true,
                message: data?.error
                  ? data?.error
                  : 'Report downloaded successfully',
                title: data?.error ? 'Error' : 'Success',
                type: data?.error ? 'warning' : 'success',
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
        },
      );
    } catch (error) {
      console.log('catch', error);
    }
  };

  const BuildExportTrackingNumbers = () => {
    return (
      <div className='w-60'>
        {/* <p className='font-semibold'>Options</p>
        <div className='flex flex-col'>
          <label className='cursor-pointer'>
            <Checkbox />
            <span className='ml-4'>Include product data</span>
          </label>
          <label className='cursor-pointer'>
            <Checkbox />
            <span className='ml-4'>Export current view</span>
          </label>
        </div>
        <hr className='border-t-2 border-gray-300 px-2 my-5' /> */}
        <div className='mt-6'>
          <p className='text-slate-400'>Download as</p>
        </div>
        <div className='mt-6'>
          {/* <h6 className='cursor-pointer hover:bg-slate-300 p-2 rounded-lg'>
            Microsoft Excel(.xlsx)
          </h6> */}
          <h6
            className='cursor-pointer hover:bg-slate-300 p-2 rounded-lg'
            onClick={() => onSubmit('csv')}>
            Comma Separated Values(.csv)
          </h6>
          <Button
            data-testid={'pdfbutton'}
            // disabled={isLoading}
            // onClick={handlePdf}
            onClick={() => onSubmit('pdf')}
            sx={{
              borderRadius: '0.5rem',
              color: 'black',
              textTransform: 'none',
              width: '100%',
              justifyContent: 'left',
              fontSize: 13.5,
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'rgb(203 213 225)',
              },
            }}>
            PDF(.pdf)
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <Popover
        placement='bottom'
        trigger='click'
        data-testid='formRequestPayment'
        content={BuildExportTrackingNumbers}
        onVisibleChange={handlePopoverClick}
        visible={isVisible}>
        <button
          data-testid='formRequestPayment'
          className='flex justify-between w-full xl:w-[144px] xl:ml-0 items-center xl:bg-white px-6 py-4 xl:rounded-3xl cursor-pointer xl:h-[4rem]'
          // style={{ backgroundColor: '#e1e1e1' }}
        >
          <div className='flex gap-4'>
            <img
              src='images/trackingNumbers/downloadIcon.png'
              alt='download icon'
              width='20'
              height='21'
            />
            <span className='text-[#178DFF] fontGTAmericanR'>Export</span>
          </div>
          <img
            alt='delete icon'
            className='h-4 w-2 xl:hidden'
            height='17'
            src='images/trackingNumbers/blueRightArrow.png'
            width='12'
          />
        </button>
      </Popover>
      {viewModal.active && (
        <DrawerComponentUI
          anchor={'bottom'}
          modalBorderTopRightRadius={'30px'}
          viewRender={<BuildExportTrackingNumbers />}
          closeModal={setViewModal}
          width={'100%'}
        />
      )}
    </>
  );
};

export default ExportTrackingNumbers;
