import React, { useState } from 'react';
import * as Styles from './style';
import productsSend from '../../../api/services/myDealsCart/productsSend';
import usePriceFormat from '../../../hooks/usePriceFormat';
import { themeCustom } from '../../../theme';
import { Box, Button } from '@mui/material';
import { useCart } from 'react-use-cart';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../generalAlert/GeneralAlert';
import { Link } from 'react-router-dom';
const { colors } = themeCustom;

const PurchaseSummaryCard = () => {
  const { items, emptyCart, setCartMetadata } = useCart();
  const [submitSucceed, setSubmitSucceed] = useState(false);
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const { mutate } = productsSend();

  const handleCommit = () => {
    const productsSend: any = [];
    let price: any = 0;
    let list_price: any = 0;

    items.forEach((item: any) => {
      (price = item.price),
        (list_price = item.variations[0].list_price),
        item.quantityUser.forEach((qty: any) => {
          productsSend.push({
            id: qty.id,
            qty: Number(qty.amount),
            price,
            list_price,
          });
        });
    });

    mutate(
      { commits: productsSend },
      {
        onSuccess: (data) => {
          if (data?.error) {
            setShowAlert({
              show: true,
              message: data?.error,
              title: 'Error',
              type: 'error',
            });
          } else {
            setSubmitSucceed(true);
            setShowAlert({
              show: true,
              message: 'The order has been sent successfully',
              title: 'Success',
              type: 'success',
            });
            emptyCart();
          }
        },
        onError: (error: any) => {
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
        },
      },
    );
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  let totalItemsPrice = 0;
  const calculateTotalItemsPrice = () => {
    items.forEach((item: any) => (totalItemsPrice += item.TotalPrice));
  };
  calculateTotalItemsPrice();

  React.useEffect(() => {
    const addMetaData = () => {
      setCartMetadata({ totalItemsPrice });
    };

    addMetaData();
  }, [totalItemsPrice]);

  return (
    <div className='grid justify-items-stretch mt-3 mr-10'>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      {submitSucceed ? (
        <Styles.SucceedContainer>
          <picture className='flex justify-center mt-6'>
            <img
              src='/images/successPurple.png'
              alt='successImage'
              width={203}
              height={231}
            />
          </picture>
          <h4 className='text-2xl mt-12'>Success!</h4>
          <Box onClick={preventDefault} sx={{ marginTop: 3 }}>
            <Link to='/my-commitments' className='underline'>
              View my commitments
            </Link>
          </Box>
        </Styles.SucceedContainer>
      ) : (
        <Styles.MainContainer>
          <div className='ml-4'>
            <div className='lg:flex'>
              <h2 className='text-gray-500 font-semibold w-9/12'>
                Reimbursement
              </h2>
              <data value={totalItemsPrice} className='text-2xl lg:text-sm'>
                {usePriceFormat(totalItemsPrice)}
              </data>
            </div>
            <p className='w-7/12 mt-3 descriptioParagraphfontSize italic'>
              Along with credit card rewards, cashback and miles.
            </p>
          </div>
          <Styles.HrDivider />
          <div className='lg:flex ml-4 mt-8'>
            <h3 className='text-gray-500 w-9/12'>Total Items</h3>
            <data value={items.length} className='text-2xl lg:text-sm'>
              {items.length}
            </data>
          </div>
          <div className='mt-8 flex justify-center'>
            <Button
              variant='outlined'
              size='large'
              onClick={handleCommit}
              sx={{
                border: '1.5px solid',
                borderRadius: 2,
                width: '100%',
                textTransform: 'none',
                padding: '12px 0px',
                backgroundColor: '#0062cc',
                borderColor: '#0062cc',
                fontWeight: 'bold',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#0062cc',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                  color: colors.white,
                },
              }}>
              Commit to All
            </Button>
          </div>
        </Styles.MainContainer>
      )}
    </div>
  );
};

export default PurchaseSummaryCard;
