/* eslint-disable no-undef */
import React, { useState } from 'react';
import { themeCustom } from '../../../theme';
import { Popover } from 'antd';
import { Button, Stack } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { IGenerateLabels } from '../../../interfaces/trackingNumbers';
import { IAlertType } from '../../../interfaces/activeDeal';
import useGenerateLabels from '../../../api/services/trackingNumbers/generateLabels';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew';
import useResponsive from '../../../hooks/useResponsive';

const GenerateLabels = () => {
  const { colors } = themeCustom;
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [viewModal, setViewModal] = useState({ active: false });
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<IGenerateLabels>();
  const { mutate, isLoading } = useGenerateLabels();
  const { isMobile } = useResponsive();

  const onSubmit: SubmitHandler<IGenerateLabels> = (labelsAmount) => {
    try {
      mutate(labelsAmount, {
        onSuccess: (data) => {
          reset({ quantity: 1 });
          setIsVisible(false);
          setViewModal({ active: false });

          setShowAlert({
            show: true,
            message: data?.error
              ? data?.error
              : 'Tracking numbers generated successfully',
            title: data?.error ? 'Error' : 'Success',
            type: data?.error ? 'warning' : 'success',
          });

          if (data?.download) {
            window.location.href = `${
              process.env.REACT_APP_BACKEND_URL
            }/resources/${encodeURIComponent(data?.download.code)}`;
          }
        },
        onError: (error: any) => {
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
        },
      });
    } catch (error: any) {
      setShowAlert({
        show: true,
        message: error,
        title: 'Error',
        type: 'error',
      });
    }
  };

  const handleCancel = () => {
    if (isMobile) {
      setViewModal({ active: false });
      reset({ quantity: 1 });
    } else {
      setIsVisible(false);
      reset({ quantity: 1 });
    }
  };

  const handlePopoverClick = (visible: any) => {
    isMobile ? setViewModal({ active: visible }) : setIsVisible(visible);
  };

  // eslint-disable-next-line no-unused-vars
  const handleCloseAlert = () => {
    setShowAlert({
      show: false,
      message: '',
      title: '',
      type: 'success',
    });
  };

  const BuildGenerateLabels = () => {
    return (
      <form className='w-full xlc:w-[13rem]' onSubmit={handleSubmit(onSubmit)}>
        <h3 className='block xlc:hidden fontSharpGrotesk22Medium text-xl mb-6'>
          Generate Labels
        </h3>
        <p>
          <b>How many boxes would you like to drop off?</b>
        </p>
        <p>Labels are for one-time-use only. Dot not reuse.</p>
        <Controller
          name={'quantity'}
          control={control}
          defaultValue={1}
          render={({ field }) => (
            <Stack>
              <input
                {...field}
                {...register('quantity', {
                  required: 'This field is required',
                })}
                className='bg-gray-100 rounded-xl p-3 w-[6rem]'
                name='quantity'
                type='number'
                data-testid='inputDownloadLabels'
              />
              <ErrorMessage errors={errors} name={'trackings'} />
              <div className='flex xlc:flex-row-reverse xlc:justify-end gap-4 xlc:gap-2 mt-5 xl:mt-0'>
                <Button
                  // data-testid='buttonSubmitAddTrackingsNumbers'
                  data-testid='cancelGenerateTrackingBtn'
                  disabled={isLoading}
                  onClick={handleCancel}
                  sx={{
                    textDecoration: { lg: 'underline' },
                    textTransform: 'none',
                    border: { xs: '1.5px solid #4da4fc', lg: '0px' },
                    bgcolor: 'white',
                    borderRadius: { xs: 3, lg: '' },
                    color: { xs: '#4da4fc', lg: colors.grayLabels },
                    fontSize: 13,
                    fontWeight: 'bold',
                    width: { xs: '100%', lg: 'auto' },
                    '&:hover': {
                      backgroundColor: 'white',
                      textDecoration: 'underline',
                      boxShadow: 'none',
                    },
                  }}>
                  Cancel
                </Button>
                <Button
                  data-testid='DownloadLabelsBtn'
                  disabled={isLoading}
                  sx={{
                    color: { xs: 'white', lg: '#4da4fc' },
                    fontSize: 13,
                    fontWeight: 'bold',
                    bgcolor: { xs: '#178DFF', lg: 'white' },
                    borderRadius: { xs: 3, lg: '' },
                    textDecoration: { lg: 'underline' },
                    textTransform: 'none',
                    width: { xs: '100%', lg: 'auto' },
                    '&:hover': {
                      backgroundColor: { lg: 'white' },
                      textDecoration: 'underline',
                      boxShadow: 'none',
                    },
                  }}
                  type='submit'>
                  Download {isMobile ? '' : 'Labels'}
                </Button>
              </div>
            </Stack>
          )}
        />
      </form>
    );
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <Popover
        placement='bottom'
        trigger='click'
        data-testid='formRequestPayment'
        content={BuildGenerateLabels}
        onVisibleChange={handlePopoverClick}
        visible={isVisible}>
        <button className='flex justify-between w-full xl:w-[210px] xl:ml-0 items-center xl:bg-white px-6 py-4 xl:rounded-3xl cursor-pointer text-blue-400 xl:h-[4rem]'>
          <div className='flex gap-4'>
            <img
              className='h-6'
              src='images/trackingNumbers/generateLabelsButtonIcon.png'
              alt='generate labels icon'
              width='23'
              height='25'
            />
            <h2 className='text-[#178DFF] mb-0 fontGTAmericanR'>
              Generate Labels
            </h2>
          </div>
          <img
            alt='delete icon'
            className='h-4 w-2 xl:hidden'
            height='17'
            src='images/trackingNumbers/blueRightArrow.png'
            width='12'
          />
        </button>
      </Popover>
      {viewModal.active && (
        <DrawerComponentUI
          anchor={'bottom'}
          modalBorderTopRightRadius={'30px'}
          viewRender={<BuildGenerateLabels />}
          closeModal={setViewModal}
          width={'100%'}
        />
      )}
    </>
  );
};

export default GenerateLabels;
