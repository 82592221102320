import tw, { styled } from 'twin.macro';

export const TableContainer = styled.div`
  @media screen and (max-width: 1200px) {
    .ant-table table {
      padding: 0;
    }

    .ant-table-thead > tr > th:first-child {
      padding-left: 1rem;
      border-radius: 0 !important;
    }

    .ant-table-thead > tr > th:last-child {
      border-radius: 0 !important;
    }

    .ant-table-column-sorter-inner {
      display: none;
    }
  }

   @media screen and (max-width: 1445px) and (min-width: 1201px) {
    .ant-table table {
      padding: 0.4rem;
    }

    .ant-table-thead > tr > th:first-child {
      padding-left: 0.5rem;
    }
  }

  .ant-table-thead > tr > th:first-child {
    padding-left: 0.5rem;
  }

  .ant-table-tbody > tr > td {
    padding: 0.5rem;
  }
`;

export const ContentHistorialTrackings = styled.div`
  .sectionScroll {
    ${tw`h-60 overflow-y-auto px-8`}
    scrollbar-width: thin;
    scrollbar-color: light;
    overflow: auto;
  }

  .sectionScroll::-webkit-scrollbar {
    width: 5px;
  }

  .sectionScroll::-webkit-scrollbar-track {
    background-color: #f3f3f3; /* color of the tracking area */
  }

  .sectionScroll::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
`;

export const TableClaims = styled.div`
  .ant-table-tbody > tr > td {
    padding: 0.8rem 0.5rem;
  }

  .highlighted-row {
    background-color: #ebf5ff;
  }

  .ant-table-thead > tr > th {
    border-bottom: 5px solid white;
  }

`;
