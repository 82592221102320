import React from 'react';
import { Navigate } from 'react-router-dom';
import useStatesGlobalStore from '../hooks/useStatesGlobalStore';

const RequiredAuth = ({ children }: any) => {
  const { authInfo } = useStatesGlobalStore();

  if (authInfo.state === 'sessionDenied' || authInfo.length === 0) {
    return <Navigate to='/' />;
  }

  return <>{children}</>;
};

export default RequiredAuth;
