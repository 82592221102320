export const dealAvailableToCommit = [
  { color: 'White', qtyCommitted: '03' },

  { color: 'Space gray', qtyCommitted: '00' },
];

export const purchaseProductShopOptions = [
  {
    image: '/images/walmart.jpg',
    shopName: 'Walmart',
    sku: '15-dk1056wm',
    width: 1620,
    height: 1620,
  },
  {
    image: '/images/bestbuy.jpg',
    shopName: 'Best Buy',
    sku: 'GWTN156-1BK',
    width: 246,
    height: 246,
  },
];

export const locationOptionsShipProduct = [
  {
    name: '1400 Vandever Ave Wilmington, DE 19802',
  },
];

export const nextSteps = [
  {
    name: 'Add tracking numbers',

    icon: (
      <img
        src='/images/sideBar/tracking.svg'
        alt='icon'
        width='32'
        height='32'
      />
    ),
    arrow: (
      <img
        className='ml-9'
        src='/images/dashboard/blueArrow.png'
        alt='icon'
        width='7'
        height='13'
      />
    ),
    route: '/tracking-numbers',
    state: true,
  },
  {
    name: 'Request payment',
    icon: (
      <img
        src='/images/sideBar/payment.svg'
        alt='icon'
        width='32'
        height='32'
      />
    ),
    arrow: (
      <img
        className='ml-20'
        src='/images/dashboard/blueArrow.png'
        alt='icon'
        width='7'
        height='13'
      />
    ),
    route: '/payment-request',
  },
];
