import {
  IServiceResponseTransform,
  ITrackingNumbers,
} from '../../../interfaces/trackingNumbers/index';

const transformTrackingNumbersData = (
  data: ITrackingNumbers,
): IServiceResponseTransform => {
  const dataTransformed = data?.data?.map((item: any) => {
    return {
      ...item,
      received: item?.purchase?.receiving_status,
      key: item?.id,
    };
  });
  const metaTransform = {
    message: data?.message,
    totals: data?.totals,
    status: data?.status,
  };
  return { data: dataTransformed, meta: metaTransform };
};
export default transformTrackingNumbersData;
