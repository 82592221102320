import React, { useState } from 'react';
import * as Styles from './style';
import CardContent from '../../components/UI/cardContent';
import BreadCrumbs from '../../components/UI/breadCrumbs';
import ProductCard from '../../components/UI/productCard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, Divider, Stack } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { IAlertType } from '../../interfaces/activeDeal';
import { themeCustom } from '../../theme';
import { useParams } from 'react-router-dom';
import {
  // TODO:USE WHEN NEEDED
  locationOptionsShipProduct,
  nextSteps,
} from './constants';
import {
  handleGeneralCopyClick,
  // notifyImpedimentToDecrease,
  notifyImpedimentToIncrease,
} from '../../helpers';
import GeneralAlert from '../../components/UI/generalAlert/GeneralAlert';
import useGetDeal from '../../api/services/activeDeals/getDeal';
import { useCart } from 'react-use-cart';
import { useQueryClient } from 'react-query';
import useQuickCommit from '../../api/services/activeDeals/quickCommit';
import loadingIcon from '../../images/spinner-2.gif';
import OperationsMobileView from './containers/OperationsMobileView';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';

const ActiveDealModule = () => {
  const { colors } = themeCustom;
  const { addItem } = useCart();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { mutate, isLoading } = useQuickCommit();
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [isNotAllowToDecrease, setIsNotAllowToDecrease] = useState(false);
  const [variationQty, setVariationQty] = useState({
    id: '',
    qty: '',
  });
  let { id } = useParams();
  const { response, refetch } = useGetDeal(id);
  const breadCrumbsActiveDeal = [
    {
      name: 'Active Deals',
      route: '/active-deals',
    },
    {
      name: `${response?.data?.item?.description}`,
      route: '!#',
    },
  ];

  const handleChange = (e: any, variationId: any, currentAmount: any, lockedByTime: boolean) => {
    notifyImpedimentToIncrease(
      e.target.value,
      currentAmount,
      setIsNotAllowToDecrease,
      setShowAlert,
      lockedByTime
    );
    setVariationQty({ ...variationQty, id: variationId, qty: e.target.value });
  };

  const handleQuickCommit = async () => {
    mutate(
      { commitsData: { commits: [variationQty] }, dealId: response?.data?.id },
      {
        onSuccess: (data) => {
          if (data?.status) {
            setShowAlert({
              show: true,
              message: data?.error
                ? data?.error
                : 'You have committed to this deal successfully',
              title: data?.error ? 'Error' : 'Success',
              type: data?.error ? 'warning' : 'success',
            });
            queryClient.invalidateQueries('ACTIVEDEALS');
            refetch();
          } else {
            setShowAlert({
              show: true,
              message: data?.error,
              title: 'Error',
              type: 'warning',
            });
          }
        },
        onError: (error: any) => {
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
        },
      },
    );
  };

  let addnew = (deal: any) => {
    addItem(deal);
    setShowAlert({
      show: true,
      message: 'Item added successfully',
      title: 'Success',
      type: 'success',
    });
  };

  const handleCopyClick = (text: string) => {
    handleGeneralCopyClick(
      text,
      {
        show: true,
        message: text,
        title: 'Text Copied!',
        type: 'info',
      },
      {
        show: true,
        title: 'Coping Error',
        type: 'error',
      },
      setShowAlert,
    );
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.view, alertTurnScreenMobile.view]);
  

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
        />
      )}
      <main
        className='block xl:space-y-12 lg:mb-28 xl:mb-[86rem] xl:mt-11'
        id='mainCards'>
        <CardContent
          styles={
            'rounded-b-3xl mb-10 xl:mb-0 pt-10 xl:p-12 xl:pr-1 xl:absolute xl:top-10 xl:w-[79%]'
          }>
          <div className='px-5 xl:ml-7 text-gray-400 mb-5'>
            <BreadCrumbs links={breadCrumbsActiveDeal} />
          </div>
          <ProductCard deal={response?.data} />
        </CardContent>
        <OperationsMobileView
          deal={response?.data}
          dealFormatted={response?.data}
          functions={{
            addnew: addnew,
            handleChange: handleChange,
            handleCopyClick: handleCopyClick,
            handleQuickCommit: handleQuickCommit,
          }}
          variables={{
            colors: colors,
            isLoading: isLoading,
            qtyDayExpires: response?.data?.days_to_expire,
            variationQtyId: variationQty.id,
            nextSteps: nextSteps,
            isNotAllowToDecrease: isNotAllowToDecrease,
          }}
          loadingIcon={loadingIcon}
        />
        <div
          id='operations'
          className='hidden xl:block absolute top-96 w-[79%] space-y-5'>
          <CardContent
            styles={
              'flex justify-between rounded-3xl p-14 h-[37rem] space-x-14'
            }
            id='operationsCard'>
            <section className='w-4/12' id='commitToProduct'>
              <h2 className='text-xl fontGTAmericanR font-bold'>
                1. Commit to Product
              </h2>
              {response?.data?.locked || response?.data?.is_fully_committed ? (
                <p className='mt-16 mb-0 text-gray-400 fontGTAmericanR text-sm'>
                  <LockOutlinedIcon
                    sx={{ height: 21, marginRight: 1, width: 21 }}
                  />
                  No More Commitments Allowed
                </p>
              ) : response?.data?.enabled ? (
                <p className='mt-16 mb-0 text-gray-400 fontGTAmericanR text-sm'>
                  <LockOutlinedIcon
                    sx={{ height: 21, marginRight: 1, width: 21 }}
                  />
                  You&apos;ve already committed to this deal
                </p>
              ) : (response?.data?.days_to_expire >= 0 ? false : true) ? (
                <p className='mt-16 mb-0 text-gray-400 fontGTAmericanR text-sm'>
                  <LockOutlinedIcon
                    sx={{ height: 21, marginRight: 1, width: 21 }}
                  />
                  Deal Expired
                </p>
              ) : (
                <div>
                  <p className='mt-8 text-gray-400 fontGTAmericanR text-[0.875rem]'>
                    This product is available in multiple colors
                  </p>
                  <Stack
                    data-testid='quickCommitView'
                    sx={{
                      borderRadius: 3,
                      padding: 2,
                      paddingBottom: 0,
                    }}>
                    <div className='h-[20rem] overflow-y-auto'>
                      <Styles.TableHeaderCommitContainer className='flex px-4'>
                        <h3 className='fontGTAmericanR'>Color</h3>
                        <h3 className='ml-16 fontGTAmericanR'>Qty Committed</h3>
                      </Styles.TableHeaderCommitContainer>
                      {response?.data?.quantityUser?.map(
                        (item: any, index: any) => (
                          <>
                            {item.fullyCommitted && (
                              <p className='mb-0 text-xs bg-red-100 font-bold mt-3 p-[2px] text-center rounded-sm'>
                                You've already committed to this item's
                                variation.
                              </p>
                            )}
                            <div
                              className='flex rounded-lg items-center justify-center bg-slate-200 mt-2'
                              key={index}>
                              <div className='flex justify-between w-10/12 items-center h-14'>
                                <span
                                  className={`text-sm fontGTAmericanR text-left truncate`}>
                                  {item.color}
                                </span>
                                <input
                                  className='ml-3 text-slate-500 cursor-text px-2 bg-slate-100 border border-gray-400 h-9 w-[4rem] rounded-lg items-center flex font-semibold disabled:cursor-default'
                                  disabled={item.fullyCommitted}
                                  type='number'
                                  data-testid={`inputQuickCommit#${item.id}`}
                                  name={item.color}
                                  min={item.locked_by_time ? item.amount : 0}
                                  onChange={(e: any) =>
                                    handleChange(e, item.id, item.amount, item.locked_by_time)
                                  }
                                  defaultValue={item.amount}
                                />
                                <Button
                                  disabled={
                                    isLoading ||
                                    item.id !== variationQty.id ||
                                    item.fullyCommitted ||
                                    isNotAllowToDecrease
                                  }
                                  variant='outlined'
                                  data-testid={`btnSave#${item.id}`}
                                  onClick={handleQuickCommit}
                                  sx={{
                                    border: '1.5px solid',
                                    borderRadius: 2,
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                      backgroundColor: '#0069d9',
                                      borderColor: '#0062cc',
                                      boxShadow: 'none',
                                      color: colors.white,
                                    },
                                  }}>
                                  {isLoading ? (
                                    <img
                                      className='h-5 w-5'
                                      src={loadingIcon}
                                      alt='loading icon'
                                      width={200}
                                      height={200}
                                    />
                                  ) : (
                                    <span className='fontGTAmericanR'>
                                      Save
                                    </span>
                                  )}
                                </Button>
                              </div>
                            </div>
                          </>
                        ),
                      )}
                    </div>
                  </Stack>
                  <div className='mt-4 block space-y-4'>
                    <Button
                      variant='outlined'
                      size='large'
                      onClick={() => {
                        addnew(response?.data);
                      }}
                      sx={{
                        textDecoration: 'underline',
                        border: 'none',
                        color: colors.grayLabels,
                        width: '100%',
                        textTransform: 'none',
                        padding: '12px 0px',
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: 'white',
                          boxShadow: 'none',
                          border: 'none',
                          textDecoration: 'underline',
                        },
                      }}>
                      Add to Deal Cart
                    </Button>
                  </div>
                </div>
              )}
            </section>
            <Divider orientation='vertical' />
            <section className='w-4/12' id='purchaseProduct'>
              <h2 className='text-xl fontGTAmericanR font-bold'>
                2. Purchase Product
              </h2>
              <p className='mt-8 text-gray-400 fontGTAmericanR text-[0.875rem] w-10/12'>
                Once you have committed to this product, use this link to
                purchase the product in chosen quantity and style. If there are
                multiple options, choose your preferred retailer
              </p>
              <div
                className='mt-12 block h-[18rem] overflow-y-auto'
                id='shopOptions'>
                {response?.data?.variations.map((variation: any) =>
                  variation?.stores?.map((store: any, index: number) => {
                    const transfomImages = store?.icon?.split('//')[1];

                    return (
                      <div key={index}>
                        <div className='border-2 rounded-[2.2rem] w-10/12'>
                          <a href={store.link} target='_blank' rel='noreferrer'>
                            <div className='flex mr-9  items-center p-4 pl-6'>
                              <picture className='w-8 h-8'>
                                <img
                                  alt='Shop Image'
                                  height={40}
                                  src={`https://${transfomImages}`}
                                  width={40}
                                />
                              </picture>
                              <span className='items-center ml-5 text-blue-400 fontGTAmericanR'>
                                Shop on {store.name}
                              </span>
                            </div>
                          </a>
                        </div>
                        <div className='underline text-gray-400 mt-3 mb-4'>
                          <Button
                            onClick={() => {
                              handleCopyClick(variation?.model);
                            }}
                            startIcon={
                              <ContentCopyIcon
                                sx={{ height: 17, marginRight: 1 }}
                              />
                            }
                            sx={{
                              color: colors.grayLabels,
                              textTransform: 'none',
                              fontWeight: 'bold',
                              fontSize: 13,
                            }}>
                            Copy Item SKU
                          </Button>
                        </div>
                      </div>
                    );
                  }),
                )}
              </div>
            </section>
            <Divider orientation='vertical' />
            <section className='w-4/12' id='shipProduct'>
              <h2 className='text-xl fontGTAmericanR font-bold'>
                3. Ship to Product
              </h2>
              <p className='mt-8 text-gray-400 fontGTAmericanR text-[0.875rem] w-12/12'>
                You can either ship directly to our processing centers, or ship
                product to your own address and reship or hand-deliver to our
                processing centers.
              </p>
              <p className='mt-4 text-gray-400 fontGTAmericanR text-[0.875rem] w-12/12'>
                to ship to our processing center, choose a location.
              </p>
              <p className='mt-4 text-gray-400 fontGTAmericanR text-[0.875rem]'>
                For information on re-shipping or in-person drop-offs,
                {/* <Link className='underline text-gray-400' to='#!'>
                  learn more here
                </Link> */}
              </p>
              <div className='mt-9' id='deliveryOptions'>
                {locationOptionsShipProduct?.map(
                  (location: any, index: any) => (
                    <div
                      className='flex justify-between bg-slate-200 rounded-xl w-[100%] mb-6 p-3 font-semibold text-gray-400'
                      key={index}>
                      <LocationOnOutlinedIcon sx={{ height: 16, width: 16 }} />
                      <span className='ml-2 text-sm'>{location.name}</span>
                      <div className='underline text-gray-400 ml-6 w-1/12'>
                        <Button
                          data-testid='buttonCopyLocation'
                          onClick={() => {
                            handleCopyClick(location.name);
                          }}
                          startIcon={
                            <ContentCopyIcon sx={{ height: 15, width: 15 }} />
                          }
                          sx={{
                            color: colors.grayLabels,
                            p: 0,
                          }}></Button>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </section>
          </CardContent>
          <div className='flex justify-end'>
            <CardContent
              styles={'rounded-3xl w-[39%] flex justify-center py-16'}>
              <section className='' id='nextSteps'>
                <h2 className='fontGTAmericanR text-lg font-bold'>
                  Next Steps
                </h2>
                <div className=' mt-10' id='stepOptions'>
                  {nextSteps?.map((step: any, index: any) => (
                    <Link
                      to={step.route}
                      className='flex justify-between border-2 rounded-[2.2rem] items-center p-6 mb-6 h-[4.35rem] w-[110%]'
                      key={index}
                      state={step.state}>
                      <div className='flex items-center'>
                        {step.icon}
                        <span className='ml-5 fontGTAmericanR  text-[#178DFF]'>
                          {step.name}
                        </span>
                      </div>
                      <div className='flex '>
                        <span className='flex'>{step.arrow}</span>
                      </div>
                    </Link>
                  ))}
                </div>
                <p className='mt-8 font-semibold text-sm mb-0 opacity-50'>
                  For more information,{' '}
                  <Link
                    className='underline hover:text-gray-500 hover:underline'
                    to='/help-center'>
                    check out our Help section
                  </Link>
                </p>
              </section>
            </CardContent>
          </div>
        </div>
      </main>
    </>
  );
};

export default ActiveDealModule;
