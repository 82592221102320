import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Table } from 'antd';
import { themeCustom } from '../../../theme';
import getPurchaseInfo from '../../../api/services/trackingNumbers/getPurchase';
import usePriceFormat from '../../../hooks/usePriceFormat';
import SubmmittedBy from '../../../components/UI/sideSheet/containers/SubmmittedBy';
const { colors } = themeCustom;

import * as Styles from '../../purchaseOrders/style';
import { dataTableFieldsDetails } from '../constants/index';

export const DetailsTrackingNumbers = ({
  data,
  purchase,
  setViewModal,
}: any) => {
  const { response } = getPurchaseInfo(purchase?.po_number);

  return (
    <>
      <div id='header'>
        <div className='flex justify-between'>
          <p className='font-semibold'>Tracking Number</p>
          <Button
            sx={{
              textTransform: 'none',
              color: 'black',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => setViewModal(false)}>
            <Box sx={{ color: colors.grayLabels }} data-testid='deleteButton'>
              <CancelIcon />
              Close
            </Box>
          </Button>
        </div>
        <h3 className='text-lg xlc:text-2xl font-medium'>
          {data?.tracking_number}
        </h3>
      </div>
      <Divider />
      <Box sx={{ mt: 4, p: 2, display: 'flex' }}>
        <div id='labels' className='space-y-[1.7rem]'>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels }}>
              Date
            </Typography>
          </div>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels }}>
              Purchase ID
            </Typography>
          </div>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels }}>
              Value
            </Typography>
          </div>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels }}>
              Submmitted by
            </Typography>
          </div>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels }}>
              Pay Status
            </Typography>
          </div>
        </div>
        <div className='ml-10 space-y-8 mt-1'>
          <div>
            {/* <span className='underline'>{response?.pO?.date}</span> */}
            <span className=''>{data?.date_added}</span>
          </div>
          <div>
            <span className='underline'>
              {response?.pO?.purchase_id || 'Not Received'}
            </span>
          </div>
          <div>
            <data value={response?.pO?.amount}>
              {usePriceFormat(response?.pO?.amount ? response?.pO?.amount : 0)}
            </data>
          </div>
          <div className='flex'>
            <SubmmittedBy isBuyerSubmitted={data?.is_buyer_submitted} />
          </div>
          <div>
            <>
              {response?.pO?.status ? (
                <>
                  {purchase?.status === 'Pending' && (
                    <span className='text-orange-600 fontGTAmericanR '>
                      Pending
                    </span>
                  )}
                  {purchase?.status === 'Complete' && (
                    <span className='text-green-600 fontGTAmericanR '>
                      Complete
                    </span>
                  )}
                  {purchase?.status === 'Unpaid' && (
                    <span className='text-gray-400 fontGTAmericanR '>
                      Unpaid
                    </span>
                  )}
                </>
              ) : (
                <span className='text-gray-400 fontGTAmericanR '>
                  Not Purchase
                </span>
              )}
            </>

            {/* <span className='text-green-600'>
            {response?.pO?.status || 'N/A'}
        </span>*/}
          </div>
        </div>
      </Box>
      <div onTouchEndCapture={(e) => e.stopPropagation()}>
        <Box
          className='overflow-x-auto h-[30rem]'
          sx={{
            mt: 7,
            p: 4,
            borderRadius: 3,
            border: '1px solid #b2b2b2',
            marginX: 1,
          }}>
          <div className='flex justify-between p-4 pt-0'>
            <h5 className='font-semibold text-base'>Products</h5>
            {/* <Button
              sx={{
                color: colors.grayLabels,
                textDecoration: 'underline',
                textTransform: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'white',
                },
              }}>
              <FileDownloadOutlinedIcon />
              <span className='ml-2 '>Export</span>
            </Button> */}
          </div>
          <Divider />
          <Styles.TableContainer>
            <Table
              className='tableStyles tableHeadtyles tableBodyStyles'
              columns={dataTableFieldsDetails}
              pagination={false}
              dataSource={response?.items}></Table>
          </Styles.TableContainer>
        </Box>
      </div>
    </>
  );
};
