import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
// import SideSheet from '../../../components/UI/sideSheet';
import SubmmittedBy from '../../../components/UI/sideSheet/containers/SubmmittedBy';
import DateFormater from '../containers/DateFormater';
import { dateFormatSorter } from '../../../helpers';
import { RenderActions } from '../containers/RenderActions';
import usePriceFormat from '../../../hooks/usePriceFormat';
import PurchaseAmount from '../../../components/UI/sideSheet/containers/PurchaseAmount';
import useResponsive from '../../../hooks/useResponsive';
import InsurancePolicy from '../containers/InsurancePolicy';
import EditOtpCode from '../containers/EditOtpCode';
import TrackingInfoGrid from '../containers/TrackingInfoGrid';

export const boxValue = {
  title: 'Value / Coverage',
  dataIndex: 'box_value',
  width: '180px',
  key: 'box_value',
  className: 'fontGTAmericanR w-[150px] xlc:w-[180px]',
  // sorter: (a: any, b: any) =>
  //   a?.tracking_number.localeCompare(b?.tracking_number),
  render: (insurance: any, item: any) => <InsurancePolicy item={item} />,
};

export const discrepancy = {
  title: 'discrepancy',
  dataIndex: 'discrepancy_value',
  width: '100px',
  key: 'tracking_number',
  className: 'fontGTAmericanR w-[100px] xlc:w-[150px]',
  sorter: (a: any, b: any) => {
    return a.discrepancy - b.discrepancy;
  },
  render: (item: any) => (
    <p className='truncate-text-1-lines text-xs mb-0'>
      {usePriceFormat(item || 0)}
    </p>
  ),
};

export let dataTableFields: ColumnsType<any> = [
  {
    title: <span data-testid='desktopDateTitle'>Date</span>,
    dataIndex: 'date_added',
    key: 'date_added',
    // width: '200px',
    className: 'fontGTAmericanR w-[110px] xlc:w-[120px] xl2_1:w-[170px]',
    responsive: ['xl'],
    sorter: (a: any, b: any) =>
      new Date(dateFormatSorter(a?.date_added)).getTime() -
      new Date(dateFormatSorter(b?.date_added)).getTime(),
    render: (date_added) => <DateFormater date={date_added} />,
  },
  {
    title: 'Tracking',
    dataIndex: 'tracking_number',
    fixed: 'left',
    // width: '150px',
    key: 'tracking_number',
    className: 'fontGTAmericanR w-[130px] xlc:w-[170px] xl2_1:w-[190px]',
    // sorter: (a: any, b: any) =>
    //   a?.tracking_number.localeCompare(b?.tracking_number),
    render: (tracking, item) => (
      <TrackingInfoGrid tracking={tracking} item={item} />
    ),
  },
  {
    title: 'OTP',
    dataIndex: 'otp_code',
    key: 'otp_code',
    width: '70px',
    className: 'fontGTAmericanR w-[150px] xlc:w-[180px] ',
    // sorter: (a: any, b: any) => a?.otp_code.localeCompare(b?.otp_code),
    render: (item, tracking) => <EditOtpCode item={item} tracking={tracking} />,
  },
  {
    title: <span data-testid='mobileDateTitle'>Date</span>,
    dataIndex: 'date_added',
    width: '90px',
    key: 'date_addedC',
    responsive: ['xs', 'sm', 'md', 'lg'],
    className: 'fontGTAmericanR xlc:hidden w-[90px] xlc:w-[0px]',
    sorter: (a: any, b: any) =>
      new Date(dateFormatSorter(a?.date_added)).getTime() -
      new Date(dateFormatSorter(b?.date_added)).getTime(),
    render: (date_added) => <DateFormater date={date_added} />,
  },
  {
    title: 'Purchase ID',
    dataIndex: 'purchase_id',
    key: 'purchaseId',
    width: '120px',
    className: 'fontGTAmericanR w-[150px] xlc:w-[190px]',
    // sorter: (a: any, b: any) => {
    //   return a?.purchase?.po_number - b?.purchase?.po_number;
    // },
    render: (purchase_id, tracking_number) => {
      return (
        <div className='text-xs'>
          {purchase_id && tracking_number?.purchase?.status != 'Cancelled'
            ? // <SideSheet
              //   purchase={tracking_number?.purchase}
              //   trackingInfo={tracking_number}
              // />
              tracking_number?.purchase?.purchase_id
            : tracking_number.status}
        </div>
      );
    },
  },
  {
    title: 'Value',
    dataIndex: 'cost',
    key: 'value',
    width: '100px',
    className: 'fontGTAmericanR w-[130px] xlc:w-[140px]',
    sorter: (a: any, b: any) => {
      return a.cost - b.cost;
    },
    render: (cost) => {
      if (cost !== 0) {
        return (
          <p className='truncate-text-1-lines text-xs mb-0'>
            {usePriceFormat(cost || 0)}
          </p>
        );
      }
    },
  },
  {
    title: 'Submitted',
    dataIndex: 'is_buyer_submitted',
    key: 'is_buyer_submitted',
    ellipsis: true,
    width: '95px',
    className: 'fontGTAmericanR text-sx',
    // sorter: (a: any, b: any) => a?.is_buyer_submitted - b?.is_buyer_submitted,
    render: (is_buyer_submitted) => (
      <SubmmittedBy isBuyerSubmitted={is_buyer_submitted} />
    ),
  },
  {
    title: <span data-testid='desktopPSTitle'>Payment Status</span>,
    dataIndex: 'purchase',
    key: 'payStatus',
    width: '140px',
    className: 'fontGTAmericanR text-sx',
    sorter: (a: any, b: any) => {
      return a.purchase?.status.length - b.purchase?.status.length;
    },
    render: (purchase) => (
      // TODO: SET CORRECT VALUE
      <>
        {purchase ? (
          <>
            {purchase?.status === 'Pending' && (
              <span className='text-orange-600 fontGTAmericanR text-xs'>
                Pending
              </span>
            )}
            {purchase?.status === 'Complete' && (
              <span className='text-green-600 fontGTAmericanR text-xs'>
                Complete
              </span>
            )}
            {purchase?.status === 'Unpaid' && (
              <span className='text-gray-400 fontGTAmericanR text-xs'>
                Unpaid
              </span>
            )}
          </>
        ) : (
          <span className='text-gray-400 fontGTAmericanR text-xs'>
            Not received
          </span>
        )}
      </>
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'purchase',
    key: 'action',
    width: '130px',
    // responsive: ['xl'],
    className: 'fontGTAmericanR w-[150px]',
    render: (purchase, tracking_number) => {
      return (
        <div className='block'>
          <RenderActions purchase={purchase} trackingInfo={tracking_number} />
        </div>
      );
    },
  },
  Table.EXPAND_COLUMN,
];

export const dataTableFieldsDetails: ColumnsType<any> = [
  {
    title: 'Item',
    dataIndex: 'product',
    width: '300px',
    key: 'product',
    render: (product) => (
      <span className='mr-8 text-left text-[13px] fontGTAmericanR mt-2 truncate-text'>
        {product?.name}
      </span>
    ),
  },
  {
    title: 'SKU',
    dataIndex: 'product',
    key: 'product',
    render: (product) => <span>{product?.internal_sku}</span>,
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
    width: '70px',
    key: 'quantity',
    render: (item) => (
      <span className='fontGTAmericanR font-semibold'>{item}</span>
    ),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (item) => (
      <span className='fontGTAmericanR font-semibold'>
        {usePriceFormat(item)}
      </span>
    ),
  },
  {
    title: 'Amount',
    key: 'price',
    render: (itemImfo) => <PurchaseAmount itemInfo={itemImfo} />,
  },
];

export const dateFilter = [
  {
    name: 'datefilter',
    styles: 'mt-7 w-8',
  },
];

export const searchFilter = [
  {
    name: 'tracking_number',
    placeHolder: 'Search Tracking Numbers',
    styles: 'mt-7 ml-20',
    stylesFirstContainer: '',
    stylesSecondContainer: 'mb-5 xl:mb-0 xl:mr-5',
    width: { xs: '100%', lg: 160, xl: 220 },
  },
];

export const filterInsurance = {
  name: 'insurance_status_id',
  selectWidth: () => {
    const { isScreenLaptop, isDefaultWidth, isUltraWidth } = useResponsive();
    if (isUltraWidth) {
      return 150;
    }

    if (isDefaultWidth) {
      return 100;
    }

    if (isScreenLaptop) {
      return 70;
    }
  },
  stylesFirstContainer: () => {
    return 'mt-6';
  },
  stylesSecondContainer: 'items-center mr-4 mb-8 lg:mb-0',
  title: 'Coverage',
  menuItems: [
    {
      value: '',
      labelName: 'All',
    },
    {
      value: 1,
      labelName: 'Validating tracking number',
    },
    {
      value: 2,
      labelName: 'Invalid tracking number information',
    },
    {
      value: 3,
      labelName: 'Pending to validate tracking',
    },
    {
      value: 4,
      labelName: 'Creating coverage',
    },
    {
      value: 5,
      labelName: 'Covered',
    },
    {
      value: 6,
      labelName: 'Not valid tracking number',
    },
    {
      value: 7,
      labelName: 'Coverage error',
    },
  ],
};

export const selectFiltersTN = [
  {
    name: 'receiving_status_id',
    selectWidth: () => {
      const { isScreenLaptop, isDefaultWidth, isUltraWidth } = useResponsive();
      if (isUltraWidth) {
        return 150;
      }

      if (isDefaultWidth) {
        return 100;
      }

      if (isScreenLaptop) {
        return 90;
      }
    },
    stylesFirstContainer: () => {
      return 'mt-6';
    },
    stylesSecondContainer: 'items-center mr-4 mb-8 lg:mb-0',
    title: 'Receiving Status',
    menuItems: [
      {
        value: '',
        labelName: 'All',
      },
      {
        value: 1,
        labelName: 'Received',
      },
      {
        value: 2,
        labelName: 'Not received',
      },
    ],
  },

  {
    name: 'buyer_submitted',
    selectWidth: () => {
      const { isScreenLaptop, isDefaultWidth, isUltraWidth } = useResponsive();
      if (isUltraWidth) {
        return 150;
      }

      if (isDefaultWidth) {
        return 100;
      }

      if (isScreenLaptop) {
        return 80;
      }
    },
    stylesFirstContainer: () => {
      return 'mt-6';
    },
    stylesSecondContainer: 'items-center mr-4 mb-8 lg:mb-0',
    title: 'Submitted by',
    menuItems: [
      {
        value: '',
        labelName: 'All',
      },
      {
        value: 1,
        labelName: 'Buyer',
      },
      {
        value: 2,
        labelName: 'Warehouse',
      },
    ],
  },
  {
    name: 'otp_code_submitted',
    selectWidth: () => {
      const { isScreenLaptop, isDefaultWidth, isUltraWidth } = useResponsive();
      if (isUltraWidth) {
        return 150;
      }

      if (isDefaultWidth) {
        return 100;
      }

      if (isScreenLaptop) {
        return 70;
      }
    },
    stylesFirstContainer: () => {
      return 'mt-6';
    },
    stylesSecondContainer: 'items-center mr-4 mb-8 lg:mb-0',
    title: 'Otp Code',
    menuItems: [
      {
        value: '',
        labelName: 'All',
      },
      {
        value: 1,
        labelName: 'Submitted',
      },
      {
        value: 2,
        labelName: 'Not submitted',
      },
    ],
  },
  {
    name: 'payment_status_id',
    selectWidth: () => {
      const { isScreenLaptop, isDefaultWidth, isUltraWidth } = useResponsive();
      if (isUltraWidth) {
        return 150;
      }

      if (isDefaultWidth) {
        return 100;
      }

      if (isScreenLaptop) {
        return 90;
      }
    },
    stylesFirstContainer: () => {
      return 'mt-6';
    },
    stylesSecondContainer: 'items-center mr-4 mb-8 lg:mb-0',
    title: 'Payment Status',
    menuItems: [
      {
        value: '',
        labelName: 'All',
      },
      {
        value: 'Complete',
        labelName: 'Paid',
      },
      {
        value: 'Pending',
        labelName: 'Unpaid',
      },
    ],
  },
  {
    name: 'tracking_number_status_id',
    selectWidth: () => {
      const { isScreenLaptop, isDefaultWidth, isUltraWidth } = useResponsive();
      if (isUltraWidth) {
        return 150;
      }

      if (isDefaultWidth) {
        return 100;
      }

      if (isScreenLaptop) {
        return 90;
      }
    },
    stylesFirstContainer: () => {
      return 'mt-6';
    },
    stylesSecondContainer: 'items-center mr-4 mb-8 lg:mb-0',
    title: 'Tracking Status',
    menuItems: [
      {
        value: '',
        labelName: 'All',
      },
      {
        value: 1,
        labelName: 'Unreceived',
      },
      {
        value: 2,
        labelName: 'Received',
      },
      {
        value: 3,
        labelName: 'Verify package contents',
      },
      {
        value: 4,
        labelName: 'Waiting to be received',
      },
      {
        value: 5,
        labelName: 'Rejected',
      },
      {
        value: 6,
        labelName: 'Deleted',
      },
      {
        value: 7,
        labelName: 'Scanned for OTP',
      },
      {
        value: 8,
        labelName: 'Scanned at dock',
      },
    ],
  },
];

export const stylesFilterSectionSearchAndDate = () => {
  const { isDesktopCustomTable, isScreenLaptop, isDefaultWidth, isUltraWidth } =
    useResponsive();

  if (isUltraWidth) {
    return 'flex justify-end mt-5';
  }

  if (isDefaultWidth) {
    return 'flex justify-end mt-5';
  }
  if (isScreenLaptop) {
    return 'flex justify-end mt-5';
  }
  if (isDesktopCustomTable) {
    return 'flex justify-end mt-5';
  }
};
