import React from 'react';
import * as Styles from './style';
import PurchaseSummaryCard from '../../components/UI/purchaseSummaryCard';
import { dataTableFields } from './constants';
import { Table } from 'antd';
import { useCart } from 'react-use-cart';
import MobileList from '../../components/UI/mobileList/index';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';

const MyDealsCartModule = () => {
  const { items } = useCart();

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.view, alertTurnScreenMobile.view]);

  return (
    <main className='mt-11'>
      <header id='title'>
        <h1 className='lg:ml-5 text-2xl lg:text-4xl fontSharpGrotesk22Medium text-black mb-10 '>
          My Deals Cart
        </h1>
      </header>
      <div className='hidden lg:block'>
        <Styles.BackgroundContainer>
          <Table
            columns={dataTableFields}
            // className='itemTableTd tableStyles mt-9'
            pagination={false}
            dataSource={items}
            scroll={{ x: 1300 }}
            className='xl1:h-[calc(100vh-550px)] xlc:h-[calc(100vh-550px)] xl2:h-[calc(100vh-550px)] overflow-auto flex-1 min-h-[250px]'
          />
          <PurchaseSummaryCard />
        </Styles.BackgroundContainer>
      </div>

      <div className='lg:hidden'>
        <PurchaseSummaryCard />
      </div>
      <div>
        <MobileList data={items} isMyCart={true} />
      </div>
      {items.length > 0 && (
        <div className='lg:hidden'>
          <PurchaseSummaryCard />
        </div>
      )}
    </main>
  );
};

export default MyDealsCartModule;
