import React from 'react';
import Dashboard from '../pages/Dashboard';
import TrackingNumbers from '../pages/TrackingNumbers';
import PurchaseOrders from '../pages/PurchaseOrders';
import HelpCenter from '../pages/HelpCenter';
import ActiveDeals from '../pages/ActiveDeals';
import ActiveDeal from '../pages/ActiveDeal';
import MyDealsCart from '../pages/MyDealsCarts';
import ReadFaqs from '../modules/helpCenter/containers/subModules/helpCenter/ReadFaqs';
import OpenHelpTicket from '../modules/helpCenter/containers/subModules/helpCenter/OpenHelpTicket';
import CheckTicketStatus from '../modules/helpCenter/containers/subModules/helpCenter/CheckTicketStatus';
import LatestUpdates from '../modules/helpCenter/containers/subModules/helpCenter/LatestUdpates';
import MyCommitments from '../pages/MyCommitments';
import UserProfile from '../pages/UserProfile';
import PaymentRequest from '../pages/PaymentRequest';
import QuickGuide from '../pages/QuickGuide';
import RedirectNewTrackings from '../pages/RedirectNewTrackings';

export const safeRoutes = [
  {
    path: '/dashboard',
    component: <Dashboard />,
    title: 'Dashboard',
  },
  {
    path: '/tracking-numbers',
    component: <TrackingNumbers />,
    title: 'Tracking Numbers',
  },
  {
    path: '/purchase-orders',
    component: <PurchaseOrders />,
    title: 'Purchase Orders',
  },
  {
    path: '/help-center',
    component: <HelpCenter />,
    title: 'Help Center',
  },
  {
    path: '/active-deals',
    component: <ActiveDeals />,
    title: 'Active Deals',
  },
  {
    path: '/active-deal/:id',
    component: <ActiveDeal />,
    title: 'active Deal',
  },
  {
    path: '/my-deals-cart',
    component: <MyDealsCart />,
    title: 'My Deals Cart',
  },
  {
    path: '/payment-request',
    component: <PaymentRequest />,
    title: 'Payment Request',
  },
  {
    path: '/read-faqs',
    component: <ReadFaqs />,
    title: 'Read Faqs',
  },
  {
    path: '/open-help-ticket',
    component: <OpenHelpTicket />,
    title: 'Open Help Ticket',
  },
  {
    path: '/check-ticket-status',
    component: <CheckTicketStatus />,
    title: 'Check Ticket Status',
  },
  {
    path: '/latest-updates',
    component: <LatestUpdates />,
    title: 'Latest Updates',
  },
  {
    path: '/my-commitments',
    component: <MyCommitments />,
    title: 'My Commitments',
  },
  {
    path: '/my-account',
    component: <UserProfile />,
    title: 'My Account',
  },
  {
    path: '/quick-start-guide',
    component: <QuickGuide />,
    title: 'Quick Start Guide',
  },
  {
    path: '/newtracking',
    component: <RedirectNewTrackings />,
    title: 'New trackings',
  }
];
