import React from 'react';

const DateFormater = ({ date }: any) => {
  let dateSplited = date.split(' ');
  let formattedDate = dateSplited[0];
  let hour = dateSplited[1];

  return (
    <div className='flex flex-wrap'>
      <span className='underline mr-2 text-xs'>{formattedDate}</span>
      <span className='text-xs'>
        {hour.search('pm') > 0
          ? hour.replace('pm', 'PM')
          : hour.replace('am', 'AM')}
      </span>
    </div>
  );
};

export default DateFormater;
