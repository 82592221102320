import React, { useEffect, useState } from 'react';
import SmallCard from '../../components/UI/smallCard';
import WideCard from '../../components/UI/wideCard';
import CardContent from '../../components/UI/cardContent';
import getInfoCards from '../../api/services/dashboard/getInfoCards';
import cookie from 'react-cookies';
import {
  dataCard,
  dataWideCard,
  // updatesCardContent,
  // notifications,
} from './constants';
import CardUpdates from './components/CardUpdates';
// import SearchIcon from '@mui/icons-material/Search';
import DateRangeIcon from '@mui/icons-material/DateRange';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import Notification from './components/notification';
// import * as Styles from './style';
// import { Popover } from 'antd';
import useStatesGlobalStore from '../../hooks/useStatesGlobalStore';
import { IAlertType } from '../../interfaces/activeDeal/index';
import GeneralAlert from '../../components/UI/generalAlert/GeneralAlert';
import getActiveDeals from '../../api/services/activeDeals/index';
import List from '../activeDeals/containers/List';

import * as Style from '../activeDeals/style';
import moment from 'moment';
import { IFilters } from '../../interfaces/activeDeals';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';
import useGetUpdatesInfo from '../../api/services/helpCenter/getUpdatesInfo';
import { Checkbox, Modal, Spin } from 'antd';
import type { CheckboxProps } from 'antd';
import { useAddInsuranceEnrroled } from '../../api/services/trackingNumbers/addInsuranceEnroll';
import { useNavigate } from 'react-router-dom';

function DashboardModule() {
  const { response } = getInfoCards();
  const { dashboardInfo, setDashboardInfo, buyerInfo } = useStatesGlobalStore();

  const navigate = useNavigate();
  const [filterOptions] = React.useState<IFilters>({
    brands: [],
    retailers: [],
    price: [],
    expires: [],
    // category: [],
    // filterSets: '',
    priceType: [],
  });

  const [current, setCurrent] = useState(1);
  const [sizeItemsPage, setSizeItemPage] = useState(10);
  const [fechaCurrent, setFechaCurrent] = useState({
    beforeMonth: '',
    day: '',
  });
  const [modalFollowUs, setmodalFollowUs] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const notShowAgain = localStorage.getItem('notShowAgain');
  const [enrolled, setEnrolled] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [rollPackageModal, setrollPackageModal] = useState(false);
  const [modalSucces, setModalSuccess] = useState(false);
  const [alreadyEnroll, setAlreadyEnroll] = useState(false);

  const enroll = cookie.load('enroll');

  const handleClose = () => {
    setmodalFollowUs(false);
  }

  const closeModal = () => {
    setrollPackageModal(false);
    cookie.remove('enroll');
  }

  const { response: responseEnrolled, refetch } = getInfoCards();
  const { mutate, isLoading: loadMutation } = useAddInsuranceEnrroled(setEnrolled, setMessageError, closeModal, refetch);

  const onSubmit = () => {
    mutate();
    setTimeout(() => {
      closeModal();
    }, 2000);
  };

  useEffect(() => {
    if (parseInt(enroll) === 1 && dashboardInfo?.info?.trackings_insurance_available === 1) {
      if (responseEnrolled && responseEnrolled?.data?.insurance_enroll === 0 ) {
        setrollPackageModal(true);
      } else if (responseEnrolled && responseEnrolled?.data?.insurance_enroll === 1) {
        setAlreadyEnroll(true);
      }
    }
  }, [enroll, enrolled, messageError, responseEnrolled]);

  useEffect(() => {
   if (enrolled) {
    setModalSuccess(true);
    setTimeout(() => {
      navigate('/tracking-numbers')
    }, 500);
   }
  }, [enrolled])

  const closeModalSuccess = () => {
    setModalSuccess(false);
  }

  const closeModalAlready = () => {
    setAlreadyEnroll(false);
    cookie.remove('enroll');
  }

  const onChange: CheckboxProps['onChange'] = (e) => {
    setIsChecked(e.target.checked);
    setTimeout(() => {
      localStorage.setItem('notShowAgain', JSON.stringify(true));
      handleClose();
    }, 1000);
  };

  const { response: responseDeals, isLoading } = getActiveDeals({
    brand_name: filterOptions.brands,
    vendor: filterOptions.retailers,
    price: filterOptions.price,
    expired: filterOptions.expires,
    list_price_type: filterOptions.priceType,
    start: current,
    limit: sizeItemsPage,
  });

  const { response: updateInfo } = useGetUpdatesInfo({
    display_at_from: fechaCurrent.beforeMonth,
    display_at_until: fechaCurrent.day,
  });

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const { firstName } = buyerInfo;

  useEffect(() => {
    if (response?.data) {
      setDashboardInfo({ ...dashboardInfo, info: response?.data });
    }
  }, [response?.data]);

  const dataResponse: any = response;

  // useEffect(() => {
  //   setShowAlert({
  //     show: true,
  //     message: `Welcome to the USA Buying Group beta portal`,
  //     title: 'Success',
  //     type: 'success',
  //   });
  // }, [betaPortalAccess]);

  // const buildNotification = () => {
  //   return (
  //     <Styles.Card>
  //       <div className='sectionScroll mt-4 overflow-y-scroll h-[23rem] w-[11rem] px-2'>
  //         {notifications.map((notification, index) => (
  //           <Notification
  //             key={index}
  //             title={notification.title}
  //             status={notification.status}
  //           />
  //         ))}
  //       </div>
  //     </Styles.Card>
  //   );
  // };

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.view, alertTurnScreenMobile.view]);

  useEffect(() => {
    const day = new Date(); // Tu variable con la fecha

    const year = day.getFullYear();
    const month = String(day.getMonth() + 1).padStart(2, '0');
    const date = String(day.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${date}`;

    day.setMonth(day.getMonth() - 1);

    const fechaUnMesAntes = day.toISOString().split('T')[0];

    setFechaCurrent({ beforeMonth: fechaUnMesAntes, day: formattedDate });
  }, []);

  useEffect(() => {
    const hasVisited = sessionStorage.getItem('hasVisited');
    if (!notShowAgain) {
      if (!hasVisited) {
        setmodalFollowUs(true);
        sessionStorage.setItem('hasVisited', JSON.stringify(true));
      }
    }
  }, [notShowAgain])

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
    script.type = "module";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='px-1'>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <Modal
          visible={modalSucces}
          onCancel={closeModalSuccess}
          style={{ top: 300 }}
          bodyStyle={{ borderRadius: '20px' }}
          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
          footer={null}>
            <h1 className='text-center text-xl font-semibold pt-2'>The request has been submitted.</h1>
        </Modal>
        <Modal
          visible={alreadyEnroll}
          onCancel={closeModalAlready}
          style={{ top: 300 }}
          bodyStyle={{ borderRadius: '20px', backgroundColor: '#3b98f5' }}
          footer={null}>
            <h1 className='text-center text-white text-xl font-semibold pt-2'>You are already enrolled.</h1>
        </Modal>
      <Modal
        visible={rollPackageModal}
        onCancel={closeModal}
        style={{ top: 90 }}
        className='background-container'
        bodyStyle={{ backgroundColor: '#3b98f5', borderRadius: '20px', opacity: 11 }}
        width={window.innerWidth < 1200 ? '90%' : '60%'}
        footer={null}>
        <div className='flex flex-col justify-center items-center'>
          <h1 className='text-4xl text-white text-center font-bold w-7/12'>USA Buying Safe Ship Terms</h1>
          <div style={{ backgroundColor: '#8DC8FF', width: '94%', borderRadius: 20, padding: 12, height: '30rem',  overflowY: 'scroll' }}>
            <Spin spinning={loadMutation} size='large' style={{ backgroundColor: 'rgba(141, 200, 255, 0.6)' }}>
              <p className='text-xl text-justify'>
                By enrolling in this program, you agree to the following terms:
                <br /><br />
                <span className='font-semibold'>1.⁠ ⁠Enrollment and Coverage:</span><br />
                <br />
                - Participants must enroll in the program to receive coverage.<br />
                - Only packages shipped to our Warehouse(s) after enrollment and during the active enrollment period are covered.<br />
                - Coverage is limited up to the specified package limit.<br />
                <br />
                <span className='font-semibold'>2.⁠ ⁠Claims Process:</span><br />
                <br />
                - All claims must be submitted within One (1) week of delivery.<br />
                - Claims will undergo a verification process conducted by the program's team.<br />
                - Verification may include but is not limited to invoices etc.<br />
                - Invoices related to the shipment.<br />
                - Denial from the shipper/retailer.<br />
                - Proof of delivery documentation.<br />
                - Financial transactions associated with the claim.<br />
                - Possibly conducting remote sessions to verify submitted documents.<br />
                <br />
                <span className='font-semibold'>3.⁠ ⁠Fraud Prevention:</span><br />
                <br />
                - Claims suspected of being fraudulent will be denied.<br />
                - USA Buying Safe Ship reserves the right to deny any claim believed to be fraudulent.<br />
                - USA Buying Safe Ship also reserves the right to cancel enrollment if necessary.<br />
                <br />
                <span className='font-semibold'>4.⁠ ⁠Updates to Terms:</span><br />
                <br />
                - The terms of the program are subject to change at any time.<br />
                - Participants are bound by the most current terms as posted or provided.<br />
                <br />
                These terms are designed to ensure the integrity of the program and protect both participants and the program itself from fraudulent claims or misuse. It’s important for participants to understand these terms before enrolling to avoid any misunderstandings regarding coverage and claims.
              </p>
            </Spin>
          </div>
            <div className='text-center' style={{ backgroundColor: '#8DC8FF', borderRadius: 20, padding: 10, marginTop: 40, width: '78%', justifyContent: 'center', alignItems: 'center' }}>
              <Spin spinning={loadMutation} style={{ backgroundColor: 'rgba(141, 200, 255, 0.6)' }}>
                <Checkbox checked={isChecked} onChange={onChange}>
                  <p className='text-xl font-medium pt-5 text-justify'>I {buyerInfo?.firstName} {buyerInfo?.lastName} agree to these terms and conditions</p>
                </Checkbox>
                <button disabled={!isChecked} onClick={onSubmit}
                  className='flex items-center bg-[#178DFF] hover:bg-blue-600 text-white  disabled:bg-white px-5 pb-1  xl:rounded-3xl cursor-pointer disabled:text-[#178DFF] mx-auto'>
                  <div className='flex gap-1'>
                    <h2 className={isChecked ? 'text-white text-lg font-semibold pt-1 mb-0 fontGTAmericanR' : `text-#178DFF] text-lg font-semibold pt-1 mb-0 fontGTAmericanR`}>
                      Submit
                    </h2>
                  </div>
                </button>
              </Spin>
            </div>
        </div>
      </Modal >
      <Modal
        visible={modalFollowUs}
        onCancel={handleClose}
        style={{ top: 180, borderRadius: 20 }}
        className='background-container'
        bodyStyle={{ backgroundColor: '#3b98f5', borderRadius: '20px', opacity: 11 }}
        width={window.innerWidth < 1200 ? '80%' : '55%'}
        footer={null}>
        <div className='flex flex-col justify-center items-center'>
          <img src='/images/dashboard/follow-us-orange.png' alt='follow us' width={500} />
          <div className='flex flex-wrap mt-10 mb-3 gap-3 justify-center items-end'>
            <a href='https://discord.gg/usabuyinggroup' target="_blank">
              <img src='/images/dashboard/discord-orange.png' alt='follow us' style={{ width: '150px', height: '125px', paddingTop: '10px' }} />
            </a>
            <a target="_blank" href='https://www.instagram.com/usabuyinggroup/'>
              <img src='/images/dashboard/insta-orange.png' alt='follow us' style={{ width: '150px', height: '130px' }} />
            </a>
            <a target="_blank" href='https://x.com/USABuyingGroup'>
              <img src='/images/dashboard/x-orange.png' alt='follow us' style={{ width: '130px', height: '130px', paddingTop: '5px' }} />
            </a>
          </div>
          <div className='flex mt-5 flex-wrap items-center justify-center text-center'>
            <Checkbox checked={isChecked} onChange={onChange} className="custom-checkbox">
              <p className='text-lg font-medium pt-5' style={{ color: 'white' }}>Do not show this message again</p>
            </Checkbox>
          </div>
        </div>
      </Modal>
      <header className=' ml-auto grid w-full md:flex flex-wrap md:justify-between md:content-center mt-6'>
        <h1 className='fontSharpGrotesk22Medium text-2xl order-2 sm:text-3xl md:text-2xl lg:text-2xl sm:w-6/12 h-16 pt-4 ml-6 xl2_1:text-[2rem] md:order-1 mb-4 lg:mb-2 block md:flex'>
          <span>Hey, {firstName}!</span>
          <p className='xl:ml-1'>How it&apos;s going:</p>
        </h1>
        <div className=' w-70 grid grid-cols-12 sm:w-auto sm:order-1 sm:grid sm:grid-cols-12  sm:h-15 md:flex md:flex-wrap md:gap-4 md:h-1 md:mb-10 lg:mt-3 lg:mr-3'>
          {/* TODO:uncomment search when it works */}
          {/* <div className='col-start-11 col-span-2 row-start-2 sm:row-start-2 sm:col-start-11 sm:w-12 sm:justify-self-end bg-white py-4 px-3 rounded-3xl md:bg-white md:py-4 md:px-5 md:rounded-3xl md:w-[4rem] '>
            <img
              className='hidden md:block'
              src='./images/dashboard/search.png'
              alt='search icon'
              width='21'
              height='22'
            />
            <div className='md:hidden'>
              <SearchIcon />
            </div>
          </div> */}
          <div className='col-start-1 col-span-9 row-start-2 bg-white py-4 pl-3 rounded-3xl sm:col-span-6 sm:row-start-2 sm:bg-white sm:py-4 sm:px-5 sm:rounded-3xl md:bg-white md:py-4 md:px-5 md:rounded-3xl md:flex items-center'>
            <img
              className='mr-0 hidden md:block'
              src='./images/dashboard/date.png'
              alt='date icon'
              width='21'
              height='23'
            />
            <div className='flex items-center'>
              <div className='md:hidden'>
                <DateRangeIcon />
              </div>
              <span className='ml-2 items-center fontSharpGrotesk22Medium sm:mx-5 sm:text-slate-700 md:text-sky-500 text-xs md:mx-5 '>
                Today is {moment(new Date()).format('MMMM Do YYYY')}
              </span>
            </div>
          </div>
          {/* TODO:uncomment notification when it works */}
          {/* <Popover
            placement='bottom'
            trigger='click'
            content={buildNotification}>
            <div
              className=' flex ml-2 col-start-13 row-start-2 justify-self-end bg-white py-3 px-2 rounded-xl  w-10 md:bg-white md:p-4 md:rounded-xl md:w-14 '
              data-testid='notificationIconButton'>
              <img
                className='hidden md:block'
                src='./images/dashboard/notification.png'
                alt='notification icon'
                width='17'
                height='22'
              />
              <div className='md:hidden'>
                <NotificationsNoneIcon />
              </div>
            </div>
          </Popover> */}
        </div>
      </header>
      <div className='justify-center items-center w-full my-5 ml-auto mr-auto'>
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: `
                    <dotlottie-player 
                      src="images/dashboard/AMAZIN_OCT.json"
                      background="transparent"
                      speed="1"
                      style="width: 100%;"
                      loop
                      autoplay
                    ></dotlottie-player>
                  `
                }}
              />
            </div>
      <div className='md:grid md:grid-cols-2 xl2:grid-cols-4 md:gap-10 xl:gap-3 xl3:gap-6 2xl:flex'>
        {dataCard?.map((item, index) => {
          return (
            <SmallCard
              key={index}
              data={item}
              value={dataResponse?.data[item?.field]}
            />
          );
        })}
      </div>
      <div className='md:grid md:grid-cols-2 gap-10 xl:flex xl:gap-0 mt-[1.4rem]'>
        <CardUpdates data={updateInfo?.data} />
        <CardContent
          styles={
            'hidden md:w-full xl:w-2/4 xl:ml-8 xl:h-5/6 rounded-3xl p-3 pb-8 md:block '
          }>
          <p className='fontSharpGrotesk22Medium text-xl my-5 xl:ml-12'>
            What do you want to do?
          </p>
          <div className='flex flex-wrap justify-center w-full'>
            {dataWideCard.map((item, index) => {
              return <WideCard key={index} data={item} />;
            })}
          </div>
        </CardContent>
      </div>
      <div className='hidden md:mt-10 md:block '>
        {/* <Table
          title={ListTitle}
          columns={dataTableFields}
          scroll={{ x: 1300 }}
          loading={isLoading}
          dataSource={responseDeals?.data}
          rowClassName={(data: { enabled: any; locked: any }): any =>
            (data.enabled || data.locked) && 'disabled-row'
          }
        /> */}
        <Style.TableContainer>
          <List
            deals={responseDeals?.data}
            loading={isLoading}
            isDashboard={true}
            totalItems={responseDeals?.meta?.totals}
            current={current}
            setSizeItemPage={setSizeItemPage}
            sizeItemsPage={sizeItemsPage}
            setCurrent={setCurrent}
          />
        </Style.TableContainer>
      </div>
    </div>
  );
}

export default DashboardModule;