/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchGetDeal = async (params: any) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/active-deals/${params}`,
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    },
  ).then((res) => res.json());
  return res;
};

export default fetchGetDeal;
