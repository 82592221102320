import { useState } from 'react';
import useAddAchAccount from '../../../api/services/myAccount/addAchAccount';
import useGetVerificationCode from '../../../api/services/myAccount/getVerificationCode';
import useAddPayeeName from '../../../api/services/myAccount/addPayeeName';
import useChangePassword from '../../../api/services/myAccount/changePassword';
import { IAlertType } from '../../../interfaces/activeDeal';
import { useQueryClient } from 'react-query';
import useUpdateAchAccount from '../../../api/services/myAccount/updateAchAccount';
import useUpdatePayeeName from '../../../api/services/myAccount/updatePayeeName';

const useFormTemplateFunctions = () => {
  const queryClient = useQueryClient();
  const [verification_id, setVerification_id] = useState('');
  const [sameValueValidation, setSameValueValidation] = useState({
    message: '',
    show: false,
  });
  const [alert, setAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [viewModal, setViewModal] = useState({
    active: false,
    name: '',
  });

  const { achAccountMutate } = useAddAchAccount();
  const { getVerificationCodeMutate } = useGetVerificationCode();
  const { payeeNameMutate } = useAddPayeeName();
  const { mutate } = useChangePassword();
  const { achAccountUpdateMutate } = useUpdateAchAccount();
  const { payeeNameMutateFnc } = useUpdatePayeeName();

  const setErrorAlert = (error: any) => {
    setAlert({
      show: true,
      title: 'Error on request',
      message: error.toString(),
      type: 'error',
    });
  };

  const setExceptionAlert = (data: any) => {
    setAlert({
      show: true,
      title: 'Error',
      message: data?.error?.message || data?.error,
      type: 'warning',
    });
  };

  const activeDrawerView = (data: any, modalName: string) => {
    setTimeout(() => {
      !data?.error
        ? setViewModal({ active: true, name: modalName })
        : setViewModal({ active: false, name: '' });
    }, 2000);
  };

  const handleAddAchAccount = (formData: any, reset: any) => {
    const { account_name, account_number, routing_number, verification_code } =
      formData;

    const newFormData = {
      account_name,
      account_number,
      routing_number,
      verification_code,
      verification_id,
    };

    achAccountMutate(newFormData, {
      onSuccess: (data: any) => {
        setAlert({
          show: true,
          title: data?.error
            ? 'Error'
            : 'The ACH Account has been created successfully.',
          message: data?.error || '',
          type: data?.error ? 'warning' : 'success',
        });

        activeDrawerView(data, 'allAchAccounts');

        if (data.status) {
          reset();
        }
      },
      onError: (error: any) => {
        setErrorAlert(error);
      },
    });
  };

  const handleUpdateAchACcount = (formData: any, id: any, handleClose: any) => {
    const { account_name, account_number, routing_number, verification_code } =
      formData;

    let formattedData = {
      data: {
        account_name,
        account_number,
        routing_number,
        verification_code,
        verification_id,
      },
      code: id,
    };

    achAccountUpdateMutate(formattedData, {
      onSuccess: (data: any) => {
        if (data?.status) {
          setAlert({
            show: true,
            title: 'The ACH Account has been udpated successfully.',
            message: '',
            type: 'success',
          });

          setTimeout(() => {
            handleClose();
          }, 2000);
          queryClient.invalidateQueries('GET_ACH_ACCOUNTS');
        } else {
          setExceptionAlert(data);
        }
      },
      onError: (error: any) => {
        setErrorAlert(error);
      },
    });
  };

  const handleAddPayeeName = (formData: any, reset: any) => {
    payeeNameMutate(
      { ...formData, set_default: 0 },
      {
        onSuccess: (data: any) => {
          setAlert({
            show: true,
            title: data?.error
              ? 'Error'
              : 'The payee name has been created successfully.',
            message: data?.error || '',
            type: data?.error ? 'warning' : 'success',
          });

          activeDrawerView(data, 'allPayeeNames');

          if (data.status) {
            reset({
              name: '',
            });
          }
        },
        onError: (error: any) => {
          setErrorAlert(error);
        },
      },
    );
  };

  const handleChangePassword = (formData: any, getValues: any, reset: any) => {
    const password = getValues('password');
    const confirm_password = getValues('confirm_password');
    if (password != confirm_password) {
      setSameValueValidation({
        show: true,
        message: 'Password and confirm password must be the same',
      });
    } else {
      setSameValueValidation({
        show: false,
        message: '',
      });

      mutate(formData, {
        onSuccess: (data: any) => {
          setAlert({
            show: true,
            title: data?.error
              ? 'Error'
              : 'The password has been modified successfully',
            message: data?.error || '',
            type: data?.error ? 'warning' : 'success',
          });
          if (data.status) {
            reset({
              current_password: '',
              password: '',
              confirm_password: '',
            });
          }
        },
        onError: (error: any) => {
          setErrorAlert(error);
        },
      });
    }
  };

  const handleGetVerificationCode = (
    setIsAvailableToSendCode: any,
    updateInfo: any,
  ) => {
    setIsAvailableToSendCode(true);
    getVerificationCodeMutate(
      { action: updateInfo ? 'edit_ach_account' : 'add_ach_account' },
      {
        onSuccess: (data: any) => {
          setAlert({
            show: true,
            title: data?.error
              ? 'Error'
              : 'The code has been sent to the phone number that you have registered',
            message: data?.error || '',
            type: data?.error ? 'warning' : 'success',
          });

          if (data.status) {
            setVerification_id(data?.data?.verification_id);
            setTimeout(() => {
              setIsAvailableToSendCode(false);
            }, 30000);
          }
        },
        onError: (error: any) => {
          setIsAvailableToSendCode(false);
          setErrorAlert(error);
        },
      },
    );
  };

  const handleUpdatePayeeName = (formData: any, id: any, handleClose: any) => {
    const { name } = formData;

    let formattedData = {
      data: {
        name,
      },
      code: id,
    };

    payeeNameMutateFnc(formattedData, {
      onSuccess: (data: any) => {
        if (data?.status) {
          setAlert({
            show: true,
            title: 'The payee name has been Updated sussesfully.',
            message: '',
            type: 'success',
          });

          setTimeout(() => {
            handleClose();
          }, 2000);
          queryClient.invalidateQueries('GET_PAYEE_NAMES');
        } else {
          setExceptionAlert(data);
        }
      },
      onError: (error: any) => {
        setErrorAlert(error);
      },
    });
  };

  return {
    alert,
    handleAddAchAccount,
    handleAddPayeeName,
    handleChangePassword,
    handleGetVerificationCode,
    handleUpdateAchACcount,
    handleUpdatePayeeName,
    sameValueValidation,
    setAlert,
    viewModal,
    setViewModal,
  };
};

export default useFormTemplateFunctions;
