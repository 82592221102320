import React from 'react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Link } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import usePriceFormat from '../../../hooks/usePriceFormat';
import * as Style from '../style';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Tag } from 'antd';
import { validateExpires } from '../../../helpers/index';

const CardList = (dealsCard: any) => {
  const { addItem } = useCart();
  const addnew = (item: any) => {
    addItem(item);
  };

  return (
    <>
      <div className='bg-white rounded-3xl'>
        <Style.ViewCartDeals className=''>
          {dealsCard?.dealsCard.map((deals: any, index: number) => (
            <div
              className='flex justify-between flex-col sm:m-auto sm:w-full sm:mx-8 md:w-72 md:m-0 xl:w-[21rem] xl:mt-6'
              key={index}
              style={{
                borderRadius: 10,
                border: '2px solid #d0d0d0',
                padding: '1rem',
              }}>
              <div className='rounded-3xl p-2'>
                <img
                  src={deals.item.image}
                  alt='img'
                  className='container__grid__image'
                />
              </div>
              <p className='text-gray-400 font-semibold'>{deals.item.marc}</p>
              <div className='text-base fontGTAmericanR font-semibold'>
                <p className='truncate-text-3-lines'>
                  {deals.item.description}
                </p>
                <p className='text-gray-400 font-medium mt-3 text-sm'>
                  Colors:{' '}
                  {deals?.quantityUser?.map((item: any) => item.color + ' ')}
                </p>
              </div>
              <div className='flex gap-3 mb-4 justify-between'>
                <div className='w-[55%]'>
                  <div className='flex justify-between'>
                    <p className='text-gray-400 text-xs mt-1'>Price</p>
                    <span className='text-black text-base font-bold fontGTAmericanR'>
                      {usePriceFormat(deals.weReimburse || 0)}
                    </span>
                  </div>
                  <div className='flex justify-between mt-1'>
                    <p className='text-gray-400 text-xs'>Deal Expires</p>
                    <div>
                      <span className='text-orange-500 text-sm'>
                        {deals.dealExpires}
                      </span>
                      <div>
                        {validateExpires(deals?.days_to_expire) === 1 ? (
                          <Tag
                            style={{
                              borderRadius: 10,
                              fontSize: '8px',
                              width: '4rem',
                              paddingLeft: 0,
                              paddingRight: 0,
                              paddingTop: 0,
                              textAlign: 'center',
                              margin: 0,
                            }}
                            color='#cd201f'>
                            Expired!
                          </Tag>
                        ) : validateExpires(deals?.days_to_expire) === 0 ? (
                          <Tag
                            style={{
                              borderRadius: 10,
                              fontSize: '8px',
                              width: '4rem',
                              paddingLeft: 0,
                              paddingRight: 0,
                              paddingTop: 0,
                              textAlign: 'center',
                              margin: 0,
                            }}
                            color='#F86238'>
                            Expires Soon!
                          </Tag>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between mt-2'>
                    <p className='text-gray-400 text-xs'>We Reimburse</p>
                    <div>
                      <span className='text-black text-sm'>
                        {/* {usePriceFormat(deals.price || 0)} */}
                        <p
                          className={
                            Number(deals?.price) >
                            Number(deals?.variations[0]?.list_price)
                              ? 'flex flex-col font-bold fontGTAmericanR'
                              : 'flex flex-col font-bold fontGTAmericanR'
                          }>
                          {usePriceFormat(Number(deals?.price))}
                          {Number(deals?.price) >
                            Number(deals?.variations[0]?.list_price) && (
                            <Tag
                              style={{
                                borderRadius: 10,
                                fontSize: '7px',
                                width: '4rem',
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 0,
                                textAlign: 'center',
                                margin: 0,
                              }}
                              color='#81BFFD'>
                              {usePriceFormat(
                                Number(deals?.price) -
                                  Number(deals?.variations[0]?.list_price) || 0,
                              )}
                              <ArrowUpwardIcon fontSize='inherit' />
                              Price
                            </Tag>
                          )}

                          {Number(deals?.price) <
                            Number(deals?.variations[0]?.list_price) && (
                            <Tag
                              style={{
                                borderRadius: 10,
                                fontSize: '7px',
                                width: '4rem',
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 0,
                                textAlign: 'center',
                                margin: 0,
                              }}
                              color='#ff6339'>
                              {usePriceFormat(
                                Number(deals?.variations[0]?.list_price) -
                                  Number(deals?.price) || 0,
                              )}
                              <ArrowDownwardIcon fontSize='inherit' />
                              Price
                            </Tag>
                          )}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='text-gray-400 text-xs ml-2 flex items-end'>
                  <div className='mb-2'>
                    <p>Where to Shop</p>
                    <div className='flex flex-wrap gap-1'>
                      {deals?.variations?.map((variation: any) =>
                        variation?.stores?.map((store: any, index: number) => (
                          <a
                            key={index}
                            href={store.link}
                            target='_blank'
                            rel='noreferrer'
                            id={'variation-store-' + store.id}>
                            <img
                              src={
                                !store.icon.includes('no-icon.jpg')
                                  ? store.icon
                                  : 'images/no-image.png'
                              }
                              className='rounded-md w-8 h-8'
                              alt={store.name}
                            />
                          </a>
                        )),
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-end  flex-wrap gap-2 sm:justify-center  2xl:m-0 xl:mx-0 mt-5'>
                <button className='border-2 py-1 w-full sm:w-1/3 md:w-full xl:w-28 2xl:w-[7.2rem] border-black rounded '>
                  <Link to={`/active-deal/${deals?.id}`} state={deals}>
                    <RemoveRedEyeOutlinedIcon />
                    <span className='ml-2'>View Item</span>
                  </Link>
                </button>
                <button
                  onClick={() => {
                    addnew(deals);
                  }}
                  disabled={deals?.days_to_expire > 0 ? false : true}
                  className="border-2 rounded py-1 w-full sm:w-1/2 md:w-full 2xl:w-44 before:content-['Add_to_Deals_Cart'] hover:bg-blue-500 hover:text-white xl:w-44 border-blue-500 text-blue-500 customDisabledCartButton"
                  key={deals.key}>
                  <span className='float-left ml-1'>
                    <AddShoppingCartIcon />
                  </span>
                </button>
              </div>
            </div>
          ))}
        </Style.ViewCartDeals>
      </div>
    </>
  );
};
export default CardList;
