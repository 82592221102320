import { IData } from '../../../interfaces/activeDeal';

export interface IServiceTransform {
  data: IData;
  meta: {
    status: boolean;
  };
}

const getDealTransform = (data: any): IServiceTransform => {
  const dataTranformed = () => {
    let linkBestBuy = '';
    let linkAmazon = '';
    let linkWalmart = '';
    const colorDeal: any = [];
    const quantityUser: any = [];
    let alreadyCommittedStyleFlag = false;
    let realStores: any[] = [];
    const alreadyCommitted = data.data.deal.variations.reduce(
      (total: number, current: any) => total + parseInt(current.buyer_commit),
      0,
    );

    const commitLimit = data.data.deal.variations.reduce(
      (total: number, current: any) => total + parseInt(current.commit_limit),
      0,
    );

    if (alreadyCommitted === commitLimit) {
      alreadyCommittedStyleFlag = true;
    } else {
      alreadyCommittedStyleFlag = false;
    }

    data.data.deal?.variations?.map((variation: any) => {
      quantityUser.push({
        color: variation.name,
        amount: variation.buyer_commit,
        id: variation.id,
        fullyCommitted: variation.buyer_commit === variation.commit_limit,
        locked_by_time: variation.locked_by_time
      });

      colorDeal.push(variation.name);

      variation?.stores?.map((store: any) => {
        if (store.name === 'Best Buy') {
          linkBestBuy = store.link;
        } else if (store.name === 'Amazon') {
          linkAmazon = store.link;
        } else if (store.name === 'Walmart') {
          linkWalmart = store.link;
        }
        realStores.push(store);
      });
    });

    return {
      ...data,
      item: {
        image: data.data.deal.image,
        marc: data.data.deal.brand_name,
        description: data.data.deal.title,
        color: colorDeal,
      },
      shopNow: [
        '/images/walmart.jpg',
        '/images/bestbuy.jpg',
        '/images/amazon.png',
      ],
      realStores: realStores,
      stores: { linkBestBuy, linkAmazon, linkWalmart },
      price: data.data.deal.price,
      quantityUser: quantityUser,
      id: data.data.deal.id,
      weReimburse: data.data.deal.variations[0].list_price,
      dealExpires: data.data.deal.expiration_date,
      enabled: alreadyCommittedStyleFlag,
      variations: data.data.deal.variations,
      banner_text: data.data.deal.banner_text,
      locked: data.data.deal.locked,
      days_to_expire: data.data.deal.days_to_expire,
      is_fully_committed: data.data.deal.is_fully_committed,
    };
  };
  const metaTranform = {
    status: data?.status,
  };

  return { data: dataTranformed(), meta: metaTranform };
};

export default getDealTransform;
