import { IVendorsExternal } from '../../../interfaces/activeDeals/getSelectVendors';

export interface IServiceResponseTransform {
  data: IDatatransform;
}

interface IDatatransform {
  name: string;
  title: string;
  menuItem: any[];
}

const getSelectVendorsTransform = (
  data: IVendorsExternal[],
): IServiceResponseTransform => {
  const modifyData = data.map((vendor: any) => {
    return {
      labelName: vendor.name,
      value: vendor.name,
    };
  });

  const dataTranformed = {
    name: 'retailers',
    title: 'Retailers',
    menuItem: modifyData,
  };

  return { data: dataTranformed };
};

export default getSelectVendorsTransform;
