import React, { useEffect, useState } from 'react';
// import { GrEdit } from 'react-icons/gr';

import { useAddInsuranceGrid } from '../../../api/services/trackingNumbers/addInsuranceInGrid';
import { useQueryClient } from 'react-query';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
// import usePriceFormat from '../../../hooks/usePriceFormat';
import { Tooltip, Popover } from 'antd';
import getInfoCards from '../../../api/services/dashboard/getInfoCards';

const InsurancePolicy = ({ item }: any) => {
  const [newInsurancePolice, setNewInsurancePolicy] = useState<any>(item?.insurance?.value || '');
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const queryClient = useQueryClient();

  const { response } = getInfoCards();

  const { mutate } = useAddInsuranceGrid();

  const verifyDataInput = () => {
    if (Number(newInsurancePolice) === 0 || Number(newInsurancePolice) < 0) {
      setShowAlert({
        show: true,
        message: 'The value must be greater than $0',
        title: 'Error',
        type: 'error',
      });
      return;
    }

    // if (Number(newInsurancePolice) < 100) {
    //   setShowAlert({
    //     show: true,
    //     message: 'The value must be greater than $100',
    //     title: 'Error',
    //     type: 'error',
    //   });

    //   return;
    // }

    setOpenConfirmModal(true);
  };

  const [inputDisabled, setInputDisabled] = useState(false);

    useEffect(() => {
      setInputDisabled(false);
      if (response?.data?.insurance_enroll === 0) {
        setInputDisabled(true);
      }
    }, [response])


  const handleSendInsurance = () => {
    setOpenConfirmModal(false);

    mutate(
      {
        tracking: item?.tracking_number,
        data: { insurance_value: newInsurancePolice },
      },
      {
        onSuccess: (data) => {
          setNewInsurancePolicy('');
          if (data.error) {
            setShowAlert({
              show: true,
              message: data.error,
              title: 'Error',
              type: 'error',
            });
          } else {
            setShowAlert({
              show: true,
              message: 'Insurance Created',
              title: 'Success',
              type: 'success',
            });
          }
          queryClient.invalidateQueries('GET_TRACKING_NUMBERS_DATA');
        },
        onError: (error: any) => {
          console.log(error);
          // setShowAlert({
          //   show: true,
          //   message: error,
          //   title: 'Error',
          //   type: 'error',
          // });
        },
      },
    );
  };

  // const verifyEditInsurance = () => {
  //   let insuranceStatus = false;

  //   if (item.insurance.added_at) {
  //     const fechaActual: Date = new Date();

  //     const fechaAPINY: Date = new Date(item?.insurance.added_at);

  //     const fechaActualNY: string = fechaActual.toLocaleString('en-US', {
  //       timeZone: 'America/New_York',
  //     });

  //     const diferenciaMinutos: number =
  //       (new Date(fechaActualNY).getTime() - fechaAPINY.getTime()) /
  //       (1000 * 60);

  //     if (diferenciaMinutos >= 5) {
  //       insuranceStatus = false;
  //     } else {
  //       insuranceStatus = true;
  //     }
  //   } else {
  //     insuranceStatus = true;
  //   }

  //   return insuranceStatus;
  // };

  // const VerifyTimeCreateTrackings = () => {
  //   const fechaActual: Date = new Date();

  //   const fechaString: string = item?.date_added;
  //   const [datePart, timePart] = fechaString.split(' ');
  //   const [month, day, year] = datePart.split('/');
  //   const [hour, minute] = timePart.replace(/[^\d:]/g, '').split(':');

  //   const fechaObjeto: Date = new Date(
  //     Number(year),
  //     Number(month) - 1,
  //     Number(day),
  //     Number(hour) + (timePart.includes('pm') ? 12 : 0),
  //     Number(minute),
  //   );

  //   const diferenciaTiempo: number =
  //     fechaActual.getTime() - fechaObjeto.getTime();

  //   const diferenciaHoras: number = diferenciaTiempo / (1000 * 60 * 60);

  //   return diferenciaHoras;
  // };

  const verifyStatusInsuranceInput = () => {
   
      return (
        <Popover
          visible={openConfirmModal}
          placement='bottom'
          trigger='click'
          content={
            <div className='flex flex-col justify-center items-center px-16 py-5'>
              <p className='font-semibold'>
                Set package value to ${newInsurancePolice}
              </p>
              <p>Confirm coverage</p>
              <div className='flex gap-3 mt-2 justify-center'>
                <h3
                  className='text-[#158DFE] cursor-pointer border border-[#158DFE] px-4 py-1 rounded-2xl'
                  onClick={() => setOpenConfirmModal(false)}>
                  Cancel
                </h3>
                <h3
                  className='text-white bg-[#158DFE] cursor-pointer border border-[#158DFE] px-4 py-1 rounded-3xl'
                  onClick={handleSendInsurance}>
                  Yes
                </h3>
              </div>
            </div>
          }>
          <input
            disabled={inputDisabled}
            className='text-center bg-[#E6F3FE] py-2 border-none w-3/4 min-w-[90px]'
            type='number'
            placeholder={
              item?.insurance.value > 0 ? item?.insurance.value : 'Enter value'
            }
            value={newInsurancePolice}
            onChange={(e) => setNewInsurancePolicy(e.target.value)}
            onBlur={verifyDataInput}
          />
        </Popover>
      );
  };

  const verifyStatusInsuranceIcon = () => {
    const [imageStatus, setImageStatus] = useState(
      './images/trackingNumbers/icons-grid-2/Property-error.png',
    );

    useEffect(() => {
      if (item?.insurance.status === '') {
        return setImageStatus(
          './images/trackingNumbers/icons-grid-2/Property-no-covered.png',
        );
      }
      if (
        item?.insurance.status === 'Not valid tracking number' ||
        item?.insurance.status === 'Covegare Error'
      ) {
        return setImageStatus(
          './images/trackingNumbers/icons-grid-2/Property-error.png',
        );
      }
      if (
        item?.insurance.status === 'Pending to Validate Tracking' ||
        item?.insurance.status === 'Creating Coverage' ||
        item?.insurance.status === 'Validating Tracking Number'
      ) {
        return setImageStatus(
          './images/trackingNumbers/icons-grid-2/Property-pending.png',
        );
      }
      if (item?.insurance.status === 'Covered') {
        return setImageStatus(
          './images/trackingNumbers/icons-grid-2/Property-success.png',
        );
      }
    }, [item.insurance.status]);

    return (
      <div className='flex justify-center'>
        <Tooltip
          placement='top'
          title={
            <p className='text-black mb-0 font-semibold px-4'>
              {item?.insurance.status || '# Not covered'}
            </p>
          }
          color='white'
          overlayStyle={{ borderRadius: '40px' }}>
          <img src={imageStatus} alt='' width={29} />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className='flex items-center align-middle justify-between'>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}

      {verifyStatusInsuranceInput()}
      {verifyStatusInsuranceIcon()}
    </div>
  );
};

export default InsurancePolicy;
