import { IBrandsExternal } from '../../../interfaces/activeDeals/getSelectBrands';

export interface IServiceResponseTransform {
  data: IDatatransform;
}

interface IDatatransform {
  name: string;
  title: string;
  menuItem: any[];
}

const getSelectBrandsTransform = (
  data: IBrandsExternal[],
): IServiceResponseTransform => {
  const modifyData = data.map((brand: any) => {
    return {
      labelName: brand.name,
      value: brand.name,
    };
  });

  const dataTranformed = {
    name: 'brands',
    title: 'Brands',
    menuItem: modifyData,
  };

  return { data: dataTranformed };
};

export default getSelectBrandsTransform;
