import React, { useEffect, useRef, useState } from 'react';
import * as Styles from './style';
import { styled } from '@mui/material/styles';
import { themeCustom } from '../../theme';
import { Link } from 'react-router-dom';
import { handleGeneralCopyClick } from '../../helpers/index';
import { IAlertType } from '../../interfaces/activeDeal';
import {
  addACHAccountForm,
  addPayeeNameForm,
  changePasswordForm,
  links,
  paymentMethods,
} from './constants';
import { ILinkTabProps } from '../../interfaces/userProfile';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputBase,
  InputLabel,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import BuyingGroupIDCard from './components/BuyingGroupIDCard';
import CardContent from '../../components/UI/cardContent';
import FormTemplate from './components/FormTemplate';
import SecurityCard from './components/SecurityCard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EastIcon from '@mui/icons-material/East';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useStatesGlobalStore from '../../hooks/useStatesGlobalStore';
import DetailAllAchAccounts from './components/DetailAllAchAccounts';
import GeneralAlert from '../../components/UI/generalAlert/GeneralAlert';
import { DrawerComponentUI } from '../../components/UI/sideSheetNew';
import DetailAllPayeeNames from './components/DetailAllPayeeNames';
import useGetAchAccountsAuthorizedActions from '../../api/services/myAccount/getAchAccountsAuthorizedActions';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';
import usePutUpdateInfoUser from '../../api/services/myAccount/putUpdateInfoUser';
import getMyInfo from '../../api/services/myAccount/getMyInfo';
import postUpdateInfoUserAddress from '../../api/services/myAccount/postUpdateInfoUserAddress';
import { useQueryClient } from 'react-query';
import { Popover } from 'antd';
import useUpdateChangeImage from '../../api/services/myAccount/putChangeImage';
import useDeleteImage from '../../api/services/myAccount/deleteImage';
import useUpdateEmailPreferences from '../../api/services/myAccount/updateEmailPreferences';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const LinkTab = (props: ILinkTabProps) => {
  return (
    <Tab
      component='a'
      {...props}
      sx={{
        textTransform: 'none',
        color: 'gray',
        fontSize: 13,
        fontWeight: 'bold',
        '&.Mui-selected': {
          color: 'black',
        },
      }}
    />
  );
};

const ImageInput = styled('input')({
  display: 'none',
});

const UserProfileModule = () => {
  const { colors } = themeCustom;
  const { buyerInfo, setBuyerInfo } = useStatesGlobalStore();
  const { response } = useGetAchAccountsAuthorizedActions();
  const [addPaymentMethod, setAddPaymentMethod] = useState(true);
  const [openPaymentMethodForm, setOpenPaymentMethodForm] = useState({
    formName: '',
  });
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [tabValue, setTabValue] = useState(0);
  const [viewModal, setViewModal] = useState({
    active: false,
    name: '',
  });

  const [base64Image, setBase64Image] = useState('');
  const reader = new FileReader();
  const [viewPopover, setViewPopover] = useState<boolean>(false);
  const [viewPopoverRemovedPhoto, setViewPopoverRemovedPhoto] =
    useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [fileSizeError, setFileSizeError] = useState(false);

  const [checkedEmails, setCheckedEmails] = useState({
    payment_request_approval_email: false,
    deals_email: false,
    monthly_reports_email: false,
    orders_shipment_statuses_email: false,
  });

  const fileInputRef = useRef<any>();

  const { addActions, editActions } = response || {};

  const { mutate: mutateUpdateInfoUser } = usePutUpdateInfoUser();

  const { mutate: mutateUpdateInfoUserAddress } = postUpdateInfoUserAddress();

  const { EmailPreferencesMutate } = useUpdateEmailPreferences();

  const { response: responseMyInfo } = getMyInfo();

  const { updateImage } = useUpdateChangeImage();

  const mutation = useDeleteImage();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (responseMyInfo?.data) {
      setCheckedEmails({
        payment_request_approval_email:
          responseMyInfo.data.email_preferences.payment_request_approval_email,
        deals_email: responseMyInfo.data.email_preferences.deals_email,
        monthly_reports_email:
          responseMyInfo.data.email_preferences.monthly_reports_email,
        orders_shipment_statuses_email:
          responseMyInfo.data.email_preferences.orders_shipment_statuses_email,
      });
    }
  }, [responseMyInfo]);

  const handleCopyClick = (text: string) => {
    handleGeneralCopyClick(
      text,
      {
        show: true,
        message: text,
        title: 'Text Copied!',
        type: 'info',
      },
      {
        show: true,
        title: 'Coping Error',
        type: 'error',
      },
      setShowAlert,
    );
  };

  const handleOpenPaymentMethodForm = (formName: string) => {
    setOpenPaymentMethodForm({ formName: formName });
    setAddPaymentMethod(false);
  };

  const handleChangeLinkTabs = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setTabValue(newValue);
  };

  const {
    firstName,
    lastName,
    emailAddress,
    mobilePhone,
    mobilePhoneTwo,
    mobilePhoneThree,
  } = buyerInfo;

  const [updateInfoUser, setUpdateInfoUser] = useState({
    firstName: firstName,
    lastName: lastName,
    phoneNumber: mobilePhone,
    phoneTwo: mobilePhoneTwo === null ? '' : mobilePhoneTwo,
    phoneThree: mobilePhoneThree === null ? '' : mobilePhoneThree,
    email: emailAddress,
  });

  const [updateInfoUserAddress, setUpdateInfoUserAddress] = useState({
    address_1: buyerInfo?.address?.address_line_1,
    address_2: buyerInfo?.address?.address_line_2,
    city: buyerInfo?.address?.city,
    country: buyerInfo?.address?.country,
    state: buyerInfo?.address?.state,
    zip_code: buyerInfo?.address?.zip_code,
  });

  useEffect(() => {
    setUpdateInfoUser({
      firstName: firstName,
      lastName: lastName,
      phoneNumber: mobilePhone,
      phoneTwo: mobilePhoneTwo === null ? '' : mobilePhoneTwo,
      phoneThree: mobilePhoneThree === null ? '' : mobilePhoneThree,
      email: emailAddress,
    });
  }, [buyerInfo]);

  useEffect(() => {
    setUpdateInfoUserAddress({
      address_1: buyerInfo?.address?.address_line_1,
      address_2: buyerInfo?.address?.address_line_2,
      city: buyerInfo?.address?.city,
      country: buyerInfo?.address?.country,
      state: buyerInfo?.address?.state,
      zip_code: buyerInfo?.address?.zip_code,
    });
  }, [buyerInfo]);

  const dataSendVerify: any = {
    first_name:
      firstName === updateInfoUser.firstName || updateInfoUser.firstName === ''
        ? null
        : updateInfoUser.firstName,
    last_name:
      lastName === updateInfoUser.lastName || updateInfoUser.lastName === ''
        ? null
        : updateInfoUser.lastName,
    phone_number:
      mobilePhone === updateInfoUser.phoneNumber ||
        updateInfoUser.phoneNumber === ''
        ? null
        : updateInfoUser.phoneNumber,
    phone_number_2:
      mobilePhoneTwo === updateInfoUser.phoneTwo ||
        updateInfoUser.phoneTwo === ''
        ? null
        : updateInfoUser.phoneTwo,
    phone_number_3:
      mobilePhoneThree === updateInfoUser.phoneThree ||
        updateInfoUser.phoneThree === ''
        ? null
        : updateInfoUser.phoneThree,
    email:
      emailAddress === updateInfoUser.email || updateInfoUser.email === ''
        ? null
        : updateInfoUser.email,
  };

  let dataSend: any = {};

  dataSend = Object.keys(dataSendVerify).reduce((acc: any, key: any) => {
    if (dataSendVerify[key] !== null) {
      acc[key] = dataSendVerify[key];
    }
    return acc;
  }, {});

  const dataSendVerifyAddress: any = {
    address_line_1:
      buyerInfo?.address?.address_line_1 === updateInfoUserAddress.address_1 ||
        updateInfoUserAddress.address_1 === ''
        ? null
        : updateInfoUserAddress.address_1,
    address_line_2:
      buyerInfo?.address?.address_line_2 === updateInfoUserAddress.address_2 ||
        updateInfoUserAddress.address_2 === ''
        ? null
        : updateInfoUserAddress.address_2,
    city:
      buyerInfo?.address?.city === updateInfoUserAddress.city ||
        updateInfoUserAddress.city === ''
        ? null
        : updateInfoUserAddress.city,
    country:
      buyerInfo?.address?.country === updateInfoUserAddress.country ||
        updateInfoUserAddress.country === ''
        ? null
        : updateInfoUserAddress.country,
    state:
      buyerInfo?.address?.state === updateInfoUserAddress.state ||
        updateInfoUserAddress.state === ''
        ? null
        : updateInfoUserAddress.state,
    zip_code:
      buyerInfo?.address?.zip_code === updateInfoUserAddress.zip_code ||
        updateInfoUserAddress.zip_code === ''
        ? null
        : updateInfoUserAddress.zip_code,
  };

  let dataSendAddress: any = {};

  dataSendAddress = Object.keys(dataSendVerifyAddress).reduce(
    (acc: any, key: any) => {
      if (dataSendVerifyAddress[key] !== null) {
        acc[key] = dataSendVerifyAddress[key];
      }
      return acc;
    },
    {},
  );

  const personalInformation = [
    {
      label: 'First Name',
      name: 'firstName',
      value: updateInfoUser.firstName,
      type: 'text',
      unlocked:
        buyerInfo.change_personal_info_request_status === 'Pending approval'
          ? false
          : true,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      value: updateInfoUser.lastName,
      type: 'text',
      unlocked:
        buyerInfo.change_personal_info_request_status === 'Pending approval'
          ? false
          : true,
    },
    {
      label: 'Email Address',
      name: 'email',
      value: updateInfoUser.email,
      type: 'text',
      unlocked:
        buyerInfo.change_personal_info_request_status === 'Pending approval'
          ? false
          : true,
    },
    {
      label: 'Main Mobile Phone',
      name: 'phoneNumber',
      value: updateInfoUser.phoneNumber,
      type: 'number',
      phone: true,
      unlocked:
        buyerInfo.change_personal_info_request_status === 'Pending approval'
          ? false
          : true,
    },
    {
      label: '#2 Mobile Phone',
      name: 'phoneTwo',
      value: updateInfoUser.phoneTwo,
      type: 'number',
      phone: true,
      unlocked: true,
    },
    {
      label: '#3 Mobile Phone',
      name: 'phoneThree',
      value: updateInfoUser.phoneThree,
      type: 'number',
      phone: true,
      unlocked: true,
    },
  ];

  const addressForm = [
    {
      label: 'Address 1',
      name: 'address_1',
      type: 'text',
      width: 650,
      widthLaptops: 400,
      widthMobile: 290,
      mr: 9,
      unlocked: true,
      value: updateInfoUserAddress.address_1,
    },
    {
      label: 'Address 2',
      name: 'address_2',
      type: 'text',
      width: 650,
      widthLaptops: 400,
      widthMobile: 290,
      mr: 9,
      unlocked: true,
      value: updateInfoUserAddress.address_2,
    },
    {
      label: 'City',
      name: 'city',
      type: 'text',
      width: 290,
      widthLaptops: 290,
      widthMobile: 290,
      mr: 9,
      unlocked: true,
      value: updateInfoUserAddress.city,
    },
    {
      autoCompleteIndicator: 'country',
      label: 'Country',
      name: 'country',
      type: 'text',
      width: 290,
      widthLaptops: 290,
      widthMobile: 290,
      mr: 9,
      unlocked: true,
      value: updateInfoUserAddress.country,
    },
    {
      label: 'State',
      name: 'state',
      type: 'text',
      width: 290,
      widthLaptops: 290,
      widthMobile: 290,
      mr: 9,
      unlocked: true,
      value: updateInfoUserAddress.state,
    },
    {
      label: 'Zip Code',
      name: 'zip_code',
      type: 'number',
      width: 290,
      widthLaptops: 290,
      widthMobile: 290,
      mr: 9,
      unlocked: true,
      value: updateInfoUserAddress.zip_code,
    },
  ];

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.view, alertTurnScreenMobile.view]);

  useEffect(() => {
    if (responseMyInfo) {
      setBuyerInfo({
        ...buyerInfo,
        firstName: responseMyInfo.data.first_name,
        lastName: responseMyInfo.data.last_name,
        emailAddress: responseMyInfo.data.email,
        mobilePhone: responseMyInfo.data.phone_number,
        mobilePhoneTwo: responseMyInfo.data.phone_number_2,
        mobilePhoneThree: responseMyInfo.data.phone_number_3,
        address: responseMyInfo.data.address,
        change_address_info_request_status:
          responseMyInfo.data.change_address_info_request_status,
        change_personal_info_request_status:
          responseMyInfo.data.change_personal_info_request_status,
        profile_image: responseMyInfo.data.profile_image,
      });
    }
  }, [responseMyInfo]);

  const changeInfoUser = (e: any, item: any) => {
    if (item.unlocked) {
      setUpdateInfoUser({
        ...updateInfoUser,
        [e.target.name]: e.target.value,
      });
    }
  };

  const changeInfoUserAddress = (e: any, item: any) => {
    if (item.unlocked) {
      setUpdateInfoUserAddress({
        ...updateInfoUserAddress,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdateInfoUser = () => {
    mutateUpdateInfoUser(dataSend, {
      onSuccess: (data: any) => {
        if (data?.status) {
          setShowAlert({
            show: true,
            title: 'data updated successfully',
            message: '',
            type: 'success',
          });

          queryClient.invalidateQueries('GET_MY_INFO');

          setTimeout(() => {
            setShowAlert({
              message: '',
              show: false,
              title: '',
              type: 'success',
            });
          }, 2000);
        } else {
          setShowAlert({
            show: true,
            title: data.error,
            message: '',
            type: 'error',
          });
        }
      },
      onError: (error: any) => {
        setShowAlert({
          show: true,
          title: error.message,
          message: '',
          type: 'error',
        });
      },
    });
  };

  const handleUpdateIfoUserAddress = () => {
    mutateUpdateInfoUserAddress(dataSendAddress, {
      onSuccess: (data: any) => {
        if (data?.status) {
          setShowAlert({
            show: true,
            title: 'data updated successfully',
            message: '',
            type: 'success',
          });

          queryClient.invalidateQueries('GET_MY_INFO');

          setTimeout(() => {
            setShowAlert({
              message: '',
              show: false,
              title: '',
              type: 'success',
            });
          }, 2000);
        } else {
          setShowAlert({
            show: true,
            title: data.error,
            message: '',
            type: 'error',
          });
        }
      },
      onError: (error: any) => {
        setShowAlert({
          show: true,
          title: error.message,
          message: '',
          type: 'error',
        });
      },
    });
  };

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2 MB en bytes

    if (file.size > maxSize) {
      setFileSizeError(true);
      event.target.value = '';
      return;
    }
    setFileSizeError(false);

    reader.onload = (e: any) => {
      const base64 = e.target.result;
      setBase64Image(base64);
    };

    reader.readAsDataURL(file);

    setPreviewImage(URL.createObjectURL(file));
  };

  const handleCancelNewPhoto = () => {
    setPreviewImage(null);
    setViewPopover(false);
    fileInputRef.current.value = null;
  };

  const handleCancelRemovePhoto = () => {
    setViewPopoverRemovedPhoto(false);
  };

  const handleUdapteImage = () => {
    updateImage(
      { profile_image: base64Image.toString() },
      {
        onSuccess: (data: any) => {
          if (data.status) {
            handleCancelNewPhoto();
            setShowAlert({
              show: true,
              title: data.data,
              message: '',
              type: 'success',
            });
          }
          queryClient.invalidateQueries('GET_MY_INFO');
        },
        onError: (error: any) => {
          console.log(error);
        },
      },
    );
  };

  const handleDeleteImage = () => {
    mutation.mutate();

    setViewPopoverRemovedPhoto(false);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setShowAlert({
        show: true,
        title: mutation.data.data
          ? 'The profile picture has been successfully deleted.'
          : mutation.data.error,
        message: '',
        type: mutation.data.data ? 'success' : 'warning',
      });
      queryClient.invalidateQueries('GET_MY_INFO');
    }
  }, [mutation.isSuccess]);

  useEffect(() => {
    if (fileSizeError) {
      setShowAlert({
        show: true,
        title: 'The image cannot weigh more than 2mg',
        message: '',
        type: 'warning',
      });
    }
  }, [fileSizeError]);

  const handleUpdateEmailPreferences = () => {
    EmailPreferencesMutate(checkedEmails, {
      onSuccess: (data: any) => {
        if (data?.status) {
          setShowAlert({
            show: true,
            title: data.data,
            message: '',
            type: 'success',
          });

          queryClient.invalidateQueries('GET_MY_INFO');

          setTimeout(() => {
            setShowAlert({
              message: '',
              show: false,
              title: '',
              type: 'success',
            });
          }, 2000);
        } else {
          setShowAlert({
            show: true,
            title: data.error,
            message: '',
            type: 'error',
          });
        }
      },
      onError: (error: any) => {
        setShowAlert({
          show: true,
          title: error.message,
          message: '',
          type: 'error',
        });
      },
    });
  };


  const handlePhoneChange = (value: any, item: any) => {
    if (item.unlocked) {
      setUpdateInfoUser({
        ...updateInfoUser,
        [item.name]: value,
      });
    }
  };

  return (
    <main className='mt-11'>
      {showAlert?.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
        />
      )}
      <header className='flex items-center' id='title'>
        <Avatar
          alt='Remy Sharp'
          src={
            buyerInfo.profile_image
              ? buyerInfo.profile_image
              : '/images/test-avatar.png'
          }
          sx={{ width: 56, height: 56 }}
        />

        <h1 className='ml-3 lg:ml-5 text-2xl lg:text-4xl fontSharpGrotesk22Medium text-black'>
          {firstName} {lastName}
        </h1>
        {/* <h1 className='ml-5 mb-0 text-3xl fontSharpGrotesk22Medium text-black'>
          {firstName} {lastName}
        </h1> */}
      </header>
      <div className='lg:flex mt-7 lg:space-x-16'>
        <CardContent
          styles={
            ' w-full lg:w-7/12 py-8 px-6 lg:p-12 pt-6 rounded-t-3xl lg:rounded-t-0 lg:rounded-3xl'
          }
          id={'userOperations'}>
          <div className='hidden lg:flex justify-center'>
            <Tabs
              sx={{
                borderBottom: '2px solid #b2b2b2',
                '& .MuiTabs-indicator': {
                  backgroundColor: '#2f95fc',
                },
              }}
              id='headerLinks'
              value={tabValue}
              onChange={handleChangeLinkTabs}
              aria-label='header user operations tabs'>
              {links.map((item: any, index: any) => (
                <LinkTab
                  key={index}
                  label={item.label}
                  href={`#${item.link}`}
                />
              ))}
            </Tabs>
          </div>
          <section id='myInfo' className='lg:ml-6 mt-10'>
            <h2 className='fontSharpGrotesk22Medium text-lg'>My Info</h2>
            {buyerInfo.change_personal_info_request_status ===
              'Pending approval' ? (
              <Alert severity='warning'>Waiting for approval.</Alert>
            ) : (
              <p className=' text-gray-400 font-semibold text-sm'>
                To edit personal information,
                <Link className='underline ml-1 text-gray-400' to='#!'>
                  send us a request
                </Link>
              </p>
            )}
            <div className='lg:flex items-center my-10' id='yourPhoto'>
              <div className='flex items-center'>
                <p className=' text-gray-400 font-semibold text-sm'>
                  Your photo
                </p>

                <Avatar
                  alt='Avatar image'
                  src={
                    buyerInfo.profile_image
                      ? buyerInfo.profile_image
                      : '/images/test-avatar.png'
                  }
                  sx={{ marginLeft: 5, width: 56, height: 56 }}
                />
              </div>
              <div className='flex'>
                <Popover
                  visible={viewPopover}
                  placement='bottom'
                  trigger='click'
                  content={
                    <div className='w-60 h-60 flex flex-col items-center justify-end'>
                      {previewImage ? (
                        <Avatar
                          alt='Travis Howard'
                          src={previewImage}
                          sx={{ width: 100, height: 100 }}
                          className='mb-10'
                        />
                      ) : (
                        <Avatar
                          alt='Avatar image'
                          src='/images/test-avatar.png'
                          sx={{ width: 100, height: 100 }}
                          className='mb-10'
                        />
                      )}
                      <label htmlFor='avatarPhotoTest'>
                        <ImageInput
                          accept='.png, .jpg, .jpeg'
                          id='avatarPhotoTest'
                          // multiple
                          type='file'
                          ref={fileInputRef}
                          onChange={handleImageUpload}
                        />
                        <Button
                          component='span'
                          onClick={(e: any) => e.stopPropagation()}
                          className='cursor-pointer'
                          sx={{
                            color: colors.grayLabels,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: 13,
                            cursor: 'pointer',
                          }}>
                          Upload photo
                        </Button>
                      </label>
                      <div className='flex gap-3 uppercase mt-4'>
                        <h3
                          className='text-[#6fb8ff] font-bold cursor-pointer'
                          onClick={handleUdapteImage}>
                          Save
                        </h3>
                        <h3
                          className='text-[#ff6f6fd4] font-bold cursor-pointer'
                          onClick={handleCancelNewPhoto}>
                          Cancel
                        </h3>
                      </div>
                    </div>
                  }>
                  <Button
                    component='span'
                    onClick={() => setViewPopover(!viewPopover)}
                    sx={{
                      color: colors.grayLabels,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: 13,
                    }}>
                    Upload new photo
                  </Button>
                </Popover>

                <Popover
                  visible={viewPopoverRemovedPhoto}
                  placement='bottom'
                  trigger='click'
                  content={
                    <div className='flex flex-col items-center'>
                      <h3>Are you sure you want to delete the photo?</h3>
                      <div className='flex gap-3 uppercase mt-4'>
                        <h3
                          className='text-[#6fb8ff] font-bold cursor-pointer'
                          onClick={handleDeleteImage}>
                          accept
                        </h3>
                        <h3
                          className='text-[#ff6f6fd4] font-bold cursor-pointer'
                          onClick={handleCancelRemovePhoto}>
                          Cancel
                        </h3>
                      </div>
                    </div>
                  }>
                  <div className='underline text-gray-400'>
                    <Button
                      onClick={() =>
                        setViewPopoverRemovedPhoto(!viewPopoverRemovedPhoto)
                      }
                      sx={{
                        color: colors.grayLabels,
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontSize: 13,
                      }}>
                      Remove photo
                    </Button>
                  </div>
                </Popover>

                {/* <label
                  className='lg:ml-6 underline text-gray-400'
                  htmlFor='avatarPhoto'>
                  <ImageInput
                    accept='image/*'
                    id='avatarPhoto'
                    // multiple
                    type='file'
                    onChange={handleImageUpload}
                  />
                  <Button
                    disabled
                    component='span'
                    onClick={(e: any) => e.stopPropagation()}
                    sx={{
                      color: colors.grayLabels,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: 13,
                    }}>
                    Upload new photo
                  </Button>
                </label> */}
                {/* <div className='underline text-gray-400'>
                  <Button
                    disabled
                    onClick={() => {}}
                    sx={{
                      color: colors.grayLabels,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: 13,
                    }}>
                    Remove photo
                  </Button>
                </div> */}
              </div>
            </div>
            <div className='flex flex-wrap' id='personalInformation'>
              {personalInformation.map((item: any, index: any) => (
                <Stack key={index} sx={{ mr: 9, mb: 4 }}>
                  <InputLabel
                    shrink
                    htmlFor={item.name}
                    sx={{
                      color: colors.grayLabels,
                      fontWeight: 'bold',
                      fontSize: 15,
                    }}>
                    {item.label}
                  </InputLabel>
                  <Box
                    sx={{
                      p: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      width: 280,
                      height: 50,
                      borderRadius: 0.6,
                      backgroundColor: colors.grayInputs,
                      border: '1px solid #dedede',
                    }}>
                    {/* {item.phone && (
                      <p className='p-0 mb-[0.20rem] font-bold text-base'>+1</p>
                    )} */}
                    {item.type === 'text' ?
                      <InputBase
                        id={item.name}
                        name={item.name}
                        type={item.type}
                        sx={{
                          flex: 1,
                          p: 1,
                          fontSize: 15,
                          fontWeight: 'bold',
                          input: {
                            '&::placeholder': {
                              '-webkit-text-fill-color': `black !important`,
                              fontWeight: 'bold',
                            },
                          },
                        }}
                        value={item.value}
                        onChange={(e) => {
                          changeInfoUser(e, item);
                        }}
                      />
                      :
                      <PhoneInput
                        disabled={!item.unlocked}
                        defaultCountry='US'
                        id={item.name}
                        name={item.name}
                        className='h-auto p-[8px]'
                        placeholder="Enter phone number"
                        value={item.value}
                        onChange={(value) => handlePhoneChange(value, item)} />}
                    {!item.unlocked && (
                      <LockOutlinedIcon
                        sx={{
                          height: 20,
                          mr: 2,
                          width: 20,
                          color: '#b2b2b2',
                        }}
                      />
                    )}
                  </Box>
                </Stack>
              ))}
            </div>

            <Button
              onClick={handleUpdateInfoUser}
              variant='contained'
              disabled={Object.keys(dataSend).length === 0}>
              Update
            </Button>
          </section>
          <Styles.FiltersHrStyle />
          <section id='myAddress' className='lg:ml-6'>
            <h2 className='fontSharpGrotesk22Medium text-lg font-bold'>
              My Address
            </h2>
            {buyerInfo.change_address_info_request_status ===
              'Pending approval' && (
                <Alert severity='warning'>Waiting for approval.</Alert>
              )}
            <p className=' text-gray-400 font-semibold text-sm'>
              We need this for when you send you stuff.
            </p>
            {/* <FormTemplate
              formFields={addressForm}
              extraFormContainerStyles1={'w-full'}
              styles={'flex flex-wrap mt-16'}
              handleChangeInfoAddress={changeInfoUserAddress}
              submitConfig={[
                {
                  ml: '',
                  labelName: 'Save Changes',
                  bgColor: 'white',
                  disabled: false,
                },
              ]}
            /> */}

            <div className='flex flex-wrap' id='personalInformation'>
              {addressForm.map((item: any, index: any) => {
                item.unlocked =
                  buyerInfo.change_address_info_request_status ===
                    'Pending approval'
                    ? false
                    : true;

                return (
                  <Stack key={index} sx={{ mr: 9, mb: 4 }}>
                    <InputLabel
                      shrink
                      htmlFor={item.name}
                      sx={{
                        color: colors.grayLabels,
                        fontWeight: 'bold',
                        fontSize: 15,
                      }}>
                      {item.label}
                    </InputLabel>
                    <Box
                      sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width:
                          (window.innerWidth < 1000 && item.widthMobile) ||
                          (window.innerWidth < 1400 && item.widthLaptops) ||
                          (window.innerWidth > 1400 && item.width),
                        height: 50,
                        borderRadius: 0.6,
                        backgroundColor: colors.grayInputs,
                        border: '1px solid #dedede',
                      }}>
                      <InputBase
                        id={item.name}
                        name={item.name}
                        sx={{
                          flex: 1,
                          p: 1,
                          fontSize: 15,
                          fontWeight: 'bold',
                          input: {
                            '&::placeholder': {
                              '-webkit-text-fill-color': `black !important`,
                              fontWeight: 'bold',
                            },
                          },
                        }}
                        value={item.value}
                        onChange={(e) => {
                          changeInfoUserAddress(e, item);
                        }}
                      />
                      {!item.unlocked && (
                        <LockOutlinedIcon
                          sx={{
                            height: 20,
                            mr: 2,
                            width: 20,
                            color: '#b2b2b2',
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                );
              })}
            </div>

            <Button
              // disabled={item.disabled}
              // data-testid={item.dataTestID}
              // key={index}
              disabled={
                Object.keys(dataSendAddress).length === 0 ||
                  buyerInfo.change_address_info_request_status ===
                  'Pending approval'
                  ? true
                  : false
              }
              type='submit'
              onClick={handleUpdateIfoUserAddress}
              sx={{
                textDecoration: 'underline',
                color: colors.blueLabels,
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: 13,
                // ml: item.ml,
                '&:hover': {
                  textDecoration: 'underline',
                  boxShadow: 'none',
                  backgroundColor: 'white',
                },
              }}>
              Save Changes
            </Button>
          </section>
          <Styles.FiltersHrStyle />
          <section id='payments' className='lg:ml-6'>
            <h2 className='fontSharpGrotesk22Medium text-lg'>Payments</h2>
            <p className=' text-gray-400 font-semibold text-sm'>
              Create new ways to get paid.
            </p>
            {openPaymentMethodForm.formName === '' ? (
              <></>
            ) : (
              <Button
                onClick={() => {
                  setAddPaymentMethod(true);
                  setOpenPaymentMethodForm({ formName: '' });
                }}
                sx={{
                  textDecoration: 'underline',
                  color: colors.blueLabels,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  fontSize: 13,
                  mb: 2,
                  '&:hover': {
                    textDecoration: 'underline',
                    boxShadow: 'none',
                    backgroundColor: 'white',
                  },
                }}>
                Add a payment method
              </Button>
            )}
            {addPaymentMethod && (
              <div className='rounded-3xl p-5 mb-10 border-[1px] border-slate-300'>
                {paymentMethods.map((item: any, index: any) => (
                  <div key={index}>
                    <Button
                      onClick={() => {
                        handleOpenPaymentMethodForm(item.name);
                      }}
                      data-testid={item.testID}
                      sx={{
                        color: '#2f95fc',
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1.5,
                        textTransform: 'none',
                        width: '100%',
                        '&:hover': {
                          backgroundColor: 'rgb(241 245 249)',
                          color: colors.blueLabels,
                        },
                      }}
                      endIcon={<EastIcon sx={{ height: 17 }} />}>
                      <div className='flex gap-3 items-center'>
                        {item.icon}
                        {item.label}
                      </div>
                    </Button>
                    {item.divider && (
                      <Divider
                        sx={{ bgcolor: '#dfdcdc', height: 1, borderRadius: 2 }}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            {openPaymentMethodForm.formName == 'addACHAccount' && (
              <div
                className='bg-slate-100 rounded-3xl p-5'
                data-testid='adhAccountOpenForm'>
                <div className='flex justify-between'>
                  <h3 className='ml-5 text-blue-500 text-xl font-semibold'>
                    Add an ACH Account
                  </h3>
                  <Button
                    onClick={() =>
                      setViewModal({ active: true, name: 'allAchAccounts' })
                    }
                    sx={{
                      textDecoration: 'underline',
                      color: 'rgb(59 130 246)',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: 15,
                      mb: 1,
                      '&:hover': {
                        backgroundColor: '#0069d9',
                        borderColor: '#0062cc',
                        boxShadow: 'none',
                        color: colors.white,
                      },
                    }}>
                    See all ACH Accounts
                  </Button>
                </div>
                <Divider
                  sx={{ bgcolor: '#1b8cfc', height: 2, borderRadius: 2 }}
                />
                <FormTemplate
                  extraFormContainerStyles1={'w-full'}
                  extraFormContainerStyles2={'lg:ml-4 w-full'}
                  extraFormContainerStyles3={'lg:flex lg:mt-8'}
                  formName={'addAchAccountForm'}
                  containerSubmitButton={[
                    {
                      ml: 2,
                      labelName: 'Submit',
                      bgColor: 'white',
                      containerStyles: 'lg:ml-4 w-full',
                    },
                  ]}
                  formFields={addACHAccountForm}
                  phoneNumberDetails={addActions?.details}
                  styles={'flex flex-wrap mt-10 justify-center'}
                  submitConfig={[
                    {
                      dataTestID: 'saveChangesAddAchAccount',
                      ml: 2,
                      labelName: 'Submit',
                      bgColor: 'rgb(241 245 249)',
                    },
                  ]}
                  extraACHForm={true}
                />
              </div>
            )}
            {openPaymentMethodForm.formName == 'addPayeeName' && (
              <div className='bg-slate-100 rounded-3xl p-5'>
                <div className='flex justify-between'>
                  <h3 className='ml-5 text-blue-500 text-xl font-semibold'>
                    Add Payee Name
                  </h3>
                  <Button
                    onClick={() =>
                      setViewModal({ active: true, name: 'allPayeeNames' })
                    }
                    sx={{
                      textDecoration: 'underline',
                      color: 'rgb(59 130 246)',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: 15,
                      mb: 1,
                      '&:hover': {
                        backgroundColor: '#0069d9',
                        borderColor: '#0062cc',
                        boxShadow: 'none',
                        color: colors.white,
                      },
                    }}>
                    See all Payee Names
                  </Button>
                </div>
                <Divider
                  sx={{ bgcolor: '#1b8cfc', height: 2, borderRadius: 2 }}
                />
                <FormTemplate
                  extraFormContainerStyles1={'w-full'}
                  extraFormContainerStyles2={''}
                  extraFormContainerStyles3={''}
                  formFields={addPayeeNameForm}
                  formName={'addPayeeNameForm'}
                  styles={'flex flex-wrap mt-10 justify-center'}
                  submitConfig={[
                    {
                      dataTestID: 'saveChangesAddPayeeName',
                      ml: 2,
                      labelName: 'Submit',
                      bgColor: 'rgb(241 245 249)',
                    },
                  ]}
                />
              </div>
            )}
            {viewModal.active && (
              <DrawerComponentUI
                anchor={'right'}
                viewRender={
                  viewModal.name === 'allAchAccounts' ? (
                    <DetailAllAchAccounts
                      phoneNumberDetails={editActions?.details}
                    />
                  ) : (
                    <DetailAllPayeeNames />
                  )
                }
                closeModal={setViewModal}
              />
            )}
          </section>
          <Styles.FiltersHrStyle />
          <section id='changePassword' className='ml-6'>
            <h2 className='fontSharpGrotesk22Medium text-lg'>
              Change Password
            </h2>
            <div id='passwordHint'>
              <p className=' text-gray-400 font-semibold text-sm'>
                Choose a unique password to protect your account.
              </p>
              <p className=' text-gray-400 font-semibold text-sm w-7/12 mb-0'>
                Password requirements: Min. 8 characters and Max. 10,
              </p>
              <p className='text-gray-400 font-semibold text-sm'>
                1 alphabetical character, 1 number, 1 special character.
              </p>
            </div>
            <FormTemplate
              // extraChangePasswordForm={true}
              extraFormContainerStyles={'w-7/12'}
              formFields={changePasswordForm}
              styles={'mt-16'}
              formName={'changePasswordForm'}
              submitConfig={[
                {
                  dataTestID: 'saveChangesNewPassword',
                  labelName: 'Save changes',
                  bgColor: 'white',
                },
              ]}
            />
          </section>

          <Styles.FiltersHrStyle />
          <section className='ml-2'>
            <h2 className='fontSharpGrotesk22Medium text-lg'>
              Email Notifications
            </h2>
            <p className=' text-gray-400 font-semibold text-sm'>
              Choose if, where and how you get email notifications for important
              updates.
            </p>
            <p className=' text-gray-400 font-semibold text-sm mt-12'>
              Preferred Email
            </p>
            <Box
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
                height: 45,
                borderRadius: 0.6,
                backgroundColor: colors.grayInputs,
                border: '1px solid #dedede',
              }}>
              <InputBase
                inputProps={{
                  'aria-label': `buyerEmail`,
                }}
                sx={{
                  flex: 1,
                  p: 1,
                  fontSize: 20,
                  input: {
                    '&::placeholder': {
                      '-webkit-text-fill-color': `black !important`,
                      fontWeight: 'bold',
                    },
                  },
                }}
                value={emailAddress}
              />
            </Box>
            <p className='font-semibold text-sm mt-12'>
              Send me email notifications for:
            </p>
            <FormGroup className='mb-8'>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={checkedEmails.payment_request_approval_email}
                    onChange={(event) =>
                      setCheckedEmails({
                        ...checkedEmails,
                        payment_request_approval_email: event.target.checked,
                      })
                    }
                  />
                }
                label={
                  <p className='font-semibold text-sm m-0'>
                    Payments request approvals
                  </p>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={checkedEmails.orders_shipment_statuses_email}
                    onChange={(event) =>
                      setCheckedEmails({
                        ...checkedEmails,
                        orders_shipment_statuses_email: event.target.checked,
                      })
                    }
                  />
                }
                label={
                  <p className='font-semibold text-sm m-0'>
                    Orders/shipment statuses
                  </p>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={checkedEmails.deals_email}
                    onChange={(event) =>
                      setCheckedEmails({
                        ...checkedEmails,
                        deals_email: event.target.checked,
                      })
                    }
                  />
                }
                label={
                  <p className='font-semibold text-sm m-0'>
                    Deals (New deals added + expiring deals)
                  </p>
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={checkedEmails.monthly_reports_email}
                    onChange={(event) =>
                      setCheckedEmails({
                        ...checkedEmails,
                        monthly_reports_email: event.target.checked,
                      })
                    }
                  />
                }
                label={
                  <p className='font-semibold text-sm m-0'>
                    Monthly reports (payments balances + activity summaries)
                  </p>
                }
              /> */}
            </FormGroup>

            <Button onClick={handleUpdateEmailPreferences} variant='contained'>
              Update
            </Button>
          </section>
          <Styles.FiltersHrStyle />
          <section id='personalReferralLink' className='ml-2'>
            <h2 className='fontSharpGrotesk22Medium text-lg'>
              Personal Referral Link
            </h2>
            <p className=' text-gray-400 font-semibold text-sm'>
              Keep track of your referrals and receive special benefits!
            </p>
            <div className='lg:flex mt-12' id='referralLink'>
              <Box
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  // width: 420,
                  height: 50,
                  borderRadius: 0.6,
                  backgroundColor: colors.grayInputs,
                  border: '1px solid #dedede',
                }}>
                <InputBase
                  disabled
                  name='referralLink'
                  sx={{
                    flex: 1,
                    p: 2,
                    input: {
                      '&::placeholder': {
                        '-webkit-text-fill-color': `black !important`,
                      },
                    },
                  }}
                  placeholder={`https://usabuying.group/referred-by/${buyerInfo.buyerID}`}
                />
              </Box>
              <div className='underline text-gray-400 ml-5 flex items-center'>
                <Button
                  data-testid='butonCopyReferral'
                  onClick={() => {
                    handleCopyClick(
                      `https://usabuying.group/referred-by/${buyerInfo.buyerID}`,
                    );
                  }}
                  startIcon={
                    <ContentCopyIcon sx={{ height: 17, marginRight: 1 }} />
                  }
                  sx={{
                    color: colors.blue,
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}>
                  Copy link
                </Button>
              </div>
            </div>
          </section>
        </CardContent>
        <div className='block lg:space-y-7'>
          <Divider
            className='block lg:hidden'
            sx={{
              bgcolor: '#dfdcdc',
              height: 1,
              borderRadius: 2,
            }}
          />
          <BuyingGroupIDCard setShowAlert={setShowAlert} />
          <Divider
            className='block lg:hidden'
            sx={{
              bgcolor: '#dfdcdc',
              height: 1,
              borderRadius: 2,
            }}
          />
          <SecurityCard />
        </div>
      </div>
    </main>
  );
};

export default UserProfileModule;
