import { styled } from 'twin.macro';

export const DealDetailContainer = styled.div`
  h6 {
    color: #b2b2b2;
    font-weight: bold;
    font-size: 0.7rem;
  }
`;

export const FiltersHrStyle = styled.hr`
  border-top: 2.5px solid #dfdcdc;
`;
