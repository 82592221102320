import React from 'react';
import Login from '../pages/Login';
import RedirectDealDetailWeweb from '../pages/RedirectDealDetailWeweb';

export const freeRoutes = [
  {
    path: '/',
    component: <Login />,
  },
  {
    path: '/deals',
    component: <RedirectDealDetailWeweb />,
    title: 'Active Deals',
  },
];
