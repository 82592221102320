import { styled } from 'twin.macro';

export const DealDetailContainer = styled.div`
  h6 {
    color: #b2b2b2;
    font-weight: bold;
    font-size: 0.7rem;
  }
`;

export const TableHeaderCommitContainer = styled.div`
  h3 {
    color: #b2b2b2;
  }
`;

export const DealCommittedContainer = styled.div`
  margin-top: 10px;

  .tableStyles .ant-table table {
    padding: 0;
  }

  .tableHeadtyles .ant-table-thead > tr > th {
    background-color: white;
    padding: 10px;
    color: #b2b2b2;
    font-weight: bold;
    font-size: 11px;
  }

  .tableBodyStyles .ant-table-tbody > tr > td {
    padding: 10px 13px;
  }
`;

export const CustomScrollContainer = styled.div`
  .sectionScroll {
    scrollbar-width: thin;
    scrollbar-color: light;
    overflow: auto;
    direction: rtl;
    padding-left: 2rem;
    margin-left: 1.3rem;
  }

  .sectionScroll::-webkit-scrollbar {
    width: 8px;
  }

  .sectionScroll::-webkit-scrollbar-track {
    background-color: #f3f3f3; /* color of the tracking area */
  }

  .sectionScroll::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

  .normalContainerTextDirection {
    direction: ltr;
  }
`;
