import { useMutation } from 'react-query';
import requestHigherLimitEnroll from '../../endPoints/trackingNumbers/requesHigherLimitEnroll';

export const useRequestHigherLimitEnroll = (limitRquest: any, messageError: any, closeModal: any, refetch: any) => {
  return useMutation(requestHigherLimitEnroll, {
    onSuccess: (data) => {
      closeModal();
      if (data.status) {
        limitRquest(true);
        closeModal();
        refetch();
      } else {
        messageError(true);
      }
    },
    onError: () => {
      messageError(true);
    }
  });
};