import React from 'react';
import usePriceFormat from '../../../../hooks/usePriceFormat';
const PurchaseAmount = ({ itemInfo }: any) => {
  return (
    <div className='fontGTAmericanR font-semibold'>
      {usePriceFormat(itemInfo.price * itemInfo.quantity)}
    </div>
  );
};

export default PurchaseAmount;
