import React, { useState } from 'react';
import { Button } from '@mui/material';
import { themeCustom } from '../../../theme';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew/index';
import { DetailsTrackingNumbers } from './DetailsTrackingNumbers';
import { useDeleteTracking } from '../../../api/services/trackingNumbers/deleteTracking';
import { useQueryClient } from 'react-query';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
const { colors } = themeCustom;

export const RenderActions = ({ purchase, trackingInfo }: any) => {
  const [viewModal, setViewModal] = useState({ active: false, name: '' });

  const [showAlert, setShowAlert] = React.useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const queryClient = useQueryClient();

  const { mutate } = useDeleteTracking();

  const handleDeleteTrackings = (id: string) => {
    mutate(id, {
      onSuccess(data) {
        if (data.status) {
          setShowAlert({
            show: true,
            message: data?.data,
            title: 'Success',
            type: 'success',
          });

          queryClient.invalidateQueries('GET_TRACKING_NUMBERS_DATA');
        }
      },
    });
  };

  return (
    <div className='flex'>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <Button
        disabled={purchase?.status === 'Cancelled'}
        onClick={() => setViewModal({ active: true, name: '' })}
        sx={{
          textTransform: 'none',
          color: colors.grayLabels,
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <img
          src='./images/trackingNumbers/icons-grid-2/button-details.png'
          alt=''
          width={25}
          className='cursor-pointer'
        />
      </Button>
      {viewModal.active && (
        <DrawerComponentUI
          anchor={'right'}
          viewRender={
            <DetailsTrackingNumbers
              data={trackingInfo}
              purchase={purchase}
              setViewModal={setViewModal}
            />
          }
          closeModal={setViewModal}
        />
      )}
      {trackingInfo.status !== 'Received' && (
        <Button
          onClick={() => handleDeleteTrackings(trackingInfo.id)}
          sx={{
            textTransform: 'none',
            color: colors.grayLabels,
            textAlign: 'start',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <img
            src='./images/trackingNumbers/icons-grid-2/delete.png'
            alt=''
            width={30}
            className='cursor-pointer'
          />
        </Button>
      )}
    </div>
  );
};
