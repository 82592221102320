import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  IPurchaseParams,
  IGetPurchaseServiceRes,
} from '../../../interfaces/trackingNumbers';
import fetchApiGet from '../../endPoints/trackingNumbers/getPurchase';
import getPurchaseInfoTransform from '../../transforms/trackingNumbers/getPurchaseInfoTransform';
import { AuthContext } from '../../../context/useSaveDataSession';

const getPurchaseInfo = (params: IPurchaseParams): IGetPurchaseServiceRes => {
  const { logout } = useContext<any>(AuthContext);

  // const [launchQeury] = useState(false);
  const { error, data, isLoading, refetch } = useQuery(
    ['GET_TRACKING_NUMBERS_DATA', params],
    () => {
      return fetchApiGet(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return {
      error,
      response: getPurchaseInfoTransform(data),
      isLoading,
      refetch,
    };
  } else {
    return { error, response: data, isLoading, refetch };
  }
};

export default getPurchaseInfo;
