import React, { useEffect, useState } from 'react'
import { Checkbox, InputNumber, Modal, Spin, message } from 'antd'
import type { CheckboxProps } from 'antd';
import getInfoCards from '../../../api/services/dashboard/getInfoCards';
import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';
import { useAddInsuranceEnrroled } from '../../../api/services/trackingNumbers/addInsuranceEnroll';
import { useRequestHigherLimitEnroll } from '../../../api/services/trackingNumbers/requestHigherLimitEnroll';
import TextArea from 'antd/lib/input/TextArea';


const RollPackage = () => {

    const { buyerInfo } = useStatesGlobalStore();

    const { response, refetch } = getInfoCards();

    const [messageApi, contextHolder] = message.useMessage();
    const [enrolled, setEnrolled] = useState(false);
    const [messageError, setMessageError] = useState(false);

    const [rollPackageModal, setrollPackageModal] = useState(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [limitSuccess, setlimitSuccess] = useState(false);
    const [inputValue, setInputValue] = useState<number>(0);
    const [textareaValue, setTextareaValue] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const handleInputChange = (value: number) => {
        setInputValue(value);
    };

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaValue(event.target.value);
    };

    const openModal = () => {
        setrollPackageModal(true);
    }

    const closeModal = () => {
        setrollPackageModal(false);
    }
    const { mutate, isLoading } = useAddInsuranceEnrroled(setEnrolled, setMessageError, closeModal, refetch);
    const { mutate: requesLimit, isLoading: loadRequest } = useRequestHigherLimitEnroll(setlimitSuccess, setMessageError, closeModal, refetch);

    const onChange: CheckboxProps['onChange'] = (e) => {
        setIsChecked(e.target.checked);
    };

    const onSubmit = () => {
        mutate();
    };

    const handleRequestLimit = () => {
        requesLimit({
            value: inputValue,
            comments: textareaValue
        });
        setInputValue(0);
        setTextareaValue('');
    }

    const hadleCloseSuccess = () => {
        setlimitSuccess(false);
    }

    useEffect(() => {
        if (enrolled) {
            setlimitSuccess(true);
        }
    }, [enrolled])


    useEffect(() => {
        if (messageError) {
            messageApi.open({
                type: 'error',
                content: 'Error',
            });
        }
    }, [messageError])

    useEffect(() => {
        setIsDisabled(inputValue === 0 || textareaValue === '');
    }, [inputValue, textareaValue])


    return (
        <>
            {contextHolder}
            {(response?.data?.insurance_enroll && response?.data?.insurance_enroll > 0) || enrolled ?
                <button onClick={openModal}
                    className='flex w-full xl:w-[250px] xl:ml-0 items-center bg-white pl-5 py-4 xl:rounded-3xl cursor-pointer xl:h-[4rem]'>
                    <div className='flex gap-4'>
                        <div style={{ paddingTop: '3px' }}>
                            <img src='./images/trackingNumbers/icons-grid-2/enrolled-icon-2.png' style={{ width: '30px', height: '30px', marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', marginRight: 'auto' }} />
                            <p className='text-[#178DFF] text-[10px] font-semibold pt-0.9 mb-0 fontGTAmericanR'>Enrolled</p>
                        </div>
                        <h2 style={{ lineHeight: 1.2 }} className='text-[#178DFF] text-left pt-0.9 mb-0 mr-1 fontGTAmericanR font-medium'>
                            Current limit <span className='font-bold'>${response?.data?.insurance_max_value}</span> <br /> per package. <br />
                            <span className='underline italic text-xs'>Request higher limit?</span>
                        </h2>
                    </div>
                </button>
                :
                <button onClick={openModal}
                    className='flex w-full xl:w-[233px] xl:ml-0 items-center bg-white pl-2 py-4 xl:rounded-3xl cursor-pointer xl:h-[4rem]'>
                    <div className='flex gap-2 px-4'>
                        <div style={{ paddingTop: '3px' }}>
                            <img src='./images/trackingNumbers/icons-grid-2/enrolled-icon-2.png' style={{ width: '30px', height: '30px', marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', marginRight: 'auto' }} />
                            <p className='text-[#178DFF] text-[10px] w-16 font-semibold pt-0.9 mb-0 fontGTAmericanR'>Not enrolled</p>
                        </div>
                        <h2 className='text-[#178DFF] pt-1 mb-0 fontGTAmericanR font-medium'>
                            Enroll In package coverage
                        </h2>
                    </div>
                </button>}
            <Modal
                visible={limitSuccess}
                onCancel={hadleCloseSuccess}
                style={{ top: 300 }}
                bodyStyle={{ borderRadius: '20px' }}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
                footer={null}>
                <h1 className='text-center text-xl font-semibold pt-2'>The request has been submitted.</h1>
            </Modal>

            <Modal
                visible={rollPackageModal}
                onCancel={closeModal}
                style={{ top: 90 }}
                className='background-container'
                bodyStyle={{ backgroundColor: '#3b98f5', borderRadius: '20px', opacity: 11 }}
                width={window.innerWidth < 1200 ? '90%' : '60%'}
                footer={null}>
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='text-4xl text-white text-center font-bold w-7/12'>USA Buying Safe Ship Terms</h1>
                    {response?.data?.insurance_enroll === 1 &&
                        <div style={{ backgroundColor: '#8DC8FF', width: '94%', borderRadius: 20, padding: 12, marginBottom: 20, gap: 10 }}>
                            <Spin spinning={loadRequest} size='large' style={{ backgroundColor: 'rgba(141, 200, 255, 0.6)' }}>
                                <div className='flex flex-wrap sm:gap-3'>
                                    <p className='text-lg mr-3'>Amount requested:                                </p>
                                    <InputNumber value={inputValue} addonBefore="$" className='input-request' style={{ borderRadius: '20px' }}
                                        onChange={(value) => handleInputChange(value)} />
                                </div>
                                <div className='flex flex-wrap sm:gap-3'>
                                    <p className='text-lg mr-20'>Any notes:</p>
                                    <TextArea size='small' className='rounded-xl' style={{ maxWidth: '70%', borderRadius: 10 }} rows={3}
                                        value={textareaValue} onChange={handleTextareaChange} />
                                </div>
                            </Spin>
                        </div>}
                    <div className={response?.data.insurance_enroll === 0 ? 'h-[29rem]' : 'h-[28rem]'}
                        style={{ backgroundColor: '#8DC8FF', width: '94%', borderRadius: 20, padding: 12, overflowY: 'scroll' }}>
                        <Spin spinning={isLoading || loadRequest} size='large' style={{ backgroundColor: 'rgba(141, 200, 255, 0.6)' }}>
                            <p className='text-xl text-justify'>
                                By enrolling in this program, you agree to the following terms:
                                <br /><br />
                                <span className='font-semibold'>1.⁠ ⁠Enrollment and Coverage:</span><br />
                                <br />
                                - Participants must enroll in the program to receive coverage.<br />
                                - Only packages shipped to our Warehouse(s) after enrollment and during the active enrollment period are covered.<br />
                                - Coverage is limited up to the specified package limit.<br />
                                <br />
                                <span className='font-semibold'>2.⁠ ⁠Claims Process:</span><br />
                                <br />
                                - All claims must be submitted within One (1) week of delivery.<br />
                                - Claims will undergo a verification process conducted by the program's team.<br />
                                - Verification may include but is not limited to invoices etc.<br />
                                - Invoices related to the shipment.<br />
                                - Denial from the shipper/retailer.<br />
                                - Proof of delivery documentation.<br />
                                - Financial transactions associated with the claim.<br />
                                - Possibly conducting remote sessions to verify submitted documents.<br />
                                <br />
                                <span className='font-semibold'>3.⁠ ⁠Fraud Prevention:</span><br />
                                <br />
                                - Claims suspected of being fraudulent will be denied.<br />
                                - USA Buying Safe Ship reserves the right to deny any claim believed to be fraudulent.<br />
                                - USA Buying Safe Ship also reserves the right to cancel enrollment if necessary.<br />
                                <br />
                                <span className='font-semibold'>4.⁠ ⁠Updates to Terms:</span><br />
                                <br />
                                - The terms of the program are subject to change at any time.<br />
                                - Participants are bound by the most current terms as posted or provided.<br />
                                <br />
                                These terms are designed to ensure the integrity of the program and protect both participants and the program itself from fraudulent claims or misuse. It’s important for participants to understand these terms before enrolling to avoid any misunderstandings regarding coverage and claims.
                            </p>
                        </Spin>
                    </div>
                    {response?.data?.insurance_enroll === 0 ?
                        <div className='text-center' style={{ backgroundColor: '#8DC8FF', borderRadius: 20, padding: 10, marginTop: 40, width: '78%', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin spinning={isLoading} style={{ backgroundColor: 'rgba(141, 200, 255, 0.6)' }}>
                                <Checkbox checked={isChecked} onChange={onChange}>
                                    <p className='text-xl font-medium pt-5 text-justify'>I {buyerInfo?.firstName} {buyerInfo?.lastName} agree to these terms and conditions</p>
                                </Checkbox>
                                <button disabled={!isChecked} onClick={onSubmit}
                                    className='flex items-center bg-[#178DFF] hover:bg-blue-600 text-white  disabled:bg-white px-5 pb-1  xl:rounded-3xl cursor-pointer disabled:text-[#178DFF] mx-auto'>
                                    <div className='flex gap-1'>
                                        <h2 className={isChecked ? 'text-white text-lg font-semibold pt-1 mb-0 fontGTAmericanR' : `text-#178DFF] text-lg font-semibold pt-1 mb-0 fontGTAmericanR`}>
                                            Submit
                                        </h2>
                                    </div>
                                </button>
                            </Spin>
                        </div>
                        :
                        <button disabled={isDisabled} onClick={handleRequestLimit}
                            className='flex items-center bg-[#82baee] hover:bg-[#77ade0] mt-10 mb-3 disabled:bg-gray-200 text-white px-5 pb-1  xl:rounded-3xl cursor-pointer disabled:text-[#178DFF] mx-auto'>
                            <div className='flex gap-1'>
                                <h2 className='text-lg font-semibold pt-0 mb-0 fontGTAmericanR'>
                                    Send request
                                </h2>
                            </div>
                        </button>
                    }
                </div>
            </Modal >
        </>
    )
}

export default RollPackage