import { useContext } from 'react';
import { useQuery } from 'react-query';
import { IResposeService } from '../../../interfaces/trackingNumbers';
import fetchApiGet from '../../endPoints/trackingNumbers/index';
import transformTrackingNumbersData from '../../transforms/trackingNumbers/trackingNumbers';
import { AuthContext } from '../../../context/useSaveDataSession';

const getTrackingNumbers = (params: any): IResposeService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    ['GET_TRACKING_NUMBERS_DATA', params],
    () => {
      return fetchApiGet(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: transformTrackingNumbersData(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default getTrackingNumbers;
