import {
  IPurchaseData,
  IPurchaseDataTransform,
} from '../../../interfaces/trackingNumbers/index';

const getPurchaseInfoTransform = (
  data: IPurchaseData,
): IPurchaseDataTransform => {
  const pO = data.data?.po;
  const items = data.data?.items;
  const payments = data.data?.payments;

  const metaTransform = {
    status: data.status,
  };
  return { pO, items, payments, meta: metaTransform };
};
export default getPurchaseInfoTransform;
