import React, { useEffect, useState } from 'react';
import * as Styles from './style';

// TODO: WHEN IT'S NEEDED
import { IFilters } from '../../interfaces/activeDeals';
import {
  selectFilterPrice,
  selectFilterExpires,
  selectFilterPriceType,
} from './constants/index';
import CardList from '../activeDeals/containers/cardList';
import List from './containers/List';
// TODO: WHEN IT'S NEEDED
import Filter from '../../components/UI/filter';
import { Link } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useCart } from 'react-use-cart';
import GeneralAlert from '../../components/UI/generalAlert/GeneralAlert';
import getActiveDeals from '../../api/services/activeDeals/index';
import MobileList from '../../components/UI/mobileList';
import useGetSelectVendors from '../../api/services/activeDeals/getSelectVendors';
import useGetSelectBrands from '../../api/services/activeDeals/getSelectBrands';
import MobileFilter from '../../components/UI/mobileFilter';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';
import useStatesGlobalStore from '../../hooks/useStatesGlobalStore';
import { simpleSelectFilter } from './constants/index';
import { postSaveFilterSetService } from '../../api/services/activeDeals/postSaveFilterSetService';
import getFilterSetService from '../../api/services/activeDeals/getFilterSet';
import PaginationCustom from '../../components/UI/PaginationCustom';

const ActiveDealsModule = () => {
  const { items } = useCart();
  // const [cards, setCards] = useState(false);
  const [selectFilters, setSelectFilters] = useState<any>([]);
  const [current, setCurrent] = useState(1);
  const [sizeItemsPage, setSizeItemPage] = useState(10);

  const { response: selectVendors, isLoading: loadingVendors } =
    useGetSelectVendors();

  const { response: selectBrands, isLoading: loadingBrands } =
    useGetSelectBrands();

  useEffect(() => {
    if (selectVendors && selectBrands) {
      setSelectFilters([
        selectVendors.data,
        selectBrands.data,
        selectFilterPrice,
        selectFilterExpires,
        selectFilterPriceType,
      ]);
    }
  }, [loadingVendors, loadingBrands]);

  // TODO: WHEN IT'S NEEDED
  const [filterOptions, setFilterOptions] = React.useState<IFilters>({
    brands: [],
    retailers: [],
    price: [],
    expires: [],
    // category: [],
    filterSets: '',
    priceType: [],
  });
  const [showAlert, setShowAlert] = useState({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const [presentSelect, setPresetSelect] = useState('');

  const { response, isLoading, refetch } = getActiveDeals({
    brand_name: filterOptions.brands,
    vendor: filterOptions.retailers,
    price: filterOptions.price,
    expired: filterOptions.expires,
    list_price_type: filterOptions.priceType,
    start: current,
    limit: sizeItemsPage,
  });

  const { mutate, isLoading: isLoadingPost } = postSaveFilterSetService();

  const { response: responseFilterSet } = getFilterSetService({
    module_name: 'active_deals',
  });

  const { selectedCardActiveDeals, setSelectedCardActiveDeals } =
    useStatesGlobalStore();

  useEffect(() => {
    if (responseFilterSet) {
      const res = responseFilterSet.data.filter(
        (filter: any) => filter.is_default === '1',
      );

      setPresetSelect('preset');
      if (res?.length) {
        setFilterOptions({ ...res[0].filter_data });
      } else {
        return setFilterOptions({
          brands: [],
          retailers: [],
          price: [],
          expires: [],
          filterSets: '',
          priceType: [],
        });
      }
    } else {
      return;
    }
  }, [loadingVendors, loadingBrands]);

  useEffect(() => {
    if (filterOptions.filterSets !== '') {
      const result = responseFilterSet?.data.filter(
        (item: any) => item.filter_name === filterOptions.filterSets,
      );

      setPresetSelect('manual select');

      setFilterOptions(result[0]?.filter_data);
    }

    if (filterOptions.filterSets === '' && presentSelect === 'manual select') {
      return setFilterOptions({
        brands: [],
        retailers: [],
        price: [],
        expires: [],
        filterSets: '',
        priceType: [],
      });
    }
  }, [filterOptions.filterSets, presentSelect]);

  useEffect(() => {
    if (items.length >= 1) {
      setShowAlert({
        ...showAlert,
        show: true,
        message: 'Item added successfully',
      });
      setTimeout(() => {
        setShowAlert({ ...showAlert, show: false });
      }, 1200);
    }
  }, [items.length]);

  useEffect(() => {
    setShowAlert({ ...showAlert, show: false });
  }, []);

  const clearFilters = () => {
    setFilterOptions({
      brands: [],
      retailers: [],
      price: [],
      expires: [],
      filterSets: '',
      priceType: [],
    });
  };

  useEffect(() => {
    refetch();
  }, [filterOptions]);

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.view, alertTurnScreenMobile.view]);

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={'success'}
          alertMessage={showAlert.message}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <header className='flex flex-wrap justify-between mt-11' id='header'>
        <div className='flex justify-between w-full mb-5 lg:mb-0 lg:block lg:w-auto'>
          <h1 className='fontSharpGrotesk22Medium text-xl lg:ml-5 lg:text-[2rem]'>
            Active Deals
          </h1>
          <Link to='/my-commitments'>
            <span className='lg:ml-5  underline text-gray-400'>
              View My Commitments
            </span>
          </Link>
        </div>
        <Link className='hidden lg:flex' to='/my-deals-cart'>
          <button className='flex text-blue-400 px-4 gap-4 bg-white rounded-3xl font-semibold cursor-pointer h-[4rem] w-[235px]'>
            <span className='m-auto'>
              <AddShoppingCartIcon />
            </span>
            <span className='m-auto'>{items.length} Commitments</span>
            <span className='m-auto'>
              <ArrowForwardIcon />
            </span>
          </button>
        </Link>
      </header>
      <div
        id='optionsDisplayView'
        className={`w-full my-3 pr-3 text-right gap-4 hidden lg:flex flex-wrap justify-end `}>
        <div
          className={`cursor-pointer ${
            selectedCardActiveDeals ? 'text-blue-300' : 'text-gray-300'
          }`}
          onClick={() => setSelectedCardActiveDeals(true)}>
          <GridViewOutlinedIcon />
        </div>
        <div
          className={`cursor-pointer ${
            selectedCardActiveDeals ? 'text-gray-300' : 'text-blue-300'
          } `}
          onClick={() => setSelectedCardActiveDeals(false)}>
          <MenuOutlinedIcon />
        </div>
      </div>
      <div className='hidden xl:block w-full'>
        <Filter
          filterOptions={filterOptions}
          formStyles={'xl:flex w-full xl:flex-wrap'}
          setFilterOptions={setFilterOptions}
          selectFilters={selectFilters}
          showFilterTitle={true}
          isSaveFilterVisible={true}
          simpleSelectFilters={simpleSelectFilter()}
          saveModule={'active_deals'}
          mutate={mutate}
          isLoading={isLoadingPost}
        />
      </div>
      <Styles.TableContainer>
        <div className='mb-4 xl:hidden'>
          <MobileFilter
            clearFilters={clearFilters}
            filterOptions={filterOptions}
            selectFilters={selectFilters}
            setFilterOptions={setFilterOptions}
            showFilterTitle={false}
            isSaveFilterVisible={true}
            simpleSelectFilters={simpleSelectFilter()}
            saveModule={'active_deals'}
            mutate={mutate}
            mySet
          />
          <MobileList data={response?.data} isActiveDeals={true} />
          <div className='lg:hidden'>
            <PaginationCustom
              totalItems={response?.meta?.totals}
              current={current}
              setSizeItemPage={setSizeItemPage}
              sizeItemsPage={sizeItemsPage}
              setCurrent={setCurrent}
              mobile={isMobile}
            />
          </div>
        </div>
        {selectedCardActiveDeals && response?.data && (
          <div className='hidden lg:block'>
            <CardList dealsCard={response?.data} />
            <PaginationCustom
              totalItems={response?.meta?.totals}
              current={current}
              setSizeItemPage={setSizeItemPage}
              sizeItemsPage={sizeItemsPage}
              setCurrent={setCurrent}
            />
          </div>
        )}

        {!selectedCardActiveDeals && response?.data && (
          <div className='hidden lg:block'>
            <List deals={response?.data} loading={isLoading} />
            <PaginationCustom
              totalItems={response?.meta?.totals}
              current={current}
              setSizeItemPage={setSizeItemPage}
              sizeItemsPage={sizeItemsPage}
              setCurrent={setCurrent}
            />
          </div>
        )}

      </Styles.TableContainer>
    </>
  );
};

export default ActiveDealsModule;