import { useContext } from 'react';
import { useQuery } from 'react-query';
import fetchGetSelectVendors from '../../endPoints/activeDeals/getSelectVendors';
import getSelectVendorsTransform from '../../transforms/activeDeals/getSelectVendorsTransform';
import { IServiceResponseTransform } from '../../transforms/activeDeals/getSelectVendorsTransform';
import { AuthContext } from '../../../context/useSaveDataSession';

interface IResponseService {
  response: IServiceResponseTransform;
  error: any;
  isLoading: boolean;
}

const useGetSelectVendors = (): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    'GET_SELECT_VENDORS',
    fetchGetSelectVendors,
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: getSelectVendorsTransform(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default useGetSelectVendors;
