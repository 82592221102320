import React, { useState } from 'react';
import { Popover } from 'antd';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import { useAddTrackingNumbers } from '../../../api/services/trackingNumbers/addTrackingNumbers';
import { IAlertType } from '../../../interfaces/activeDeal';
import { useQueryClient } from 'react-query';

const EditOtpCode = ({ item, tracking }: any) => {
  const queryClient = useQueryClient();
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const [openEdit, setOpenEdit] = useState(false);
  const [newOtp, setNewOtp] = useState('');

  const { mutate } = useAddTrackingNumbers();

  const handleEditOtp = () => {
    const editOtp = `${tracking.tracking_number}:${newOtp}`;

    mutate(
      { trackings: editOtp },
      {
        onSuccess: (data) => {
          setNewOtp('');
          setOpenEdit(false);
          setShowAlert({
            show: true,
            message: data?.tracking_numbers_added.message,
            title: 'Success',
            type: 'success',
          });
          queryClient.invalidateQueries('GET_TRACKING_NUMBERS_DATA');
        },
        onError: (error: any) => {
          console.log(error);
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
        },
      },
    );
  };

  const handleCancelOtp = () => {
    setNewOtp('');
    setOpenEdit(false);
  };

  const handleOpenOtp = () => {
    setNewOtp(item);
    setOpenEdit(!openEdit);
  };

  return (
    <div className='flex items-center align-middle justify-center'>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <Popover
        visible={openEdit}
        placement='bottom'
        trigger='click'
        content={
          <div className='flex flex-col items-center'>
            <p className='font-semibold'>
              {item ? 'Edit OTP' : 'Add OTP code'}
            </p>
            <input
              className='text-center bg-[#E6F3FE] py-1 border-none w-3/4'
              type='text'
              placeholder='Enter value'
              value={newOtp}
              defaultValue={item}
              onChange={(e) => setNewOtp(e.target.value)}
            />
            <div className='flex gap-3 mt-5 justify-center'>
              <h3
                className='text-[#158DFE] cursor-pointer border border-[#158DFE] px-4 py-1 rounded-2xl'
                onClick={handleCancelOtp}>
                Cancel
              </h3>
              <h3
                className='text-white bg-[#158DFE] cursor-pointer border border-[#158DFE] px-4 py-1 rounded-2xl'
                onClick={handleEditOtp}>
                Save
              </h3>
            </div>
          </div>
        }>
        {tracking.allow_otp == 0 && (
          <img
            src='./images/trackingNumbers/icons-grid-2/no-otp.png'
            alt=''
            width={23}
            className='cursor-pointer'
          />
        )}

        {!item && tracking.allow_otp == 1 && (
          <img
            src='./images/trackingNumbers/icons-grid-2/plus.png'
            alt=''
            width={23}
            className='cursor-pointer'
            onClick={handleOpenOtp}
          />
        )}
        {item && tracking.allow_otp == 1 && (
          <img
            src='./images/trackingNumbers/icons-grid-2/edit-checked.png'
            alt=''
            width={23}
            className='cursor-pointer'
            onClick={handleOpenOtp}
          />
        )}
      </Popover>
    </div>
  );
};

export default EditOtpCode;
