import React, { useEffect, useState } from 'react';
import { Modal, Popover } from 'antd';
// import { themeCustom } from '../../../theme';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Stack } from '@mui/material';
import { useAddTrackingNumbers } from '../../../api/services/trackingNumbers/addTrackingNumbers';
import { IAlertType } from '../../../interfaces/activeDeal';
import { IAddTrackinNumbers } from '../../../interfaces/trackingNumbers';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew';
import useResponsive from '../../../hooks/useResponsive';
import WarningIcon from '@mui/icons-material/Warning';
import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';

const AddTrackingNumbers = () => {
  // const { colors } = themeCustom;
  const { isMobile } = useResponsive();
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [showAlertTwo, setShowAlertTwo] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [viewModal, setViewModal] = useState({ active: false });

  const [isModalOpen, setIsModalOpen] = useState<any>(false);

  const [errorsInsurance, setErrorsInsurance] = useState<any>([]);

  const { dashboardInfo } = useStatesGlobalStore();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<IAddTrackinNumbers>();
  const { mutate } = useAddTrackingNumbers();
  const queryClient = useQueryClient();
  const location = useLocation();
  let orderOpenAddTrackingModal: any = location?.state;

  const onSubmit: SubmitHandler<IAddTrackinNumbers> = (trackingNumbers) => {
    try {
      setIsVisible(false);
      mutate(trackingNumbers, {
        onSuccess: (data) => {
          reset({ trackings: '' });
          setIsVisible(false);
          setViewModal({ active: false });
          if (data?.tracking_numbers_insurance_added) {
            if (data?.tracking_numbers_insurance_added.errors.length > 0) {
              showModal(data?.tracking_numbers_insurance_added?.errors);
            }
          }
          if (data.tracking_numbers_added) {
            setShowAlert({
              show: true,
              message:
                data?.tracking_numbers_added.messages.errors.length > 0
                  ? data?.tracking_numbers_added.messages.errors
                  : data?.tracking_numbers_added.messages.success,
              title:
                data?.tracking_numbers_added.messages.errors.length > 0
                  ? 'Error'
                  : 'Success',
              type:
                data?.tracking_numbers_added.messages.errors.length > 0
                  ? 'error'
                  : 'success',
            });
          }
          else if (!data.status) {
            setShowAlert({
              show: true,
              message: data?.error,
              title: 'Error',
              type: 'error',
            });
          }

          queryClient.invalidateQueries('GET_TRACKING_NUMBERS_DATA');
        },
        onError: (error: any) => {
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
        },
      });
    } catch (error: any) {
      setShowAlert({
        show: true,
        message: error,
        title: 'Error',
        type: 'error',
      });
    }
  };

  const handleCancel = () => {
    if (isMobile) {
      setIsVisible(false);
      setViewModal({ active: false });
      reset({ trackings: '' });
    } else {
      setIsVisible(false);
      reset({ trackings: '' });
    }
  };

  const handlePopoverClick = (visible: any) => {
    isMobile ? setViewModal({ active: visible }) : setIsVisible(visible);
  };

  useEffect(() => {
    if (orderOpenAddTrackingModal) {
      setIsVisible(orderOpenAddTrackingModal);
      setViewModal({ active: orderOpenAddTrackingModal });
    }
  }, []);

  const showModal = (errors: any) => {
    setErrorsInsurance(errors);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancelModal = () => {
    setErrorsInsurance([]);
    setIsModalOpen(false);
  };

  const BuildAddTrackingNumbers = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='w-full xlc:w-[26rem]'
        // data-testid='formAddTracking'
        data-testid='cancelAddTrackingBtn'>
        <h3 className='block xlc:hidden fontSharpGrotesk22Medium text-xl mb-6'>
          Add Tracking Number
        </h3>
        <p className='font-bold'>Paste all your tracking numbers here</p>
        <p className=''>
          Separate each tracking number with a comma, or enter one tracking
          number per line.
        </p>
        <p className='font-bold'>Only enter VALID tracking numbers</p>
        {dashboardInfo?.info.trackings_insurance_available === 1 ? (
          <p>
            If you would like to submit the OTP or Shipment Value with your
            tracking number, add a colon : before the OTP and a $ symbol before
            the value
          </p>
        ) : (
          <p>
            if you would like to submit the OTP with your tracking number, add a
            colon : before the OTP
          </p>
        )}

        <p className='font-bold'>Example:</p>
        {dashboardInfo?.info.trackings_insurance_available === 1 && (
          <div>
            <p className='font-bold mb-0'>Tracking with just value</p>
            <p className=''>TBA123456789012$151.23</p>
          </div>
        )}
        <p className='font-bold mb-0'>Tracking with Just OTP</p>
        <p>TBA123456789012:OPTPASS</p>

        {dashboardInfo?.info.trackings_insurance_available === 1 && (
          <div>
            <p className='font-bold mb-0'>Tracking with OTP and Value</p>
            <p>TBA123456789012:OPTPASS$151.23</p>
          </div>
        )}
        <Controller
          name={'trackings'}
          control={control}
          defaultValue=''
          render={({ field }) => (
            <Stack>
              <textarea
                {...field}
                {...register('trackings', {
                  required: 'This field is required',
                })}
                name='trackings'
                data-testid='addTrackingNumbersTextArea'
                className='bg-gray-100 w-full h-[10rem] text-lg'></textarea>
              <ErrorMessage errors={errors} name={'trackings'} />
            </Stack>
          )}
        />
        <div className='flex xlc:justify-center gap-4 xlc:gap-2 mt-5 xl:mt-3'>
          <h3
            data-testid={`${isMobile ? 'isMbileCancel' : ''}`}
            className='text-[#158DFE] cursor-pointer border border-[#158DFE] px-4 py-1 rounded-2xl'
            onClick={handleCancel}>
            Cancel
          </h3>
          <h3
            data-testid='buttonSubmitAddTrackingsNumbers'
            className='text-white bg-[#158DFE] cursor-pointer border border-[#158DFE] px-4 py-1 rounded-2xl'
            onClick={handleSubmit(onSubmit)}>
            Submit
          </h3>
        </div>
      </form>
    );
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}

      {showAlertTwo.show && (
        <GeneralAlert
          alertType={showAlertTwo.type}
          alertMessage={showAlertTwo.message}
          alertTitle={showAlertTwo.title}
          setShowAlert={setShowAlertTwo}
          fontSize={17}
        />
      )}
      <Popover
        placement='bottom'
        trigger='click'
        data-testid='formRequestPayment'
        content={BuildAddTrackingNumbers}
        onVisibleChange={handlePopoverClick}
        visible={isVisible && !isMobile}>
        <button
          className='flex justify-between w-full xl:w-[233px] xl:ml-0 items-center xl:bg-white px-6 py-4 xl:rounded-3xl cursor-pointer text-blue-400 xl:h-[4rem]'
          onClick={() => {
            setIsVisible(true);
          }}>
          <div className='flex gap-4'>
            <PlusCircleOutlined style={{ fontSize: '24px' }} />
            <h2 className='text-[#178DFF] mb-0 fontGTAmericanR'>
              Add Tracking Numbers
            </h2>
          </div>
          <img
            alt='delete icon'
            className='h-4 w-2 xl:hidden'
            height='17'
            src='images/trackingNumbers/blueRightArrow.png'
            width='12'
          />
        </button>
      </Popover>
      {isVisible && isMobile && viewModal.active && (
        <DrawerComponentUI
          anchor={'bottom'}
          modalBorderTopRightRadius={'30px'}
          viewRender={<BuildAddTrackingNumbers />}
          closeModal={setViewModal}
          width={'100%'}
        />
      )}

      <Modal
        title='Adding tracking numbers'
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancelModal}
        footer={null}>
        <div className='flex justify-center items-center'>
          <WarningIcon fontSize='large' color='warning' />
          <p className='mb-0'>
            There was a problem with the following trackings:
          </p>
        </div>
        <div className='mt-8 mx-12'>
          {errorsInsurance?.length > 0 &&
            errorsInsurance?.map((item: any) => (
              <p className='font-bold mb-0'>{item}</p>
            ))}
        </div>

        <div className='flex mt-8 mx-8'>
          {/* <p className='text-rose-500 text-base mr-2'>*</p>
          <p>
            Remember you have 24 hours to add an insurance policy, by clicking
            on the edit blue button.
          </p> */}
        </div>
      </Modal>
    </>
  );
};

export default AddTrackingNumbers;
