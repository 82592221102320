import React, { useState } from 'react';
import * as Styles from './style';
import CardContent from '../cardContent';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import usePriceFormat from '../../../hooks/usePriceFormat';
import { Tag } from 'antd';
import { validateExpires } from '../../../helpers';
import { Collapse, Divider } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ProductCard = ({ deal }: any) => {
  const [open, setOpen] = useState(false);

  const handleOpenQuickCommit = () => {
    setOpen(!open);
  };

  const productDetailsData = [
    {
      detail: { name: 'Model Number', styles: '' },
      assignment: { value: deal?.item?.marc, styles: 'text-sm font-semibold' },
      badge: false,
    },
    {
      detail: { name: 'Deal Expires', styles: '' },
      assignment: {
        value: deal?.dealExpires,

        styles: 'text-red-500',
      },
      badge: false,
    },
    {
      detail: { name: 'List Price', styles: '' },
      assignment: {
        value: usePriceFormat(deal?.variations[0].list_price || 0),

        styles: 'text-sm font-semibold',
      },
      badge: false,
    },
    {
      detail: { name: 'We Reimburse', styles: '' },
      assignment: {
        value: usePriceFormat(deal?.price || 0),
        styles: 'text-sm font-semibold',
      },
      badge: false,
      banner: (
        <>
          {Number(deal?.price) > Number(deal?.variations[0]?.list_price) && (
            <Tag
              style={{
                borderRadius: 10,
                fontSize: '8px',
                width: '4.5rem',
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                textAlign: 'center',
              }}
              color='#81BFFD'>
              {usePriceFormat(
                Number(deal?.price) - Number(deal?.variations[0]?.list_price) ||
                  0,
              )}
              <ArrowUpwardIcon fontSize='inherit' />
              Price
            </Tag>
          )}

          {Number(deal?.price) < Number(deal?.variations[0]?.list_price) && (
            <Tag
              style={{
                borderRadius: 10,
                fontSize: '8px',
                width: '4.5rem',
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                textAlign: 'center',
              }}
              color='#ff6339'>
              {usePriceFormat(
                Number(deal?.variations[0].list_price) - Number(deal?.price) ||
                  0,
              )}
              <ArrowDownwardIcon fontSize='inherit' />
              Price
            </Tag>
          )}
        </>
      ),
    },
  ];

  const transfomImages = deal?.item?.image?.split('//')[1];

  return (
    <CardContent
      styles={
        'xl:mt-4 xl:flex items-center justify-center px-6 xl:px-0 rounded-b-3xl'
      }>
      <div className='flex w-full xl:w-6/12'>
        <picture
          className='mt-4 xl:w-[10rem] xl:h-[10rem] xl:ml-6 w-[100px] h-[100px] p-1 float-left mr-8'
          style={{ boxSizing: 'border-box' }}>
          <img
            className='w-full h-full object-contain'
            src={`https://${transfomImages}`}
            alt='product image'
            width={200}
            height={200}
          />
        </picture>
        <div className='ml-6 mt-4 xl:mt-10 w-7/12 xl:w-7/12'>
          <p className='text-sm text-gray-400'>{deal?.item?.marc}</p>
          <p className='text-lg xl:text-[1.5rem] fontGTAmericanR xl:mt-1 mb-3 truncate-text'>
            {deal?.item?.description}
          </p>
          {/* <p className='text-lg xl:text-[1.75rem] fontGTAmericanR font-bold'>
            <data> {usePriceFormat(deal?.variations[0]?.list_price || 0)}</data>
          </p> */}
        </div>
      </div>
      <div className='w-6/12 hidden xl:flex'>
        <Styles.DealDetailContainer className='bg-gray-200 p-8 xl:pl-14 rounded-3xl ml-28'>
          <div className='w-full'>
            <h5 className='fontGTAmericanR'>Product Details</h5>
            <div className='flex mt-6 space-x-12'>
              <div className='fontGTAmericanR'>
                <h6>Model Number</h6>
                <p className='font-bold fontGTAmericanR'>{deal?.item?.marc}</p>
              </div>
              <div className=''>
                <h6>
                  <span className='mr-2 fontGTAmericanR'>Deal Expires</span>
                  <InfoOutlinedIcon sx={{ height: 14, width: 14 }} />
                </h6>
                <p className='mb-0 fontGTAmericanR font-bold'>
                  {' '}
                  {deal?.dealExpires}
                </p>
                <div>
                  {validateExpires(deal?.days_to_expire) === 1 ? (
                    <Tag
                      style={{ borderRadius: 10, fontSize: '10px' }}
                      color='#cd201f'>
                      Expired!
                    </Tag>
                  ) : validateExpires(deal?.days_to_expire) === 0 ? (
                    <Tag
                      style={{ borderRadius: 10, fontSize: '10px' }}
                      color='#F86238'>
                      Expires Soon!
                    </Tag>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className='fontGTAmericanR'>
                <h6>List Price</h6>
                <p className='font-bold fontGTAmericanR'>
                  {usePriceFormat(Number(deal?.variations[0]?.list_price))}
                </p>
              </div>
              <div>
                <h6>We Reimburse</h6>
                <p
                  className={
                    Number(deal?.price) >
                    Number(deal?.variations[0]?.list_price)
                      ? 'flex flex-col font-bold fontGTAmericanR'
                      : 'flex flex-col font-bold fontGTAmericanR'
                  }>
                  {usePriceFormat(Number(deal?.price))}
                  {Number(deal?.price) >
                    Number(deal?.variations[0]?.list_price) && (
                    <Tag
                      style={{
                        borderRadius: 10,
                        fontSize: '8px',
                        width: '4.5rem',
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                        textAlign: 'center',
                      }}
                      color='#81BFFD'>
                      {usePriceFormat(
                        Number(deal?.price) -
                          Number(deal?.variations[0]?.list_price) || 0,
                      )}
                      <ArrowUpwardIcon fontSize='inherit' />
                      Price
                    </Tag>
                  )}

                  {Number(deal?.price) <
                    Number(deal?.variations[0]?.list_price) && (
                    <Tag
                      style={{
                        borderRadius: 10,
                        fontSize: '10px',
                        width: '5.5rem',
                      }}
                      color='#ff6339'>
                      {usePriceFormat(
                        Number(deal?.variations[0]?.list_price) -
                          Number(deal?.price) || 0,
                      )}
                      <ArrowDownwardIcon fontSize='inherit' />
                      Price
                    </Tag>
                  )}
                </p>
              </div>

              {/* <div className=''>
                <h6>We Reimburse</h6>
                <p className='mb-0'>
                  <data value='1501.00'>$1501.00</data>
                </p>
                <Tag
                  style={{
                    borderRadius: 10,
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                  color='#81BFFD'>
                  Expires Soon!
                </Tag>
              </div> */}
            </div>
          </div>
        </Styles.DealDetailContainer>
      </div>
      <div className='mt-5 xl:hidden pb-5' id='productDetails'>
        <button
          data-testid={'quickCommitButton#' + ``}
          className='w-full flex justify-between disabled:opacity-40'
          onClick={handleOpenQuickCommit}>
          <span className='fontGTAmericanR text-lg'>Product Details</span>
          <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
        </button>
        <Collapse in={open} timeout='auto' unmountOnExit sx={{ marginTop: 2 }}>
          {productDetailsData.map((item, index) => (
            <div key={index}>
              <Divider orientation='horizontal' />
              <div className='flex justify-between items-center fontGTAmericanR pr-3 py-3'>
                <span
                  className={`text-[#b2b2b2] text-base ${item.detail.styles}`}>
                  {item.detail.name}
                </span>
                <span className={`${item.assignment.styles}`}>
                  {item.assignment.value}
                </span>
              </div>
              <div className='flex justify-end'>{item.banner}</div>
            </div>
          ))}
        </Collapse>
      </div>
    </CardContent>
  );
};

export default ProductCard;
