/* eslint-disable no-undef */
import cookie from 'react-cookies';

const postFetchAddInsuranceGrid = async ({ data, tracking }: any) => {
  console.log(data, tracking);

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/trackings/${tracking}/insurance`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
        'Content-type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify(data),
    },
  );

  return response.json();
};

export default postFetchAddInsuranceGrid;
