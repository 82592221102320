import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../../context/useSaveDataSession';
import { HistoryTracking } from '../../../interfaces/trackingNumbers/historyTracking';
import fetchGetHistoryTracking from '../../endPoints/trackingNumbers/getHistoryTracking';

export interface IGetResponseHistoryTracing {
  response: HistoryTracking | undefined;
  error: any;
  isLoading: boolean;
  refetch: any;
}
const getServicesHistoryTracking = (
  params: string,
): IGetResponseHistoryTracing => {
  const { logout } = useContext<any>(AuthContext);

  // const [launchQeury] = useState(false);
  const { error, data, isLoading, refetch } = useQuery(
    ['GET_HISTORY_TRACKING', params],
    () => {
      return fetchGetHistoryTracking(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return {
      error,
      response: data,
      isLoading,
      refetch,
    };
  } else {
    return { error, response: data, isLoading, refetch };
  }
};

export default getServicesHistoryTracking;
