import React from 'react';
import { Tooltip } from 'antd';

const SubmmittedBy = ({ isBuyerSubmitted }: any) => {
  return (
    <>
      {isBuyerSubmitted ? (
        <div className='flex justify-center'>
          <Tooltip
            placement='top'
            title={<p className='text-black mb-0 font-semibold px-4'>Buyer</p>}
            color='white'
            overlayStyle={{ borderRadius: '40px' }}>
            <img
              src='./images/trackingNumbers/icons-grid-2/user-icon.png'
              alt=''
              width={23}
              className='cursor-pointer'
            />
          </Tooltip>
        </div>
      ) : (
        <div className='flex justify-center'>
          <Tooltip
            placement='top'
            title={<p className='text-black mb-0 font-semibold'>Warehouse</p>}
            color='white'
            overlayStyle={{ borderRadius: '40px' }}>
            <img
              src='./images/trackingNumbers/icons-grid-2/warehouse.png'
              alt=''
              width={23}
              className='cursor-pointer'
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default SubmmittedBy;
