/* eslint-disable no-undef */
import React, { useCallback, createContext } from 'react';
import cookie from 'react-cookies';
import useStatesGlobalStore from '../hooks/useStatesGlobalStore';
import useLogout from '../api/services/logout';

export const AuthContext: any = createContext(null);

const UseSaveDataSession = (props: any) => {
  const { authInfo, setAuthInfo, setBuyerInfo, setDashboardInfo } =
    useStatesGlobalStore();

  const mutation = useLogout();

  // eslint-disable-next-line no-undef
  // let logOutTimer: NodeJS.Timeout;

  const logout = useCallback(() => {
    mutation.mutate();

    setAuthInfo({
      ...authInfo,
      state: 'sessionDenied',
    });
    setBuyerInfo([]);
    setDashboardInfo([]);

    setTimeout(() => {
      cookie.remove('token');
      if (process.env.REACT_APP_TESTING_DEV !== 'development') {
        window.location.href = 'https://usabuying.group/';
      }
    }, 2000);
  }, []);

  const login = useCallback(() => {
    const dateExpired = new Date(new Date().getTime() + 1000 * 60 * 1440);

    setAuthInfo({
      expiration: dateExpired?.toISOString(),
      state: 'sessionAproved',
    });
  }, []);

  // React.useEffect(() => {
  //   if (authInfo.expiration) {
  //     const verifyDateExpired =
  //       new Date(authInfo.expiration).getTime() - new Date().getTime();

  //     !cookie.load('token') && logout();

  //     logOutTimer = setTimeout(logout, verifyDateExpired);
  //   } else {
  //     clearTimeout(logOutTimer);
  //   }
  // }, [authInfo.state]);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default UseSaveDataSession;
