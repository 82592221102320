import tw, { styled } from 'twin.macro';

export const BackgroundContainer = styled.div`
  ${tw`bg-white`}
  border-radius: 2.5rem;

  .itemTableTd .ant-table-tbody > tr > td {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .tableStyles .ant-table {
    padding-left: 1.4rem;
    padding-bottom: 0rem;
  }

  .tableStyles .ant-table-thead > tr > th {
    padding-bottom: 1rem;
    background-color: #ebf5ff;
    color: black;
  }

  .ant-table-tbody > tr > td {
    padding: 20px 10px;
  }
`;
