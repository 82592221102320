import { useContext } from 'react';
import { useQuery } from 'react-query';
import { IServiceResponseTransform } from '../../transforms/activeDeals/getSelectBrandsTransform';
import fetchGetSelectBrands from '../../endPoints/activeDeals/getSelectBrands';
import getSelectBrandsTransform from '../../transforms/activeDeals/getSelectBrandsTransform';
import { AuthContext } from '../../../context/useSaveDataSession';
interface IResponseService {
  response: IServiceResponseTransform;
  error: any;
  isLoading: boolean;
}

const useGetSelectBrands = (): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    'GET_SELECT_BRANDS',
    fetchGetSelectBrands,
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: getSelectBrandsTransform(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default useGetSelectBrands;
