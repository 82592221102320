/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchGetSelectBrands = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/active-deals/brands`,
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
        'Content-Type': 'application/json',
      },
    },
  ).then((res) => res.json());

  return res;
};

export default fetchGetSelectBrands;
