import { useContext } from 'react';
import { useQuery } from 'react-query';
import fetchGetDeal from '../../endPoints/activeDeals/getDeal';
import { IServiceTransform } from '../../transforms/activeDeals/getDeal';
import getDealTransform from '../../transforms/activeDeals/getDeal';
import { AuthContext } from '../../../context/useSaveDataSession';
interface IResponseService {
  response: IServiceTransform | undefined;
  error: any;
  isLoading: boolean;
  refetch: () => void;
}

const useGetDeal = (params: any): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading, refetch } = useQuery(
    ['GET_DEAL', params],
    () => {
      return fetchGetDeal(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: getDealTransform(data), isLoading, refetch };
  } else {
    return { error, response: data, isLoading, refetch };
  }
};

export default useGetDeal;
