import React, { useState } from 'react';
import * as Styles from '../style';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { locationOptionsShipProduct } from '../constants';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { handleGeneralCopyClick } from '../../../helpers';
import { themeCustom } from '../../../theme';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';

const OperationsMobileView = ({
  deal,
  dealFormatted,
  functions,
  loadingIcon,
  variables,
}: any) => {
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const { colors } = themeCustom;

  const handleCopyClick = (text: string) => {
    handleGeneralCopyClick(
      text,
      {
        show: true,
        message: text,
        title: 'Text Copied!',
        type: 'info',
      },
      {
        show: true,
        title: 'Coping Error',
        type: 'error',
      },
      setShowAlert,
    );
  };

  return (
    <div
      className='block xl:hidden lg:flex lg:flex-wrap lg:justify-around'
      data-testid='operationsMobileView'>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
        />
      )}

      <section className='operationsCardsStyles lg:w-5/12' id='commitToProduct'>
        <h2 className='fontGTAmericanR'>1. Commit to Product</h2>
        {dealFormatted?.locked || dealFormatted?.is_fully_committed ? (
          <p className='mt-16 mb-0 text-gray-400 fontGTAmericanR text-sm'>
            <LockOutlinedIcon sx={{ height: 21, marginRight: 1, width: 21 }} />
            No More Commitments Allowed
          </p>
        ) : dealFormatted?.enabled ? (
          <p className='mt-16 mb-0 text-gray-400 fontGTAmericanR text-sm'>
            <LockOutlinedIcon sx={{ height: 21, marginRight: 1, width: 21 }} />
            You&apos;ve already committed to this deal
          </p>
        ) : (dealFormatted?.days_to_expire >= 0 ? false : true) ? (
          <p className='mt-16 mb-0 text-gray-400 fontGTAmericanR text-sm'>
            <LockOutlinedIcon sx={{ height: 21, marginRight: 1, width: 21 }} />
            Deal Expired
          </p>
        ) : (
          <div>
            <p className='mt-8 xl:text-gray-400 fontGTAmericanR text-[0.875rem]'>
              This product is available in multiple colors
            </p>
            <Stack
              data-testid='quickCommitView'
              sx={{
                borderRadius: 3,
                padding: { xs: 0, lg: 2 },
                paddingBottom: 0,
              }}>
              <div className='max-h-[20rem] overflow-y-auto'>
                <Styles.TableHeaderCommitContainer className='flex px-4'>
                  <h3 className='fontGTAmericanR'>Color</h3>
                  <h3 className='ml-16 fontGTAmericanR'>Qty Committed</h3>
                </Styles.TableHeaderCommitContainer>
                {dealFormatted?.quantityUser?.map((item: any, index: any) => (
                  <>
                    {item.fullyCommitted && (
                      <p className='mb-0 text-xs bg-red-100 font-bold mt-3 p-[2px] text-center rounded-sm'>
                        You've already committed to this item's variation.
                      </p>
                    )}
                    <div
                      className='flex rounded-lg items-center justify-center bg-slate-200 mt-2'
                      key={index}>
                      <div className='flex justify-between w-10/12 items-center h-14'>
                        <p
                          className={`text-sm fontGTAmericanR text-left truncate w-3/12 m-0 p-0`}>
                          {item.color}
                        </p>
                        <input
                          className='ml-3 text-slate-500 cursor-text px-2 bg-slate-100 border border-gray-400 h-9 w-[4rem] rounded-lg items-center flex font-semibold disabled:cursor-default'
                          disabled={item.fullyCommitted}
                          type='number'
                          data-testid={`inputQuickCommit#${item.id}`}
                          name={item.color}
                          min={item.locked_by_time ? item.amount : 0}
                          onChange={(e: any) =>
                            functions?.handleChange(e, item.id, item.amount, item.locked_by_time)
                          }
                          defaultValue={item.amount}
                        />
                        <Button
                          disabled={
                            variables?.isLoading ||
                            item.id !== variables?.variationQtyId ||
                            item.fullyCommitted ||
                            variables?.isNotAllowToDecrease
                          }
                          variant='outlined'
                          data-testid={`btnSave#${item.id}`}
                          onClick={functions?.handleQuickCommit}
                          sx={{
                            border: '1.5px solid',
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            '&:hover': {
                              backgroundColor: '#0069d9',
                              borderColor: '#0062cc',
                              boxShadow: 'none',
                              color: variables?.colors?.white,
                            },
                          }}>
                          {variables?.isLoading ? (
                            <img
                              className='h-5 w-5'
                              src={loadingIcon}
                              alt='loading icon'
                              width={200}
                              height={200}
                            />
                          ) : (
                            <span className='fontGTAmericanR'>Save</span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </Stack>
            <div className='mt-4 block space-y-4'>
              <Button
                data-testid='adtToCartBtn'
                variant='outlined'
                size='large'
                onClick={() => {
                  functions?.addnew(dealFormatted);
                }}
                sx={{
                  textDecoration: 'underline',
                  border: 'none',
                  color: variables?.colors?.grayLabels,
                  width: '100%',
                  textTransform: 'none',
                  padding: '12px 0px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    border: 'none',
                    textDecoration: 'underline',
                  },
                }}>
                Add to Deal Cart
              </Button>
            </div>
          </div>
        )}
      </section>
      <section className='operationsCardsStyles lg:w-5/12' id='purchaseProduct'>
        <h2 className='fontGTAmericanR'>2. Purchase Product</h2>
        <p className='mt-8 xl:text-gray-400 fontGTAmericanR text-[0.875rem] xl:w-10/12'>
          Once you have committed to this product, use this link to purchase the
          product in chosen quantity and style. If there are multiple options,
          choose your preferred retailer
        </p>
        <div className='mt-12 block h-[18rem] overflow-y-auto' id='shopOptions'>
          {deal?.variations.map((variation: any) =>
            variation?.stores?.map((store: any, index: number) => (
              <div key={index}>
                <div className='border-2 rounded-3xl xl:rounded-[2.2rem] xl:w-10/12'>
                  <a href={store.link} target='_blank' rel='noreferrer'>
                    <div className='flex mr-9  items-center p-4 pl-6'>
                      <picture className='w-8 h-8'>
                        <img
                          alt='Shop Image'
                          height={40}
                          src={store.icon}
                          width={40}
                        />
                      </picture>
                      <span className='items-center ml-5 text-blue-400 fontGTAmericanR'>
                        Shop on {store.name}
                      </span>
                    </div>
                  </a>
                </div>
                <div className='underline text-gray-400 mt-3 mb-4'>
                  <Button
                    data-testid={`copySkuBtn#${store.id}`}
                    onClick={() => {
                      functions?.handleCopyClick(variation?.model);
                    }}
                    startIcon={
                      <ContentCopyIcon sx={{ height: 17, marginRight: 1 }} />
                    }
                    sx={{
                      color: variables?.colors?.grayLabels,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: 13,
                    }}>
                    Copy Item SKU
                  </Button>
                </div>
              </div>
            )),
          )}
        </div>
      </section>
      <section className='operationsCardsStyles lg:w-5/12' id='shipProduct'>
        <h2 className='fontGTAmericanR'>3. Ship to Product</h2>
        <p className='mt-8 xl:text-gray-400 fontGTAmericanR text-[0.875rem] w-12/12'>
          You can either ship directly to our processing centers, or ship
          product to your own address and reship or hand-deliver to our
          processing centers.
        </p>
        <p className='mt-4 xl:text-gray-400 fontGTAmericanR text-[0.875rem] w-12/12'>
          to ship to our processing center, choose a location.
        </p>
        <p className='mt-4 xl:text-gray-400 fontGTAmericanR text-[0.875rem]'>
          For information on re-shipping or in-person drop-offs,
          {/* <Link className='underline text-gray-400' to='#!'>
            learn more here
          </Link> */}
        </p>
        <div className='mt-9' id='deliveryOptions'>
          {locationOptionsShipProduct?.map((location: any, index: any) => (
            <div
              className='flex justify-between items-center bg-slate-200 rounded-xl w-[100%] mb-6 p-3 font-semibold text-gray-400'
              key={index}>
              <LocationOnOutlinedIcon sx={{ width: 40 }} />
              <span className='ml-2 text-sm'>{location.name}</span>
              <div className='underline text-gray-400 ml-6 w-1/12'>
                <Button
                  data-testid='buttonCopyLocation'
                  onClick={() => {
                    handleCopyClick(location.name);
                  }}
                  startIcon={
                    <ContentCopyIcon sx={{ width: 40, marginRight: '5rem' }} />
                  }
                  sx={{
                    color: colors.grayLabels,
                    p: 0,
                  }}></Button>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className='operationsCardsStyles lg:w-5/12' id='nextSteps'>
        <h2 className='fontGTAmericanR'>Next Steps</h2>
        <div className='mt-10' id='stepOptions'>
          {variables?.nextSteps?.map((step: any, index: any) => (
            <Link
              to={step.route}
              className='flex justify-between border-2 rounded-3xl xl:rounded-[2.2rem] items-center p-5 mb-6 xl:h-[4.35rem] xl:w-[110%]'
              key={index}
              state={step.state}>
              <div className='flex items-center'>
                {step.icon}
                <span className='ml-4 fontGTAmericanR text-[.8rem] text-[#178DFF]'>
                  {step.name}
                </span>
              </div>
              <span>{step.arrow}</span>
            </Link>
          ))}
        </div>
        <p className='mt-8 font-semibold text-sm mb-0'>For more information,</p>
        <Link
          className='underline hover:text-gray-500 hover:underline'
          to='/help-center'>
          check out our Help section
        </Link>
      </section>
    </div>
  );
};
export default OperationsMobileView;
