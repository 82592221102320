import tw, { styled } from 'twin.macro';

export const MainContainer = styled.div`
  ${tw`bg-black p-12 mb-6 justify-self-end text-white`}
  border-radius: 2.5rem;
  width: max-content;

  @media (min-width: 1024px) {
    width: 30%;
  }

  .descriptioParagraphfontSize {
    font-size: 11px;
    color: #f5f5f5;
  }
`;

export const SucceedContainer = styled.div`
  ${tw` px-44 py-12 mb-6  justify-self-end text-center`}
  border-radius: 2.5rem;
  background-color: #9b96f5;

  img {
    width: 90px;
    height: 100px;
  }
`;

export const HrDivider = styled.hr`
  ${tw`mt-5`}
  border-top: 1px solid #8f8f8f;
`;
