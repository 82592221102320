import React, { useEffect, useState } from 'react';
import { Popover, Steps, Tooltip } from 'antd';
import * as Style from '../style';
import getServicesHistoryTracking from '../../../api/services/trackingNumbers/getHistoryTracking';
import { Events } from '../../../interfaces/trackingNumbers/historyTracking';
import { CircularProgress } from '@mui/material';

const TrackingInfoGrid = ({ tracking, item }: any) => {
  const [open, setOpen] = useState(false);
  const [trackingHistory, setTrackingHistory] = useState('');

  const { Step } = Steps;

  const { response, isLoading } = getServicesHistoryTracking(trackingHistory);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const verifyStatusTrackingIcon = () => {
    const [imageStatus, setImageStatus] = useState(
      './images/trackingNumbers/icons-grid-2/Vectorsa.png',
    );

    const [tooltip, setTooltip] = useState(
      './images/trackingNumbers/icons-grid-2/Vectorsa.png',
    );

    useEffect(() => {
      if (item?.status_id === 0) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/delivered.png');
        setTooltip('In transit');
      }
      if (item?.status_id === 1) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/caja-reloj.png');
        setTooltip('Unreceived');
      }
      if (item?.status_id === 2) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/cheked-in.png');
        setTooltip('Received');
      }
      if (item?.status_id === 3) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/lupa.png');
        setTooltip('Verify package contents');
      }
      if (item?.status_id === 4) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/reloj.png');
        setTooltip('Waiting to be received');
      }
      if (item?.status_id === 5) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/Vector.png');
        setTooltip('Rejected');
      }
      if (item?.status_id === 6) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/papelera.png');
        setTooltip('Deleted');
      }
      if (item?.status_id === 7) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/a.png');
        setTooltip('Scanned for OTP');
      }
      if (item?.status_id === 8) {
        setImageStatus('./images/trackingNumbers/icons-grid-2/a.png');
        setTooltip('Scanned at dock');
      }
    }, [item.status_id]);

    return (
      <div className='flex justify-center'>
        <Tooltip
          placement='top'
          title={
            <p className='text-black mb-0 font-semibold px-4'>{tooltip}</p>
          }
          color='white'
          overlayStyle={{ borderRadius: '40px' }}>
          <img
            src={imageStatus}
            alt=''
            width={26}
            className='lg:mr-6 cursor-pointer'
            onClick={() => setTrackingHistory(tracking)}
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className='flex justify-between'>
      <p className='truncate-text-1-lines text-xs mb-0'>{tracking}</p>
      {item.event_history_available ? (
        <Popover
          content={
            <Style.ContentHistorialTrackings>
              {isLoading && (
                <div className='flex justify-center'>
                  <CircularProgress />
                </div>
              )}
              {response?.data?.events && (
                <div className='sectionScroll'>
                  {/* <p className='font-semibold'>
                    Estimate delivered date:{' '}
                    {response.data.estimated_delivered_date}
                  </p> */}
                  <Steps
                    progressDot
                    current={
                      response?.data?.events ? response.data?.events.length : 0
                    }
                    direction='vertical'>
                    {response?.data?.events.map((item: Events, index: any) => (
                      <Step
                        title={item.date}
                        description={item.description}
                        key={index}
                      />
                    ))}
                  </Steps>
                </div>
              )}
            </Style.ContentHistorialTrackings>
          }
          title={
            response?.data?.estimated_delivered_date && (
              <div className='flex justify-between items-center align-middle py-3'>
                <p className='mb-0'>Tracking History</p>
                <p className='mb-0 font-normal'>
                  Estimate delivery: {response?.data?.estimated_delivered_date}
                </p>
              </div>
            )
          }
          trigger='click'
          visible={open}
          onVisibleChange={handleOpenChange}>
          <div>{verifyStatusTrackingIcon()}</div>
        </Popover>
      ) : null}
    </div>
  );
};

export default TrackingInfoGrid;
