import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { Button } from '@mui/material';
import { themeCustom } from '../../../theme';
const { colors } = themeCustom;

const RenderActions = (item: any) => {
  const { removeItem } = useCart();

  return (
    <div className=''>
      <Link
        className='flex text-xs'
        to={`/active-deal/${item.item.id}`}
        state={item?.item}>
        <Button
          sx={{
            width: '10rem',
            textTransform: 'none',
            color: colors.grayLabels,
          }}>
          <img
            className='h-5'
            src='images/activeDeals/greyEye.svg'
            alt='quick commit icon'
            width='17'
            height='16'
          />
          <span className='mb-0 ml-3 opacity-40 text-[#000000]'>View Item</span>
        </Button>
      </Link>
      <Button
        onClick={() => removeItem(item.item.id)}
        sx={{
          width: '10rem',
          textTransform: 'none',
          color: colors.grayLabels,
          paddingLeft: '16px',
        }}>
        <img
          className='h-4 '
          src='images/trackingNumbers/deleteIcon.png'
          alt='delete icon'
          width='12'
          height='17'
        />
        <span className='ml-3 text-xs text-[#000000] opacity-40 '>
          Remove Item
        </span>
        {/* <Box sx={{ color: colors.grayLabels, display: 'flex ' }}>
        </Box> */}
      </Button>
    </div>
  );
};

export default RenderActions;
