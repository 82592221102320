import React, { useEffect, useState } from 'react';
import * as Styles from './style';
import getTrackingNumbersInfo from '../../api/services/trackingNumbers/getTrackingNumbers';
import {
  dataTableFields,
  dateFilter,
  searchFilter,
  selectFiltersTN,
  stylesFilterSectionSearchAndDate,
  boxValue,
  filterInsurance,
  // discrepancy,
} from './constants/index';
import { IFilters } from '../../interfaces/trackingNumbers';
import { Table } from 'antd';
import Filter from '../../components/UI/filter';
import AddTrackingNumbers from './containers/addTrackingNumbers';
import GenerateLabels from './containers/generateLabels';
import ExportTrackingNumbers from './containers/exportTrackingNumbers';
import { Divider } from '@mui/material';
import MobileFilter from '../../components/UI/mobileFilter';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';
import { IDataInternal } from '../../interfaces/purchaseOrders/details';
import PaginationCustom from '../../components/UI/PaginationCustom';
import useStatesGlobalStore from '../../hooks/useStatesGlobalStore';
import getInfoCards from '../../api/services/dashboard/getInfoCards';
import RollPackage from './containers/RollPackage';

const TrackingNumbersModule = () => {
  const { isDesktopCustomTable } = useResponsive();
  const [filterOptions, setFilterOptions] = React.useState<IFilters>({
    buyer_submitted: 0,
    date_from: '',
    date_until: '',
    limit: 0,
    receiving_status_id: 0,
    tracking_number: '',
    otp_code_submitted: 0,
    insurance_status_id: 0,
    payment_status_id: '',
    tracking_number_status_id: 0,
  });

  const [listSelected, setListSelected] = useState<IDataInternal[]>([]);

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    setAlertSliceMobile,
    setAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  const { response: responseDashboard } = getInfoCards();

  const { dashboardInfo, setDashboardInfo } = useStatesGlobalStore();

  useEffect(() => {
    if (responseDashboard?.data) {
      setDashboardInfo({ ...dashboardInfo, info: responseDashboard?.data });
    }
  }, [responseDashboard?.data]);

  React.useEffect(() => {
    if (
      alertSliceMobile.forUser !== 'user' &&
      alertTurnScreenMobile.forUser !== 'user'
    ) {
      setAlertSliceMobile();
      setAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.forUser, alertTurnScreenMobile.forUser]);

  const [current, setCurrent] = useState(1);
  const [sizeItemsPage, setSizeItemPage] = useState(10);
  const [verifiedColumns] = useState(dataTableFields);
  const [verifiedFilters] = useState(selectFiltersTN);

  const { isLoading, response } = getTrackingNumbersInfo({
    ...filterOptions,
    limit: sizeItemsPage,
    start: current > 1 && current * sizeItemsPage - sizeItemsPage,
  });

  let trackingsList: any = [];
  response?.data.map((tracking: any) => {
    if (!tracking?.purchase && tracking?.autogenerated) {
      trackingsList.push(tracking.id);
    }
  });
  trackingsList = trackingsList.join();
  // TODO: USE WHEN NEED IT DISPLAY
  // const showExpCards = false;
  //TODO: verify funcionality
  // const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
  //   console.log('params', pagination, filters, sorter, extra);
  // };

  useEffect(() => {
    if (dashboardInfo?.info?.trackings_insurance_available === 1) {
      const valueCoverage = verifiedColumns.filter(
        (item) => item.title === 'Value / Coverage',
      );

      if (valueCoverage.length === 0) {
        verifiedColumns.splice(2, 0, boxValue);
      }

      // const discrepancyValidation = verifiedColumns.filter(
      //   (item) => item.title === 'discrepancy',
      // );

      // if (discrepancyValidation.length === 0) {
      //   verifiedColumns.splice(6, 0, discrepancy);
      // }

      const filterInsuraceStatus = verifiedFilters.filter(
        (item) => item.title === 'Coverage',
      );

      if (filterInsuraceStatus.length === 0) {
        verifiedFilters.splice(4, 0, filterInsurance);
      }
    }
  }, []);

  if (isDesktopCustomTable) {
    dataTableFields?.map((item: any, index: any) => {
      if (item?.dataIndex === 'tracking_number') {
        delete item.fixed;
      }
      if (item?.key === 'date_addedC') {
        dataTableFields.splice(index, 1);
      }
    });
  }

  const clearFilters = () => {
    setFilterOptions({
      buyer_submitted: 0,
      date_from: '',
      date_until: '',
      limit: 0,
      receiving_status_id: 0,
      tracking_number: '',
      otp_code_submitted: 0,
      insurance_status_id: 0,
      payment_status_id: '',
      tracking_number_status_id: 0,
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IDataInternal[]) => {
      setListSelected(selectedRows);
    },
  };
  

  return (
    <>
      <div className='flex flex-wrap justify-between gap-4 mt-11 mb-7'>
        <h1 className='text-2xl xl:text-4xl text-left fontSharpGrotesk22Medium lg:ml-5'>
          Tracking Numbers
        </h1>
        <div className='block xl:flex flex-wrap xl:gap-4 bg-white xl:bg-inherit rounded-3xl w-full xl:w-auto'>
          {dashboardInfo?.info?.trackings_insurance_available === 1 && <RollPackage />}
          <div className='xl:hidden'>
            <Divider sx={{ marginLeft: 3, marginRight: 3 }} />
          </div>
          <AddTrackingNumbers />
          <div className='xl:hidden'>
            <Divider sx={{ marginLeft: 3, marginRight: 3 }} />
          </div>
          <GenerateLabels />
          <div className='xl:hidden'>
            <Divider sx={{ marginLeft: 3, marginRight: 3 }} />
          </div>
          <ExportTrackingNumbers list={listSelected} />
        </div>
      </div>
      <div className='hidden xl:block w-full'>
        <Filter
          filterOptions={filterOptions}
          formStyles={'flex flex-wrap'}
          setFilterOptions={setFilterOptions}
          searchFilters={searchFilter}
          simpleSelectFilters={selectFiltersTN}
          rangeDateFilter={dateFilter}
          stylesFilterSectionSearchAndDate={stylesFilterSectionSearchAndDate()}
          isSaveFilterVisible={false}
          showFilterTitle={true}
        />
      </div>
      {isMobile && (
        <MobileFilter
          clearFilters={clearFilters}
          filterOptions={filterOptions}
          formStyles={'flex flex-wrap lg:justify-between'}
          isSaveFilterVisible={true}
          searchFilter={searchFilter}
          setFilterOptions={setFilterOptions}
          simpleSelectFilters={selectFiltersTN}
          stylesFilterSectionSearchAndDate={stylesFilterSectionSearchAndDate}
          showFilterTitle={false}
          rangeDateFilterMobile={dateFilter}
        />
      )}
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <Styles.TableContainer>
          <Table
            rowSelection={{
              columnWidth: '2rem',
              checkStrictly: true,
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={verifiedColumns}
            // rowClassName={() => 'rowClassName1'}
            dataSource={response?.data || []}
            scroll={{x: 900}}
            loading={isLoading}
            pagination={false}
            className='xl1:h-[calc(100vh-680px)] xlc:h-[calc(100vh-510px)] xl:h-[calc(100vh-500px)] xl2:h-[calc(100vh-500px)] 2xl:h-[calc(100vh-420px)] xl2_1:h-[calc(100vh-400px)] xl3:h-[calc(100vh-370px)]'
            style={{
              minHeight: '250px',
            }}
          />
        </Styles.TableContainer>
      </div>

      <PaginationCustom
        totalItems={response?.meta?.totals?.items}
        current={current}
        setSizeItemPage={setSizeItemPage}
        sizeItemsPage={sizeItemsPage}
        setCurrent={setCurrent}
        mobile={isMobile}
      />
    </>
  );
};

export default TrackingNumbersModule;